import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect, Link } from 'react-router-dom';

import HomeLayout from './shared/HomeLayout';
import AppIconSvg from '../assets/field-install.svg';


class InstallPwa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pwaPrompt: localStorage.getItem('pwa_prompt'),
            languages: JSON.parse(localStorage.getItem('languages')),
            strings: JSON.parse(localStorage.getItem('strings')),
            next: process.env.REACT_APP_PUBLIC_URL + 'home',
        };
        this._isMounted = false;
        this.deferredPrompt = props.deferredPrompt;
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    installApp = async () => {
        // Chrome/Android - PWA Install Prompt
        let a2hsBtn = document.getElementById("installBtn"),
            skipBtn = document.getElementById("skipBtn"),
            continueBtn = document.getElementById("continueBtn");
        // Hide the buttons, show the install prompt
        a2hsBtn.style.display = 'none';
        skipBtn.style.display = 'none';
        continueBtn.style.display = 'block';
        // Wait for the user to respond to the prompt
        this.deferredPrompt.prompt();
        await this.deferredPrompt.userChoice
            .then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    this.setState({ pwaPrompt: 'installed' }, () => {
                        localStorage.setItem('pwa_prompt', 'installed')
                    });
                    this.deferredPrompt = null;
                } else {
                    this.setState({ pwaPrompt: 'skipped' }, () => {
                        localStorage.setItem('pwa_prompt', 'skipped')
                    });
                    this.deferredPrompt = null;
                }
            });
    };

    skipInstall = () => {
        this.setState({ pwaPrompt: 'skipped' }, () => {
            localStorage.setItem('pwa_prompt', 'skipped')
        });
        this.deferredPrompt = null;
    };

    render() {
        if (this.state.pwaPrompt !== null)
            return <Redirect to={{
                pathname: this.state.next,
                state: {
                    languages: this.state.language,
                    strings: this.state.strings,
                }
            }} />

        else
            return (
                <main>
                    <HomeLayout language={this.state.language} strings={this.state.strings} />
                    <DownloadContainer>
                        <AppIconContainer>
                            <AppIcon
                                src={AppIconSvg}
                                alt="app-icon" />
                        </AppIconContainer>
                        <Heading>{this.state.strings.YOU_CAN_INSTALL_THIS_APP}</Heading>
                        <DownloadContent>{this.state.strings.PWA_DESCRIPTION}</DownloadContent>
                    </DownloadContainer>
                    <ButtonContainer>
                        <button className="button button-primary" id="installBtn" onClick={this.installApp}>{this.state.strings.INSTALL_NOW}</button>
                        <button className="button button-secondary" id="skipBtn" onClick={this.skipInstall}>{this.state.strings.SKIP_INSTALL}</button>
                        <Link className="button button-primary" id="continueBtn" to={this.state.next}>Continue</Link>
                    </ButtonContainer>
                </main >
            );
    };
};

const DownloadContainer = styled.div`
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        position: relative;
        width: 94%;
        margin: 30% 3% 0 3%;
    `,
    AppIconContainer = styled.div`
        width: 100%;
        height: 100px;
        margin: 0 auto;
        display: flex;
        align-content: center;
    `,
    AppIcon = styled.img`
        width: 96px;
        margin: 15px auto;
        position: relative;
        top: 0;
    `,
    Heading = styled.h1`
        width: 100%;
        margin: 10px auto 0;
        color #ffffff;
        text-align: center;
        text-transform: uppercase;
        font-size: 24px;
        font-family: Rajdhani, sans-serif;
        position: relative;
        top: 20px;
    `,
    DownloadContent = styled.p`
        text-align: center;
        color: #ffffff;
        margin: 24px;
        padding: 20px 0;
    `,
    ButtonContainer = styled.div`
        width: 94%;
        text-align: center;
        margin: 0 3%;

        .button {
            display: block;
            width: 94%;
            margin: 0 3% 10px;
            position: relative;
            top: -18px;
            text-transform: uppercase;
            color: #ffffff;
            padding: 12px;
            font-weight: bold;
            font-size: 18px;
            font-family: Rajdhani, sans-serif;
            clear: both;

            &:hover, &:active {
                background-color: #64a6bb;

            }
        }

        .button-primary {
            background-color: #5493b3;
            border: 1px solid #5493b3;
        }

        .button-secondary {
            background-color: transparent;
            border: 1px solid #ffffff;
        }

        #continueBtn {
            display: none;
            width: 86%;
        }
    `;

export default InstallPwa;