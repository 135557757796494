import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import Toggle from 'react-toggle';


import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class CopperTubePreparation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrlInsertionDepth: process.env.REACT_APP_API_URL + 'get/section?table=hdpe_installation_insertion_depths',
            getSectionDataUrlInstallation: process.env.REACT_APP_API_URL + 'get/section?table=hdpe_installation',
            installTypeOptions: {},
            selectedInstallType: {},
            nominalSizeOptions: {},
            selectedNominalSize: null,
            units: true,
            styleOptions: {},
            installation_nominalSizeOptions_inches: {},
            installation_nominalSizeOptions_mm: {},
            installation_nominalOutletSizeOptions_inches: {},
            installation_nominalOutletSizeOptions_mm: {},
            installation_valveSizeOptions: {},
            installation_secondSelect: null,
            filterName: '',
            sizeTypeOption: '',
            selectedStyleOption: null,
            toolModelOptions: [],
            selectedToolModel: null,
            selectedStyleType: {},
            resultsInsertionDepth: {
                actual_outside_pipe_diameter_inches: "",
                actual_outside_pipe_diameter_mm: "",
                pipe_insertion_depth_inches: "",
                pipe_insertion_depth_mm: "",
            },
            resultsInstallation: {
                actual_outside_diameter_inches: "",
                actual_outside_diameter_mm: "",
                assembly_bolt_size_inches: "",
                bolt_diameter_inches: "",
                coupling_nut_deep_well_socket_inches: "",
                coupling_nut_deep_well_socket_mm: "",
                coupling_nut_size: "",
                draw_bolt_size_inches: "",
                draw_bolt_socket_size_inches: "",
                mating_face_sealing_surface_a_max_inches: "",
                mating_face_sealing_surface_a_max_mm: "",
                mating_face_sealing_surface_b_min_inches: "",
                mating_face_sealing_surface_b_min_mm: "",
                max_allowable_diameter_inches: "",
                max_allowable_diameter_mm: "",
                max_surface_prep_a_dimension_inches: "",
                max_surface_prep_a_dimension_mm: "",
                min_hole_diameter_inches: "",
                min_hole_diameter_mm: "",
                nut_size_inches: "",
                nut_size_mm: "",
                required_number_assembly_bolts: "",
                required_number_draw_bolts: "",
                required_number_of_bolts: "",
                socket_size_inches: "",
                socket_size_mm: "",
                spacer_bolt_size: "",
                spacer_bolt_socket_inches: "",
                spacer_bolt_socket_mm: "",
                torque_value_maximum_ft_lbs: "",
                torque_value_typical_ft_lbs: "",
            }
        };
    }



    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrlInsertionDepth)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hdpe_installation_insertion_depths', JSON.stringify(response.data.data));
                let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_hdpe_installation_insertion_depths'));
                let nominalSizeOptions = [];
                let map = new Map();
                let installTypeOptions;
                for (const item of data) {
                    if (!map.has(item.nominal_size_inches) && item.nominal_size_inches !== '' && item.nominal_size_inches !== null) {
                        map.set(item.nominal_size_inches, true);    // set any value to Map
                        nominalSizeOptions.push({ value: item.nominal_size_inches, label: item.nominal_size_inches });
                    }
                }
                installTypeOptions = [
                    { 'value': 1, 'label': "Installation Insertion Depth" },
                    { 'value': 2, 'label': "Installation" },
                ];

                this.setState({
                    nominalSizeOptions: nominalSizeOptions,
                    installTypeOptions: installTypeOptions
                })
            })
            .catch(console.log);

        axios.get(this.state.getSectionDataUrlInstallation)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hdpe_installation', JSON.stringify(response.data.data));
                let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_hdpe_installation'));
                let styleOptions = [];
                let map = new Map();
                let count = 1;
                for (const item of data) {
                    if (!map.has(item.style) && item.style !== '' && item.style !== null) {
                        map.set(item.style, true);    // set any value to Map
                        styleOptions.push({ value: count, label: item.style });
                        count++
                    }

                }
                this.setState({
                    styleOptions: styleOptions,
                })
            })
            .catch(console.log);

    };

    getAllData = async () => {
        await axios.all([this.grooveSpecificationOptions, this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    setInstallType = pt => {
        this.setState({
            selectedInstallType: { 'value': pt.value, 'label': pt.label }
        })
    }

    changeUnits = (e) => {
        this.setState({
            units: e.target.checked,
        });
        this.setState({ selectedNominalDiameter: null });
        localStorage.setItem('units', e.target.checked);
    }

    changeUnitsInstall = (e) => {
        this.setState({
            units: e.target.checked,
        });
        this.clearResultsInstallation();
    }

    setStyleType = s => {
        this.setState({
            selectedStyleType: { 'value': s.value, 'label': s.label },
            units: true,
        }, () => {
            let options = [];
            _.forEach(
                _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_hdpe_installation')),
                    ['style', this.state.selectedStyleType.label]
                ), (values) => {
                    options.push(values)

                });
            // console.log(this.state.styleOptions)
            let installation_nominalSizeOptions_inches = [];
            let installation_nominalSizeOptions_mm = [];
            let installation_nominalOutletSizeOptions_inches = [];
            let installation_nominalOutletSizeOptions_mm = [];
            let installation_valveSizeOptions = [];
            let map = new Map();
            for (const item of options) {
                if (!map.has(item.nominal_size_inches) && item.nominal_size_inches !== '' && item.nominal_size_inches !== null) {
                    map.set(item.nominal_size_inches, true);    // set any value to Map
                    installation_nominalSizeOptions_inches.push({ value: item.nominal_size_inches, label: item.nominal_size_inches });
                }

                if (!map.has(item.nominal_size_mm) && item.nominal_size_mm !== '' && item.nominal_size_mm !== null) {
                    map.set(item.nominal_size_mm, true);    // set any value to Map
                    installation_nominalSizeOptions_mm.push({ value: item.nominal_size_mm, label: item.nominal_size_mm });
                }

                if (!map.has(item.nominal_outlet_size_inches) && item.nominal_outlet_size_inches !== '' && item.nominal_outlet_size_inches !== null) {
                    map.set(item.nominal_outlet_size_inches, true);    // set any value to Map
                    installation_nominalOutletSizeOptions_inches.push({ value: item.nominal_outlet_size_inches, label: item.nominal_outlet_size_inches });
                }

                if (!map.has(item.nominal_outlet_size_mm) && item.nominal_outlet_size_mm !== '' && item.nominal_outlet_size_mm !== null) {
                    map.set(item.nominal_outlet_size_mm, true);    // set any value to Map
                    installation_nominalOutletSizeOptions_mm.push({ value: item.nominal_outlet_size_mm, label: item.nominal_outlet_size_mm });
                }

                if (!map.has(item.valve_size) && item.valve_size !== '' && item.valve_size !== null) {
                    map.set(item.valve_size, true);    // set any value to Map
                    installation_valveSizeOptions.push({ value: item.valve_size, label: item.valve_size });
                }
            }

            this.setState({
                installation_nominalSizeOptions_inches: installation_nominalSizeOptions_inches,
                installation_nominalSizeOptions_mm: installation_nominalSizeOptions_mm,
                installation_nominalOutletSizeOptions_inches: installation_nominalOutletSizeOptions_inches,
                installation_nominalOutletSizeOptions_mm: installation_nominalOutletSizeOptions_mm,
                installation_valveSizeOptions: installation_valveSizeOptions,
                units: true,
                installation_secondSelect: null
            })

            // this.setState({
            //     // nominalSizeOptions : nominalSizeOptions,
            //     toolStandardSelectedDisabled : null,
            //     selectedNominalSize : null,
            // })

        });
        this.clearResultsInstallation();
    }

    clearresultsInsertionDepth = () => {
        this.setState({
            resultsInsertionDepth: {
                copper_tubing_size_2: "",
                copper_tubing_size_2_5: "",
                copper_tubing_size_3: "",
                copper_tubing_size_4: "",
                copper_tubing_size_5: "",
                copper_tubing_size_6: "",
                copper_tubing_size_8: "",
                copper_tubing_size_54: "",
                copper_tubing_size_64: "",
                copper_tubing_size_66_7: "",
                copper_tubing_size_76_1: "",
                copper_tubing_size_88_9: "",
                copper_tubing_size_108: "",
                copper_tubing_size_133: "",
                copper_tubing_size_159: "",
                copper_tubing_size_dn50: "",
                copper_tubing_size_dn65: "",
                copper_tubing_size_dn80: "",
                copper_tubing_size_dn100: "",
                copper_tubing_size_dn125: "",
                copper_tubing_size_dn150: "",
            },
        });
    }

    clearResultsInstallation = () => {
        this.setState({
            installation_secondSelect: null,
            resultsInstallation: {
                actual_outside_diameter_inches: "",
                actual_outside_diameter_mm: "",
                assembly_bolt_size_inches: "",
                bolt_diameter_inches: "",
                coupling_nut_deep_well_socket_inches: "",
                coupling_nut_deep_well_socket_mm: "",
                coupling_nut_size: "",
                draw_bolt_size_inches: "",
                draw_bolt_socket_size_inches: "",
                mating_face_sealing_surface_a_max_inches: "",
                mating_face_sealing_surface_a_max_mm: "",
                mating_face_sealing_surface_b_min_inches: "",
                mating_face_sealing_surface_b_min_mm: "",
                max_allowable_diameter_inches: "",
                max_allowable_diameter_mm: "",
                max_surface_prep_a_dimension_inches: "",
                max_surface_prep_a_dimension_mm: "",
                min_hole_diameter_inches: "",
                min_hole_diameter_mm: "",
                nut_size_inches: "",
                nut_size_mm: "",
                required_number_assembly_bolts: "",
                required_number_draw_bolts: "",
                required_number_of_bolts: "",
                socket_size_inches: "",
                socket_size_mm: "",
                spacer_bolt_size: "",
                spacer_bolt_socket_inches: "",
                spacer_bolt_socket_mm: "",
                torque_value_maximum_ft_lbs: "",
                torque_value_typical_ft_lbs: "",
            }
        })
    }

    setNominalSize = ns => {
        this.setState({
            selectedNominalSize: { 'value': ns.value, 'label': ns.label }
        }, () => {
            this.setResultsInsertionDepth();
        })
    }

    setInstallationFilter = f => {
        this.setState({
            installation_secondSelect: { 'value': f.value, 'label': f.label }
        }, () => {
            this.setResultsInstallation();
        })
    }

    setResultsInstallation = () => {
        const selectedStyleType = this.state.selectedStyleType
        const secondSelect = this.state.installation_secondSelect;
        const unit = (this.state.units) ? "inches" : "mm";
        let filterName = '';
        let dataRating = JSON.parse(localStorage.getItem(this.props.sectionId + '_hdpe_installation'));

        if (selectedStyleType.value === 1 || selectedStyleType.value === 2 || selectedStyleType.value === 4 || selectedStyleType.value === 5) {
            filterName = "nominal_size_" + unit;
        } else if (selectedStyleType.value === 3) {
            filterName = "nominal_outlet_size_" + unit;
        } else if (selectedStyleType.value === 6) {
            filterName = "valve_size"
        }

        console.log(selectedStyleType.label)

        try {
            if (Object.entries(secondSelect).length > 0 &&
                Object.entries(selectedStyleType).length > 0) {
                let results = _.forEach(_.find(dataRating, {
                    'style': selectedStyleType.label,
                    [filterName]: secondSelect.label
                }))
                console.log(results)
                this.setState({
                    resultsInstallation: {
                        actual_outside_diameter_inches: results.actual_outside_diameter_inches,
                        actual_outside_diameter_mm: results.actual_outside_diameter_mm,
                        assembly_bolt_size_inches: results.assembly_bolt_size_inches,
                        bolt_diameter_inches: results.bolt_diameter_inches,
                        coupling_nut_deep_well_socket_inches: results.coupling_nut_deep_well_socket_inches,
                        coupling_nut_deep_well_socket_mm: results.coupling_nut_deep_well_socket_mm,
                        coupling_nut_size: results.coupling_nut_size,
                        draw_bolt_size_inches: results.draw_bolt_size_inches,
                        draw_bolt_socket_size_inches: results.draw_bolt_socket_size_inches,
                        mating_face_sealing_surface_a_max_inches: results.mating_face_sealing_surface_a_max_inches,
                        mating_face_sealing_surface_a_max_mm: results.mating_face_sealing_surface_a_max_mm,
                        mating_face_sealing_surface_b_min_inches: results.mating_face_sealing_surface_b_min_inches,
                        mating_face_sealing_surface_b_min_mm: results.mating_face_sealing_surface_b_min_mm,
                        max_allowable_diameter_inches: results.max_allowable_diameter_inches,
                        max_allowable_diameter_mm: results.max_allowable_diameter_mm,
                        max_surface_prep_a_dimension_inches: results.max_surface_prep_a_dimension_inches,
                        max_surface_prep_a_dimension_mm: results.max_surface_prep_a_dimension_mm,
                        min_hole_diameter_inches: results.min_hole_diameter_inches,
                        min_hole_diameter_mm: results.min_hole_diameter_mm,
                        nut_size_inches: results.nut_size_inches,
                        nut_size_mm: results.nut_size_mm,
                        required_number_assembly_bolts: results.required_number_assembly_bolts,
                        required_number_draw_bolts: results.required_number_draw_bolts,
                        required_number_of_bolts: results.required_number_of_bolts,
                        socket_size_inches: results.socket_size_inches,
                        socket_size_mm: results.socket_size_mm,
                        spacer_bolt_size: results.spacer_bolt_size,
                        spacer_bolt_socket_inches: results.spacer_bolt_socket_inches,
                        spacer_bolt_socket_mm: results.spacer_bolt_socket_mm,
                        torque_value_maximum_ft_lbs: results.torque_value_maximum_ft_lbs,
                        torque_value_typical_ft_lbs: results.torque_value_typical_ft_lbs,
                    }
                });
            }
            this.setState({
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }

    };

    //this is completed
    setResultsInsertionDepth = () => {
        const selectedNominalSize = this.state.selectedNominalSize;
        let dataLength = JSON.parse(localStorage.getItem(this.props.sectionId + '_hdpe_installation_insertion_depths'));
        try {
            if (Object.entries(selectedNominalSize).length > 0) {
                let results = _.forEach(_.find(dataLength, {
                    'nominal_size_inches': selectedNominalSize.value
                }))
                this.setState({
                    resultsInsertionDepth: {
                        actual_outside_pipe_diameter_inches: results.actual_outside_pipe_diameter_inches,
                        actual_outside_pipe_diameter_mm: results.actual_outside_pipe_diameter_mm,
                        pipe_insertion_depth_inches: results.pipe_insertion_depth_inches,
                        pipe_insertion_depth_mm: results.pipe_insertion_depth_mm,
                    },
                });
            }
            this.setState({
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    }

    render() {
        const string = this.props.strings;
        const state = this.state;
        const selectedInstallType = state.selectedInstallType
        //Label
        const installLabel = () => {
            return (
                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {selectedInstallType.label}
                        </CategoryHeader>
                    </Col>
                </Row>
            )
        }

        //INSTALLATION LENGTH PART OF THE APPLICATION
        const tableInstallationDepth = () => {
            const units = this.state.units;
            const unit = (units) ? string.INCHES : string.MM;
            return (
                <div>
                    <Row>
                        <Col xs={12}>
                            <SectionCaution>
                                <h2><i className="icon-caution icon-round-bg"></i> {this.props.strings.CAUTION}</h2>
                                <div>{ReactHtmlParser(this.props.strings.I_900_SECTION_3_INSTALLATION_DEPTH_CAUTION)}</div>
                            </SectionCaution>
                        </Col>
                    </Row>
                    {installLabel()}
                    <Row>
                        <Col xs={12}>
                            <Label>{string.NOMINAL_SIZE} {string.INCHES}</Label>
                            <Select
                                options={this.state.nominalSizeOptions}
                                getOptionValue={option => option.value}
                                value={this.state.selectedNominalSize}
                                placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                                className="input-filter"
                                onChange={this.setNominalSize}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="extra-padding">
                        <Col xs={6}>
                            <Label>{this.props.strings.UNITS}</Label>
                        </Col>
                        <Col xs={6} className="toggle-container">
                            <label>
                                <Toggle
                                    checked={this.state.units}
                                    className="victaulic-toggle"
                                    icons={false}
                                    onChange={this.changeUnits} />
                                <Span className="victaulic-toggle-label">
                                    {(this.state.units === true ? this.props.strings.INCHES : this.props.strings.MM)}
                                </Span>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <CategoryHeader>
                                {string.INSERTION_DEPTH_REQ_HDPE_PIPE}
                            </CategoryHeader>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Label>{string.ACTUAL_PIPE_OUTSIDE_DIAMETER}</Label>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Label className="sublabel">{unit}</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={(units) ? this.state.resultsInsertionDepth.actual_outside_pipe_diameter_inches || '' : this.state.resultsInsertionDepth.actual_outside_pipe_diameter_mm}
                                readOnly="readonly"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Label>{string.PIPE_INSERTION_DEPTH}</Label>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Label className="sublabel">{unit}</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={(units) ? this.state.resultsInsertionDepth.pipe_insertion_depth_inches || '' : this.state.resultsInsertionDepth.pipe_insertion_depth_mm}
                                readOnly="readonly"
                            />
                        </Col>
                    </Row>
                </div>
            )
        }

        //INSTALLATION PART OF THE APPLICATION
        const tableInstallation = () => {
            const results = this.state.resultsInstallation
            const styleType = this.state.selectedStyleType.value
            const units = this.state.units;
            const unit = (units) ? string.INCHES : string.MM;
            // console.log(this.state.resultsInstallation)
            //Renders the second select field based on chosen style
            const sizeSelect = () => {
                if (styleType === 1 || styleType === 2 || styleType === 4 || styleType === 5) {
                    if (units) {
                        return (
                            <Row className="extra-padding" >
                                <Col xs={12}>
                                    <Label>{this.props.strings.NOMINAL_SIZE} {unit}</Label>
                                    <Select
                                        options={this.state.installation_nominalSizeOptions_inches}
                                        getOptionValue={option => option.value}
                                        placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                                        className="input-filter"
                                        onChange={this.setInstallationFilter}
                                        value={this.state.installation_secondSelect}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}
                                        styles={customSelectStyles}
                                        components={{
                                            IndicatorSeparator: () => null,
                                            DropdownIndicator: () => (
                                                <Indicator className="icon-down-micro" />
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                        )
                    } else {
                        return (
                            <Row className="extra-padding">
                                <Col xs={12}>
                                    <Label>{this.props.strings.NOMINAL_SIZE} {unit}</Label>
                                    <Select
                                        options={this.state.installation_nominalSizeOptions_mm}
                                        getOptionValue={option => option.value}
                                        placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                                        className="input-filter"
                                        onChange={this.setInstallationFilter}
                                        value={this.state.installation_secondSelect}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}
                                        styles={customSelectStyles}
                                        components={{
                                            IndicatorSeparator: () => null,
                                            DropdownIndicator: () => (
                                                <Indicator className="icon-down-micro" />
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                        )
                    }
                } else if (styleType === 3) {
                    if (units) {
                        return (
                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.NOMINAL_OUTLET_SIZE} {unit}</Label>
                                    <Select
                                        options={this.state.installation_nominalOutletSizeOptions_inches}
                                        getOptionValue={option => option.value}
                                        placeholder={this.props.strings.SELECT_A_NOMINAL_OUTLET_SIZE}
                                        className="input-filter"
                                        onChange={this.setInstallationFilter}
                                        value={this.state.installation_secondSelect}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}
                                        styles={customSelectStyles}
                                        components={{
                                            IndicatorSeparator: () => null,
                                            DropdownIndicator: () => (
                                                <Indicator className="icon-down-micro" />
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                        )
                    } else {
                        return (
                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.NOMINAL_OUTLET_SIZE} {unit}</Label>
                                    <Select
                                        options={this.state.installation_nominalOutletSizeOptions_mm}
                                        getOptionValue={option => option.value}
                                        placeholder={this.props.strings.SELECT_A_NOMINAL_OUTLET_SIZE}
                                        className="input-filter"
                                        value={this.state.installation_secondSelect}
                                        onChange={this.setInstallationFilter}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}
                                        styles={customSelectStyles}
                                        components={{
                                            IndicatorSeparator: () => null,
                                            DropdownIndicator: () => (
                                                <Indicator className="icon-down-micro" />
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                        )
                    }
                } else if (styleType === 6) {
                    return (
                        <Row className="extra-padding">
                            <Col xs={12}>
                                <Label>{this.props.strings.VALVE_SIZE}</Label>
                                <Select
                                    options={this.state.installation_valveSizeOptions}
                                    getOptionValue={option => option.value}
                                    placeholder={this.props.strings.SELECT_A_VALVE_SIZE}
                                    className="input-filter"
                                    onChange={this.setInstallationFilter}
                                    value={this.state.installation_secondSelect}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0,
                                    })}
                                    styles={customSelectStyles}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator: () => (
                                            <Indicator className="icon-down-micro" />
                                        )
                                    }}
                                />
                            </Col>
                        </Row>
                    )
                }
            }

            //Renders the result boxes based on chosen style
            const resultTable = () => {
                if (styleType === 1) {
                    //STYLE 904
                    return (
                        <div>
                            <Row>
                                <Col xs={12}>
                                    <CategoryHeader>
                                        {this.props.strings.STYLE_904_HELPFULL_INFORMATION}
                                    </CategoryHeader>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.ACTUAL_PIPE_OUTSIDE_DIAMETER}</Label>
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.actual_outside_diameter_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.MM}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.actual_outside_diameter_mm}
                                        readOnly="readonly" />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.FLANGE_BOLTS}</Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.REQUIRED_NUMBER_OF_BOLTS}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.required_number_of_bolts}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.BOLT_DIAMETER_INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.bolt_diameter_inches}
                                        readOnly="readonly" />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{ReactHtmlParser(this.props.strings.TORQUE_VALUES)}</Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6}>
                                    <Label className="sublabel">{ReactHtmlParser(this.props.strings.TYPICAL_FT_LBS)}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={ReactHtmlParser(results.torque_value_typical_ft_lbs)}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{ReactHtmlParser(this.props.strings.MAXIMUM_FT_LBS)}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.torque_value_maximum_ft_lbs}
                                        readOnly="readonly" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Subtext>
                                        {ReactHtmlParser(this.props.strings.I_900_904_TABLE_INFO)}
                                    </Subtext>
                                </Col>
                            </Row>

                        </div>
                    )
                } else if (styleType === 2) {
                    //STYLE 994
                    return (
                        <div>
                            <Row>
                                <Col xs={12}>
                                    <CategoryHeader>
                                        {this.props.strings.STYLE_994_HELPFULL_INFORMATION}
                                    </CategoryHeader>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.ACTUAL_PIPE_OUTSIDE_DIAMETER}</Label>
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.actual_outside_diameter_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.MM}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.actual_outside_diameter_mm}
                                        readOnly="readonly" />
                                </Col>
                            </Row>


                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.ASSEMBLY_BOLTS}</Label>
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.REQUIRED_NUMBER_OF_BOLTS}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.required_number_assembly_bolts}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.BOLT_SIZE_INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.assembly_bolt_size_inches}
                                        readOnly="readonly" />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.DRAW_BOLTS}</Label>
                                </Col>
                                <Col xs={12}>
                                    <Label className="sublabel">{this.props.strings.REQUIRED_NUMBER_OF_BOLTS}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.required_number_draw_bolts}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.BOLT_SIZE_INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.draw_bolt_size_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.SOCKET_SIZE_INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.draw_bolt_socket_size_inches}
                                        readOnly="readonly" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.REQUIRED_MATING_FACE_SEALING_SURFACE}</Label>
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.A_MAX} {this.props.strings.INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.mating_face_sealing_surface_a_max_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.A_MAX} {this.props.strings.MM}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.mating_face_sealing_surface_a_max_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.B_MIN} {this.props.strings.INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.mating_face_sealing_surface_b_min_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.B_MIN} {this.props.strings.MM}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.mating_face_sealing_surface_b_min_mm}
                                        readOnly="readonly" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Subtext>
                                        {ReactHtmlParser(this.props.strings.I_900_994_TABLE_INFO)}
                                    </Subtext>
                                </Col>
                            </Row>
                        </div>
                    )
                } else if (styleType === 3) {
                    return (
                        <div>
                            <Row>
                                <Col xs={12}>
                                    <CategoryHeader>
                                        {this.props.strings.STYLE_920_920N_MECHANICAL_T_PIPE_PREP_DIMENSIONS}
                                    </CategoryHeader>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.MIN_HOLE_DIAMETER_HOLE_SAW_SIZE}</Label>
                                </Col>
                                <Col xs={12}>
                                    <Label className="sublabel">{unit}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={(units) ? results.min_hole_diameter_inches || '' : results.min_hole_diameter_mm}
                                        readOnly="readonly" />
                                </Col>

                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.MAX_ALLOWABLE_DIAMETER}</Label>
                                </Col>
                                <Col xs={12}>
                                    <Label className="sublabel">{unit}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={(units) ? results.max_allowable_diameter_inches || '' : results.max_allowable_diameter_mm}
                                        readOnly="readonly" />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.SURFACE_PREP_A_DIMENSION}</Label>
                                </Col>
                                <Col xs={12}>
                                    <Label className="sublabel">{unit}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={(units) ? results.max_surface_prep_a_dimension_inches || '' : results.max_surface_prep_a_dimension_mm}
                                        readOnly="readonly" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Subtext>
                                        {ReactHtmlParser(this.props.strings.I_900_920_920N_TABLE_INFO)}
                                    </Subtext>
                                </Col>
                            </Row>
                        </div>
                    )
                } else if (styleType === 4 || styleType === 5) {
                    return (
                        <div>
                            <Row>
                                <Col xs={12}>
                                    <CategoryHeader>
                                        {this.props.strings.STYLE} {this.state.selectedStyleType.label} {this.props.strings.HELPFULL_INFORMATION}
                                    </CategoryHeader>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.ACTUAL_PIPE_OUTSIDE_DIAMETER}</Label>
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.actual_outside_diameter_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.MM}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.actual_outside_diameter_mm}
                                        readOnly="readonly" />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.NUT_SIZE}</Label>
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.nut_size_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.METRIC}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.nut_size_mm}
                                        readOnly="readonly" />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.SOCKET_SIZE}</Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.socket_size_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.METRIC}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.socket_size_mm}
                                        readOnly="readonly" />
                                </Col>
                            </Row>
                        </div>
                    )
                } else if (styleType === 6) {
                    return (
                        <div>
                            <Row>
                                <Col xs={12}>
                                    <CategoryHeader>
                                        {this.props.strings.SERIES_906_HELPFUL_INFO}
                                    </CategoryHeader>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.SPACER_BOLT_SIZE}</Label>
                                </Col>
                                <Col xs={12}>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.spacer_bolt_size}
                                        readOnly="readonly" />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.SPACER_BOLT_SOCKET}</Label>
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.spacer_bolt_socket_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.MM}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.spacer_bolt_socket_mm}
                                        readOnly="readonly" />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.COUPLING_NUT_SIZE}</Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.coupling_nut_size}
                                        readOnly="readonly" />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Label>{this.props.strings.SPACER_BOLT_SOCKET}</Label>
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.INCHES}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.coupling_nut_deep_well_socket_inches}
                                        readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Label className="sublabel">{this.props.strings.MM}</Label>
                                    <Input
                                        type="text"
                                        placeholder="&mdash;"
                                        value={results.coupling_nut_deep_well_socket_mm}
                                        readOnly="readonly" />
                                </Col>
                            </Row>
                        </div>
                    )
                }
            }

            //Unit selector for units of measurement, rendered based on chosen style
            const unitSelector = () => {
                if (styleType === 3 || styleType === 4 || styleType === 5) {
                    return (
                        <Row className="extra-padding">
                            <Col xs={6}>
                                <Label>{this.props.strings.UNITS}</Label>
                            </Col>
                            <Col xs={6} className="toggle-container">
                                <label>
                                    <Toggle
                                        checked={units}
                                        className="victaulic-toggle"
                                        icons={false}
                                        onChange={this.changeUnitsInstall} />
                                    <Span className="victaulic-toggle-label">
                                        {(this.state.units === true ? this.props.strings.INCHES : this.props.strings.MM)}
                                    </Span>
                                </label>
                            </Col>
                        </Row>
                    )
                } else {
                    return '';
                }
            }

            return (
                <div>
                    {/* <Row>
                        <Col xs={12}>
                            <Subtext>
                                {ReactHtmlParser(this.props.strings.I_600_SECTION_1_HEADER_TUBING_LENGTH)}
                            </Subtext>
                        </Col>
                    </Row>  */}
                    {installLabel()}
                    <Row>
                        <Col xs={12}>
                            <Label>{this.props.strings.STYLE}</Label>
                            <Select
                                options={state.styleOptions}
                                getOptionValue={option => option.value}
                                placeholder={this.props.strings.SELECT_A_STYLE}
                                // value={(this.state.selectedStyleType === {}) ? null : this.state.selectedStyleType  }
                                className="input-filter"
                                onChange={this.setStyleType}
                                // isDisabled={state.toolStandardSelectedDisabled ? true : null}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>

                    </Row>
                    {unitSelector()}
                    {sizeSelect()}
                    {resultTable()}
                    {/* <Row>
                        <Col xs={12}>
                            <CategoryHeader>
                                {string.TUBING_LENGTH}
                            </CategoryHeader>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6}>
                            <Label className="sublabel">{string.MIN}</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.tubing_length_min || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={6}>
                            <Label className="sublabel">{string.MAX}</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.tubing_length_max || ''}
                                readOnly="readonly"
                            />
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Subtext>
                                {ReactHtmlParser(this.props.strings.I_600_SECTION_1_FOOTER_TUBING_LENGTH)}
                            </Subtext>
                        </Col>
                    </Row>  */}
                </div>
            )
        }

        const selectedTable = () => {
            if (selectedInstallType.value === 1) {
                return tableInstallationDepth();
            } else if (selectedInstallType.value === 2) {
                return tableInstallation();
            }
        }

        return (
            <Grid>
                {/* <Row>
                    <Col xs={12}>
                        <SectionCaution>
                            <h2><i className="icon-caution icon-round-bg"></i> {this.props.strings.WARNING}</h2>
                            <div>{ReactHtmlParser(this.props.strings.I_600_SECTION_1_WARNING)}</div>
                        </SectionCaution>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_600_SECTION_1_HEADER)}
                        </Subtext>
                    </Col>
                </Row>  */}
                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.state.sizeString}</Label>
                        <Select
                            options={state.installTypeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_INSTALL_TYPE}
                            className="input-filter"
                            onChange={this.setInstallType}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>
                {selectedTable()}
            </Grid>
        );
    }
};

const SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    SectionCaution = styled.div`
        border: 1px solid #333333;
        padding: 0;
        margin: 20px 0 0 0;

        h2 {
            font-family: Rajdhani, sans-serif;
            font-size: 20px;
            background: #333333;
            color: #ffffff;
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
            margin:0 0 20px 0;

            i {
                font-size: 14px;
                vertical-align: middle;
                position:relative;
                top:-1px;
            }
        }

        div {
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            text-transform: none;
            padding: 0 10px;
            position: relative;
            top: -10px;
            margin: 0 0 5px 0;
        }

        .icon-round-bg {
            background: #ff7f00;
            color: #ffffff;
        }
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `,
    Span = styled.span`
        text-transform: uppercase;
    `;

export default CopperTubePreparation;