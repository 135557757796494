import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionSection extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
        isOpen: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    onClick = () => {
        this.props.onClick(this.props.label);
    };

    render() {
        const { onClick, props: { isOpen, label } } = this;

        return (
            <div className="nav-menu-header">
                <div
                    onClick={onClick}
                    className={this.props.className}
                >
                    <span>{label}</span>
                    <div className="nav-menu-arrow-container">
                        {!isOpen && <span className={((this.props.active === 1) ? "icon-down white" : "icon-down")}></span>}
                        {isOpen && <span className="icon-up white"></span>}
                    </div>

                </div>
                {isOpen && (
                    <div className="sub-menu-container">
                        {this.props.children}
                    </div>
                )}
            </div>
        );
    }
}

export default AccordionSection;
