import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Navigation from './shared/Navigation';

class Guide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            languages: JSON.parse(localStorage.getItem('languages')),
            strings: JSON.parse(localStorage.getItem('strings')),
            handbookId: this.props.match.params.guide,
            handbook: _.get(JSON.parse(localStorage.getItem('handbooks')), "id_" + this.props.match.params.guide),
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: false
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    openUrl = (url) => {
        window.open(url, '_blank');
    }

    render() {

        return this.state.loading ?
            (
                <main>
                    <Navigation languages={this.state.languages} strings={this.state.strings} />
                    <SectionContainer />
                </main>
            ) :
            (
                <main>
                    <Navigation languages={this.state.languages} strings={this.state.strings} />
                    <HeaderContainer>
                        <Header>{this.state.handbook.handbook.code}</Header>
                        <Subheader>Field Installation</Subheader>
                    </HeaderContainer>

                    <SectionContainer>
                        {
                            this.state.handbook.sections.map((section, index) =>
                                <SectionLink key={section.id}>
                                    <Link to={{
                                        pathname: `${process.env.REACT_APP_PUBLIC_URL}section/${this.state.handbookId}/${section.id}`,
                                        state: { languages: this.state.languages, strings: this.state.strings }
                                    }}>
                                        {section.string}
                                        <ArrowContainer>
                                            <Arrow className="icon-back" />
                                        </ArrowContainer>
                                    </Link>
                                </SectionLink>
                            )
                        }
                    </SectionContainer>

                    <QuickLinksContainer>
                        <QuickLinksHeader>{this.state.strings.QUICK_LINKS}</QuickLinksHeader>
                        <Grid>
                            <Row>
                                <Col xs={6}>
                                    <QuickLinksList>
                                        <QuickLink><a href={this.state.strings.QUICK_LINK_1_URL} target="_blank" rel="noopener noreferrer external">{this.state.strings.QUICK_LINK_1}</a></QuickLink>
                                        <QuickLink><a href={this.state.strings.QUICK_LINK_2_URL} target="_blank" rel="noopener noreferrer external">{this.state.strings.QUICK_LINK_2}</a></QuickLink>
                                        <QuickLink><a href={this.state.strings.QUICK_LINK_3_URL} target="_blank" rel="noopener noreferrer external">{this.state.strings.QUICK_LINK_3}</a></QuickLink>
                                    </QuickLinksList>
                                </Col>
                                <Col xs={6}>
                                    <QuickLinksList>
                                        <QuickLink><a href={this.state.strings.QUICK_LINK_4_URL} target="_blank" rel="noopener noreferrer external">{this.state.strings.QUICK_LINK_4}</a></QuickLink>
                                        <QuickLink><a href={this.state.strings.QUICK_LINK_5_URL} target="_blank" rel="noopener noreferrer external">{this.state.strings.QUICK_LINK_5}</a></QuickLink>
                                        <QuickLink><a href={this.state.strings.QUICK_LINK_6_URL} target="_blank" rel="noopener noreferrer external">{this.state.strings.QUICK_LINK_6}</a></QuickLink>
                                    </QuickLinksList>
                                </Col>
                            </Row>
                        </Grid>
                    </QuickLinksContainer>
                </main >
            );
    };
}

const HeaderContainer = styled.div`
        width: 100%;
        background: #ff7f00;
        position: relative;
        top: 39px;
        font-family: Rajdhani, sans-serif;
        color: #ffffff;
    `,
    Header = styled.h1`
        font-size: 36px;
        text-align: center;
        position: relative;
        top: 15px;
    `,
    Subheader = styled.p`
        font-size: 20px;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        top: -15px;
    `,
    SectionContainer = styled.ul`
        font-family: Rajdhani, sans-serif;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        list-style: none;
        width: 100%;
        position: relative;
        top: 30px;
        left: -40px;
    `,
    SectionLink = styled.li`
        border-bottom: 0.5px solid #efefef;

        a {
            display: inline-block;
            line-height: 20px;
            clear: both;
            text-decoration: none;
            color: #000000;
            padding: 30px 15% 0 15px;
            width: 80%;

            &:visited {
                color: #000000;
                text-decoration: none;
                border: 0;
            }
        }
    `,
    QuickLinksContainer = styled.div`
        width: 100%;
        margin: 0;
        padding: 5px 0 0 0;
        background: #f8f8f8;
        position: relative;
        bottom: -50px;
    `,
    QuickLinksHeader = styled.h2`
        font-family: Rajdhani, sans-serif;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0 10px 0 18px;
    `,
    QuickLinksList = styled.ul`
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        list-style: none;
        padding: 0 0 0 10px;
        margin: 0;
        width: 45%;
        min-height: 200px;
        display: inline-block;
    `,
    QuickLink = styled.li`
        margin-bottom: 10px;
        a, button {
            font-family: 'Open Sans',sans-serif;
            font-size: 18px;
            font-weight: bold;
            color: #5493b3;
            text-decoration: none;
        }
    `,
    ArrowContainer = styled.div`
        right: 15px;
        position: relative;
        z-index: 2;
        top: -25px;
        right: -15%;
        transform: rotate(180deg);
        color: #f89828;
    `,
    Arrow = styled.i`
        font-size: 26px;
        vertical-align: middle;

    `;

export default Guide;