import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import Toggle from 'react-toggle';

import '../../../assets/css/app.css';
import '../../../assets/css/react-toggle.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class AwwaPipeEndSeparation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=awwa_pipe_end_separation',
            styleInformation: [],
            styleInformationOptions: [],
            styleDescription: '',
            selectedStyle: [],
            units: localStorage.getItem('units') !== undefined ? (localStorage.getItem('units') === 'true') : true, // TRUE --> IMPERIAL || FALSE --> METRIC
            nominalSize: [],
            nominalSizeOptions: {
                inches: [],
                mm: [],
            },
            selectedNominalSize: [],
            resultHeader: this.props.strings.PIPE_END_SEPARATION,
            results: {
                allowablePipeEndSeparationIn: '',
                allowablePipeEndSeparationMm: '',
                deflectionFromCenterlineDegreesPerCoupling: '',
                deflectionFromCenterlineInchesPerFootPipe: '',
                deflectionFromCenterlineInchesPerMeterPipe: '',
                fixedPipeEndSeparationInches: '',
                fixedPipeEndSeparationMm: '',
                numberBoltsRequired: '',
                boltSizeInches: '',
                requiredMatingFaceSealingSurfaceAMaxIn: '',
                requiredMatingFaceSealingSurfaceAMaxMm: '',
                requiredMatingFaceSealingSurfaceBMaxIn: '',
                requiredMatingFaceSealingSurfaceBMaxMm: '',
                torqueRequirementsFtLbs: '',
                torqueRequirementsNM: '',
            },
        };
    }

    getStyles = async () => {
        axios.get(this.state.getSectionDataUrl + '&select=style&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_awwa_pipe_end_separation_styles', JSON.stringify(response.data.data));
                let styleOptions = [];
                response.data.data.map((value, key) => {
                    styleOptions.push({ 'value': value.style, 'label': value.style });
                    return true;
                });
                this.setState({ styleInformationOptions: styleOptions });
            })
            .catch(console.log);
    }

    getNominalSizes = async (units) => {
        axios.get(this.state.getSectionDataUrl + '&select=nominal_diameter_' + units + '&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_awwa_pipe_end_separation_nominal_size_' + units, JSON.stringify(response.data.data));
                let nominalSizes = [];
                response.data.data.map((value, key) => {
                    if (value.nominal_diameter_inches || value.nominal_diameter_mm) {
                        nominalSizes.push({
                            'value': (units === 'inches' ? value.nominal_diameter_inches : value.nominal_diameter_mm),
                            'label': (units === 'inches' ? value.nominal_diameter_inches : value.nominal_diameter_mm)
                        });
                    }
                    return true;
                });
                units === 'inches'
                    ? this.setState({ nominalSizeOptions: { inches: nominalSizes, mm: this.state.nominalSizeOptions.mm } })
                    : this.setState({ nominalSizeOptions: { inches: this.state.nominalSizeOptions.inches, mm: nominalSizes } });
            })
            .catch(console.log);
    }

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_awwa_pipe_end_separation', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.getStyles(), this.getData(), this.getNominalSizes('inches'), this.getNominalSizes('mm')])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                });
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    resetResults = () => {
        this.setState({
            results: {
                allowablePipeEndSeparationIn: '',
                allowablePipeEndSeparationMm: '',
                deflectionFromCenterlineDegreesPerCoupling: '',
                deflectionFromCenterlineInchesPerFootPipe: '',
                deflectionFromCenterlineInchesPerMeterPipe: '',
                fixedPipeEndSeparationInches: '',
                fixedPipeEndSeparationMm: '',
                numberBoltsRequired: '',
                boltSizeInches: '',
                requiredMatingFaceSealingSurfaceAMaxIn: '',
                requiredMatingFaceSealingSurfaceAMaxMm: '',
                requiredMatingFaceSealingSurfaceBMaxIn: '',
                requiredMatingFaceSealingSurfaceBMaxMm: '',
                torqueRequirementsFtLbs: '',
                torqueRequirementsNM: '',
            },
        })
    }

    changeUnits = (e) => {
        this.setState({
            units: e.target.checked,
        });
        this.setState({ selectedNominalSize: null });
        this.resetResults();
        localStorage.setItem('units', e.target.checked)
    };

    setStyleInformation = (s) => {
        if (s !== null) {
            this.setState({
                selectedStyle: { 'value': s.value, 'label': s.label },
                selectedNominalSize: { 'value': '', 'label': '' },
                resultHeader: s.label,
            }, () => {
                // filter the Size options
                let optionsIn = [];
                let optionsMm = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_awwa_pipe_end_separation')),
                        { 'style': this.state.selectedStyle.value }
                    ), (values, index) => {
                        if (values.nominal_diameter_inches) {
                            optionsIn.push(values);
                        }
                        if (values.nominal_diameter_mm) {
                            optionsMm.push(values);
                        }
                    });
                let nominalSizeOptionsIn = [];
                let nominalSizeOptionsMm = [];
                let map = new Map();
                for (const item of optionsIn) {
                    if (!map.has(item.nominal_diameter_inches) && item.nominal_diameter_inches !== '' && item.nominal_diameter_inches !== null) {
                        map.set(item.nominal_diameter_inches, true);    // set any value to Map
                        nominalSizeOptionsIn.push({ value: item.nominal_diameter_inches, label: item.nominal_diameter_inches });
                    }
                }
                let map2 = new Map();
                for (const item of optionsMm) {
                    if (!map2.has(item.nominal_diameter_mm) && item.nominal_diameter_mm !== '' && item.nominal_diameter_mm !== null) {
                        map2.set(item.nominal_diameter_mm, true);    // set any value to Map
                        nominalSizeOptionsMm.push({ value: item.nominal_diameter_mm, label: item.nominal_diameter_mm });
                    }
                }
                this.setState({
                    nominalSizeOptions: { inches: nominalSizeOptionsIn, mm: nominalSizeOptionsMm }
                })

                if (this.state.selectedStyle.value.toString().includes('Style 31')) {
                    this.setState({ styleDescription: this.props.strings.STYLE_31_DESCRIPTION })
                } else if (this.state.selectedStyle.value.toString().includes('Style 307')) {
                    this.setState({ styleDescription: this.props.strings.STYLE_307_DESCRIPTION })
                } else if (this.state.selectedStyle.value.toString().includes('Style 341')) {
                    this.setState({ styleDescription: this.props.strings.STYLE_341_DESCRIPTION })
                } else {
                    this.setState({ styleDescription: '' });
                }

                this.resetResults();
            });
        } else {
            this.resetResults();
            this.setState({
                styleDescription: '',
                resultHeader: this.props.strings.PIPE_END_SEPARATION,
            });
        }
    }

    setNominalSize = (n) => {
        if (n !== null) {
            this.setState({
                selectedNominalSize: { 'value': n.value, 'label': n.label },
            }, () => {
                let filter = (this.state.units === true
                    ? { 'style': this.state.selectedStyle.value, 'nominal_diameter_inches': this.state.selectedNominalSize.value }
                    : { 'style': this.state.selectedStyle.value, 'nominal_diameter_mm': this.state.selectedNominalSize.value }
                );
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_awwa_pipe_end_separation')),
                    filter
                );
                this.setResults(data);
            });
        } else {
            this.resetResults();
        }
    }

    setResults = (data) => {
        if (Object.entries(this.state.selectedStyle).length > 0 &&
            Object.entries(this.state.selectedNominalSize).length > 0) {
            this.setState({
                results: {
                    allowablePipeEndSeparationIn: data.allowable_pipe_end_separation_in,
                    allowablePipeEndSeparationMm: data.allowable_pipe_end_separation_mm,
                    deflectionFromCenterlineDegreesPerCoupling: data.deflection_from_centerline_degrees_per_coupling,
                    deflectionFromCenterlineInchesPerFootPipe: data.deflection_from_centerline_inches_per_foot_pipe,
                    deflectionFromCenterlineInchesPerMeterPipe: data.deflection_from_centerline_inches_per_meter_pipe,
                    fixedPipeEndSeparationInches: data.fixed_pipe_end_separation_inches,
                    fixedPipeEndSeparationMm: data.fixed_pipe_end_separation_mm,
                    numberBoltsRequired: data.number_bolts_required,
                    boltSizeInches: data.bolt_size_inches,
                    requiredMatingFaceSealingSurfaceAMaxIn: data.required_mating_face_sealing_surface_a_max_in,
                    requiredMatingFaceSealingSurfaceAMaxMm: data.required_mating_face_sealing_surface_a_max_mm,
                    requiredMatingFaceSealingSurfaceBMaxIn: data.required_mating_face_sealing_surface_b_max_in,
                    requiredMatingFaceSealingSurfaceBMaxMm: data.required_mating_face_sealing_surface_b_max_mm,
                    torqueRequirementsFtLbs: data.torque_requirements_ft_lbs,
                    torqueRequirementsNM: data.torque_requirements_nm,
                },
            });
        } else {
            this.resetResults();
        }
    }

    render() {

        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionCaution>
                            <h2><i className="icon-caution icon-round-bg"></i> {this.props.strings.CAUTION}</h2>
                            <div>{ReactHtmlParser(this.props.strings.I_300_SECTION_4_WARNING)}</div>
                        </SectionCaution>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.STYLE} / {this.props.strings.INFORMATION}</Label>
                        <Select
                            options={this.state.styleInformationOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_STYLE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setStyleInformation}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.SELECT_A_PIPE_SIZE}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.NOMINAL} {this.props.strings.DIAMETER} - {(this.state.units === true ? this.props.strings.INCHES : this.props.strings.MM)}</Label>
                        <Select
                            options={(this.state.units === true ? this.state.nominalSizeOptions.inches : this.state.nominalSizeOptions.mm)}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                            className="input-filter"
                            isClearable={true}
                            isDisabled={Object.entries(this.state.selectedStyle).length === 0}
                            onChange={this.setNominalSize}
                            value={this.state.selectedNominalSize}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className="extra-padding">
                    <Col xs={6}>
                        <Label>{this.props.strings.UNITS}</Label>
                    </Col>
                    <Col xs={6} className="toggle-container">
                        <label>
                            <Toggle
                                defaultChecked={this.state.units}
                                className="victaulic-toggle"
                                icons={false}
                                onChange={this.changeUnits} />
                            <span className="victaulic-toggle-label">
                                {(this.state.units === true ? this.props.strings.IMPERIAL : this.props.strings.METRIC)}
                            </span>
                        </label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.state.resultHeader}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row className={this.state.results.allowablePipeEndSeparationIn === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.ALLOWABLE_PIPE_END_SEPARATION}</Label><br />
                        <Label className="sublabel">{this.props.strings.INCHES}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.allowablePipeEndSeparationIn} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.allowablePipeEndSeparationMm === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.ALLOWABLE_PIPE_END_SEPARATION}</Label><br />
                        <Label className="sublabel">{this.props.strings.MILLIMETERS}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.allowablePipeEndSeparationMm} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.deflectionFromCenterlineDegreesPerCoupling === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.DEFLECTION_FROM_CENTERLINE}</Label><br />
                        <Label className="sublabel">{this.props.strings.DEGREES_PER_COUPLING}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.deflectionFromCenterlineDegreesPerCoupling} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.deflectionFromCenterlineInchesPerFootPipe === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.DEFLECTION_FROM_CENTERLINE}</Label><br />
                        <Label className="sublabel">{this.props.strings.INCHES_PER_FOOT} {this.props.strings.PIPE}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.deflectionFromCenterlineInchesPerFootPipe} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.deflectionFromCenterlineInchesPerMeterPipe === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.DEFLECTION_FROM_CENTERLINE}</Label><br />
                        <Label className="sublabel">{this.props.strings.MM_PER_METER} {this.props.strings.PIPE}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.deflectionFromCenterlineInchesPerMeterPipe} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.fixedPipeEndSeparationInches === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.FIXED_PIPE_END_SEPARATION}</Label><br />
                        <Label className="sublabel">{this.props.strings.INCHES}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.fixedPipeEndSeparationInches} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.fixedPipeEndSeparationMm === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.FIXED_PIPE_END_SEPARATION}</Label><br />
                        <Label className="sublabel">{this.props.strings.MILLIMETERS}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.fixedPipeEndSeparationMm} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.numberBoltsRequired === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.NUMBER_OF_BOLTS_REQUIRED}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.numberBoltsRequired} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.boltSizeInches === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.BOLT_SIZE}</Label><br />
                        <Label className="sublabel">{this.props.strings.INCHES}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.boltSizeInches} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.requiredMatingFaceSealingSurfaceAMaxIn === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.REQUIRED_MATING_FACE_SEALING_SURFACE} "A" {this.props.strings.MAX}</Label><br />
                        <Label className="sublabel">{this.props.strings.INCHES}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.requiredMatingFaceSealingSurfaceAMaxIn} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.requiredMatingFaceSealingSurfaceAMaxMm === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.REQUIRED_MATING_FACE_SEALING_SURFACE} "A" {this.props.strings.MAX}</Label><br />
                        <Label className="sublabel">{this.props.strings.MILLIMETERS}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.requiredMatingFaceSealingSurfaceAMaxMm} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.requiredMatingFaceSealingSurfaceBMaxIn === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.REQUIRED_MATING_FACE_SEALING_SURFACE} "B" {this.props.strings.MAX}</Label><br />
                        <Label className="sublabel">{this.props.strings.INCHES}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.requiredMatingFaceSealingSurfaceBMaxIn} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.requiredMatingFaceSealingSurfaceBMaxMm === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.REQUIRED_MATING_FACE_SEALING_SURFACE} "B" {this.props.strings.MAX}</Label><br />
                        <Label className="sublabel">{this.props.strings.MILLIMETERS}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.requiredMatingFaceSealingSurfaceBMaxMm} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.torqueRequirementsFtLbs === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.TORQUE_REQUIREMENTS}</Label><br />
                        <Label className="sublabel">{this.props.strings.FOOT_POUNDS}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.torqueRequirementsFtLbs} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.torqueRequirementsNM === '' ? "hide" : ""}>
                    <Col xs={12}>
                        <Label>{this.props.strings.TORQUE_REQUIREMENTS}</Label><br />
                        <Label className="sublabel">{ReactHtmlParser(this.props.strings.NEWTON_METERS)}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.torqueRequirementsNM} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.state.styleDescription)}
                        </Subtext>
                    </Col>
                </Row>
            </Grid>
        );
    }
};

const SectionCaution = styled.div`
        border: 1px solid #333333;
        padding: 0;
        margin: 20px 0;
        font-weight: bold;

        h2 {
            font-family: Rajdhani, sans-serif;
            font-size: 20px;
            background: #333333;
            color: #ffffff;
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
            margin:0 0 20px 0;

            i {
                font-size: 14px;
                vertical-align: middle;
                position:relative;
                top:-1px;
            }
        }

        div {
            font-family: 'Open Sans', sans-serif;
            text-transform: none;
            padding: 0 10px;
            position: relative;
            top: -10px;
            margin: 0 0 5px 0;
        }

        .icon-round-bg {
            background: #ff7f00;
            color: #ffffff;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 0;
        margin: 0 0 15px 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding: 15px 2px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;
    `;

export default AwwaPipeEndSeparation;