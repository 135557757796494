import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class SupportSpacing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedNominalSize: '',
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=awwa_support_spacing',
            nominalSize: [],
            results: {
                maxSpanBetweenSupportsFeet: '',
                maxSpanBetweenSupportsMeters: '',
            },
        };
    }

    setNominalSizeOptions = (response) => {
        let nominalSize = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_nominal_size'));
        data.map((value, key) => {
            nominalSize.push({ 'value': value.nominal_diameter_in, 'label': value.nominal_diameter_in + ' ' + value.nominal_diameter_mm });
            return true;
        });
        this.setState({
            nominalSizeOptions: nominalSize
        });
    };

    getNominalSize = async () => {
        // Retrieve the nominal_size for dropdown from the API
        axios.get(this.state.getSectionDataUrl + '&select=nominal_diameter_in|nominal_diameter_mm')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_nominal_size', JSON.stringify(response.data.data));
                this.setNominalSizeOptions();
            })
            .catch(console.log);
    };


    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_awwa_support_spacing', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.getNominalSize(), this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    setNominalSize = n => {
        if (n !== null) {
            this.setState({
                selectedNominalSize: { 'value': n.value, 'label': n.label },
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_awwa_support_spacing')),
                    ['nominal_diameter_in', this.state.selectedNominalSize.value]
                );
                // filter the Size options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_awwa_support_spacing')),
                        ['nominal_size', this.state.selectedNominalSize.value]
                    ), (values, index) => {
                        options.push(values)
                    });
                this.setResults(data, this.state.nominalSizeOptions);
            });
        } else {
            this.setNominalSizeOptions();
            this.setState({
                selectedNominalSize: '',
                results: {
                    maxSpanBetweenSupportsFeet: '',
                    maxSpanBetweenSupportsMeters: '',
                },
            });
        }
    };

    setResults = (data, nominalSizeOptions) => {
        try {
            if (Object.entries(this.state.selectedNominalSize).length > 0) {
                this.setState({
                    selectedNominalSize: { 'value': data.nominal_diameter_in, 'label': data.nominal_diameter_in + ' ' + data.nominal_diameter_mm },
                    results: {
                        maxSpanBetweenSupportsFeet: data.max_span_between_supports_feet,
                        maxSpanBetweenSupportsMeters: data.max_span_between_supports_meters,
                    },
                });
            } else {
                this.setState({
                    results: {
                        maxSpanBetweenSupportsFeet: '',
                        maxSpanBetweenSupportsMeters: '',
                    },
                });
            }
            this.setState({
                nominalSizeOptions: nominalSizeOptions,
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    };

    render() {

        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionIntro>
                            <strong>{this.props.strings.FLEXIBLE_SYSTEMS}</strong><br />
                            {this.props.strings.I_300_SECTION_3_INTRO}
                        </SectionIntro>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.SELECT_A_PIPE_SIZE}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label> {this.props.strings.NOMINAL} {this.props.strings.DIAMETER} - {this.props.strings.INCHES} ({this.props.strings.MM})</Label>
                        <Select
                            options={this.state.nominalSizeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setNominalSize}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.SUGGESTED_MAX_SPAN_BETWEEN_SUPPORTS}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row className={this.state.results.minimumLength === '‡' ? "show" : "hide"}>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_300_SECTION_3_WARNING)}
                        </Subtext>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Label>{this.props.strings.FEET}</Label>
                        <Input type="text" placeholder="&mdash;" id="minimum" value={this.state.results.maxSpanBetweenSupportsFeet} readOnly="readonly" />
                    </Col>
                    <Col xs={6}>
                        <Label>{this.props.strings.METERS}</Label>
                        <Input type="text" placeholder="&mdash;" id="maximum" value={this.state.results.maxSpanBetweenSupportsMeters} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_300_SECTION_3_FOOTER)}
                        </Subtext>
                    </Col>
                </Row>
            </Grid>
        );
    }
};

const SectionIntro = styled.p`
        border-bottom: 0.5px solid #efefef;
        padding-bottom: 15px;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 0;
        margin: 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;
    `;

export default SupportSpacing;