import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import InstallPwa from './InstallPwa';
import HomeLayout from './shared/HomeLayout';

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            termsSet: localStorage.getItem('terms'),
            languages: JSON.parse(localStorage.getItem('languages')),
            strings: JSON.parse(localStorage.getItem('strings')),
            getLanguageUrl: process.env.REACT_APP_API_URL + 'language',
            next: localStorage.getItem('language') !== undefined ? process.env.REACT_APP_PUBLIC_URL + 'home' : process.env.REACT_APP_PUBLIC_URL + 'language',
        };
        this.deferredPrompt = props.deferredPrompt;
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    acceptTerms = () => {
        localStorage.setItem('terms', 'accepted');
        this.setState({ termsSet: 'accepted' });
    };

    render() {
        if (this.state.termsSet === 'accepted' && this.deferredPrompt) return (
            <InstallPwa deferredPrompt={this.deferredPrompt} />
        );

        else if (this.state.termsSet === 'accepted' && !this.deferredPrompt) return (
            <Redirect to={{
                pathname: this.state.next,
                state: {
                    languages: this.state.language,
                    strings: this.state.strings,
                }
            }} />
        );

        else return (
            <main>
                <HomeLayout language={this.state.language} strings={this.state.strings} />
                <TermsContainer>
                    <Heading>Terms of Use</Heading>
                    <TermsContent><strong>{this.state.strings.NOTE}:</strong> {this.state.strings.TERMS_OF_USE_CONTENT}, <a
                        href="https://victaulic.com"
                        rel="noopener noreferrer"
                        target="_blank">{this.state.strings.CLICK_HERE}</a>.</TermsContent>
                </TermsContainer>
                <ButtonContainer>
                    <button
                        className="accept-button" onClick={() => this.acceptTerms()}>
                        {this.state.strings.I_ACCEPT}
                    </button>
                </ButtonContainer>
            </main>
        );
    };
};

const Heading = styled.h1`
        width: 100%;
        margin: 0;
        color #ffffff;
        text-align: center;
        text-transform: uppercase;
        font-size: 24px;
        font-family: Rajdhani, sans-serif;
        position: absolute;
        top: -50px;
    `,
    TermsContainer = styled.div`
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        position: relative;
        width: 100%;
        margin: 45% 3% 0 3%;

        width: 94%;
        background-color: #ffffff;
    `,
    TermsContent = styled.p`
        margin: 0 24px;
        padding: 20px 0;

        a {
            font-weight: bold;
            color: #f89828;
        }
    `,
    ButtonContainer = styled.div`
        width: 100%;
        text-align: center;
        margin: 25px 0 0;

        button {
            display: block;
            margin: 0 3%;
            width: 94%;
            border: none;
            position: relative;
            top: -18px;
            text-transform: uppercase;
            color: #ffffff;
            background-color: #5493b3;
            padding: 12px;
            font-weight: bold;
            font-size: 18px;
            font-family: Rajdhani, sans-serif;
            clear: both;

            &:hover, &:active {
                background-color: #64a6bb;

            }
        }
    `;

export default Terms;