import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import Toggle from 'react-toggle';

import '../../../assets/css/app.css';
import '../../../assets/css/react-toggle.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class HdpeBoltsSockets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=hdpe_bolts_and_sockets',
            styleOptions: [],
            selectedStyle: [],
            //907
            pipeType: localStorage.getItem('type') !== undefined ? (localStorage.getItem('type') === 'true') : true, // TRUE --> ISO || FALSE --> IPS
            pipeSizeOptions: {
                iso: [],
                ips: [],
            },
            selectedPipeSize: [],
            //995
            units: localStorage.getItem('units') !== undefined ? (localStorage.getItem('units') === 'true') : true, // TRUE --> IMPERIAL || FALSE --> METRIC
            nominalSizeOptions: {
                inches: [],
                mm: [],
            },
            selectedNominalSize: [],
            results: {
                boltNutSizeInches: '',
                boltNutSizeMM: '',
                socketSizeInches: '',
                socketSizeMM: '',
                actualDiameterIn: '',
                actualDiameterMM: ''
            },
        };
    }

    getData = async () => {
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hdpe_bolts_and_sockets', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([
            this.getStyles(),
            this.getPipeSize('ips'), 
            this.getPipeSize('iso'), 
            this.getNominalSizes('inches'),
            this.getNominalSizes('mm'),
            this.getData()
            ])
            .then(axios.spread(() => {
                this.setState({
                    loading: false,
                });
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    /* - - - - - - - - - - - - - - - - - - - - - - - - - -
    /* 907
    */
    getStyles = async () => {
        axios.get(this.state.getSectionDataUrl + '&select=style&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hdpe_bolts_and_sockets_styles', JSON.stringify(response.data.data));
                let styleOptions = [];
                response.data.data.map((value, key) => {
                    styleOptions.push({ 'value': value.style, 'label': value.style });
                    return true;
                });
                this.setState({ styleOptions: styleOptions });
            })
            .catch(console.log);
    }

    getPipeSize = async (type) => {
        axios.get(this.state.getSectionDataUrl + '&select=iso_nominal_pipe_size_mm_plain_end_x_grooved_end|ips_nominal_pipe_size_inches&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hdpe_bolts_and_sockets_pipe_sizes', JSON.stringify(response.data.data));
                let pipeSizeOptions = [];
                response.data.data.map((value, key) => {
                    if(value.ips_nominal_pipe_size_inches || value.iso_nominal_pipe_size_mm_plain_end_x_grooved_end) {
                        pipeSizeOptions.push({ 
                            'value': (type === 'iso' ? value.iso_nominal_pipe_size_mm_plain_end_x_grooved_end : value.iso_nominal_pipe_size_inches), 
                            'label': (type === 'iso' ? value.iso_nominal_pipe_size_mm_plain_end_x_grooved_end : value.iso_nominal_pipe_size_inches), 
                        });
                    }
                    return true;
                });
                type === 'iso'
                    ? this.setState({ pipeSizeOptions: { iso: pipeSizeOptions, ips: this.state.pipeSizeOptions.ips} })
                    : this.setState({ pipeSizeOptions: { iso: this.state.pipeSizeOptions.iso, ips: pipeSizeOptions} });
            })
            .catch(console.log);
    }

    setStyles = (s) => {
        if (s !== null) {
            this.setState({
                selectedStyle: { 'value': s.value, 'label': s.label },
                selectedPipeSize: { 'value': '', 'label': '' },
            }, () => {
                // filter the Size options
                let optionsISO = [];
                let optionsIPS = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_hdpe_bolts_and_sockets')),
                        { 'style': this.state.selectedStyle.value }
                    ), (values, index) => {
                        if (values.iso_nominal_pipe_size_mm_plain_end_x_grooved_end) {
                            optionsISO.push(values);
                        }
                        if (values.ips_nominal_pipe_size_inches) {
                            optionsIPS.push(values);
                        }
                    });
                if(this.state.selectedStyle.value === '907') {
                }

                if(this.state.selectedStyle.value === '995') {
                    // console.log('995');
                }
                let pipeSizeOptionsISO = [];
                let pipeSizeOptionsIPS = [];
                let map = new Map();
                for (const item of optionsISO) {
                    if (!map.has(item.iso_nominal_pipe_size_mm_plain_end_x_grooved_end) && item.iso_nominal_pipe_size_mm_plain_end_x_grooved_end !== '' && item.iso_nominal_pipe_size_mm_plain_end_x_grooved_end !== null) {
                        map.set(item.iso_nominal_pipe_size_mm_plain_end_x_grooved_end, true);    // set any value to Map
                        pipeSizeOptionsISO.push({ value: item.iso_nominal_pipe_size_mm_plain_end_x_grooved_end, label: item.iso_nominal_pipe_size_mm_plain_end_x_grooved_end });
                    }
                }
                let map2 = new Map();
                for (const item of optionsIPS) {
                    if (!map2.has(item.ips_nominal_pipe_size_inches) && item.ips_nominal_pipe_size_inches !== '' && item.ips_nominal_pipe_size_inches !== null) {
                        map2.set(item.ips_nominal_pipe_size_inches, true);    // set any value to Map
                        pipeSizeOptionsIPS.push({ value: item.ips_nominal_pipe_size_inches, label: item.ips_nominal_pipe_size_inches });
                    }
                }

                this.setState({
                    pipeSizeOptions: { 
                        iso: pipeSizeOptionsISO, 
                        ips: pipeSizeOptionsIPS 
                    }
                })

                this.resetResults();
            });
        } else {
            this.resetResults();
        }
    }

    setPipeSize = (n) => {
        if (n !== null) {
            this.setState({
                selectedPipeSize: { 'value': n.value, 'label': n.label },
            }, () => {
                let filter = (this.state.pipeType === true
                    ? { 'style': this.state.selectedStyle.value, 'iso_nominal_pipe_size_mm_plain_end_x_grooved_end': this.state.selectedPipeSize.value }
                    : { 'style': this.state.selectedStyle.value, 'ips_nominal_pipe_size_inches': this.state.selectedPipeSize.value }
                );
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_hdpe_bolts_and_sockets')),
                    filter
                );
                this.setResults(data);
            });
        } else {
            this.resetResults();
        }
    }

    changeType = (e) => {
        this.setState({
            pipeType: e.target.checked,
        });
        this.setState({ selectedPipeSize: null });
        this.resetResults();
        localStorage.setItem('pipeType', e.target.checked)
    };

    resetResults = () => {
        this.setState({
            results: {
                boltNutSizeInches: '',
                boltNutSizeMM: '',
                socketSizeInches: '',
                socketSizeMM: '',
                actualDiameterIn: '',
                actualDiameterMM: ''
            },
        })
    }

    /* - - - - - - - - - - - - - - - - - - - - - - - - - -
    /* 995
    */
    getNominalSizes = async (units) => {
        axios.get(this.state.getSectionDataUrl + '&select=nominal_size_' + units + '&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hdpe_bolts_and_sockets_nominal_size_' + units, JSON.stringify(response.data.data));
                let nominalSizes = [];
                response.data.data.map((value, key) => {
                    if (value.nominal_size_inches || value.nominal_size_mm) {
                        nominalSizes.push({
                            'value': (units === 'inches' ? value.nominal_size_inches : value.nominal_size_mm),
                            'label': (units === 'inches' ? value.nominal_size_inches : value.nominal_size_mm)
                        });
                    }
                    return true;
                });
                units === 'inches'
                    ? this.setState({ nominalSizeOptions: { inches: nominalSizes, mm: this.state.nominalSizeOptions.mm } })
                    : this.setState({ nominalSizeOptions: { inches: this.state.nominalSizeOptions.inches, mm: nominalSizes } });
            })
            .catch(console.log);
    }

    setNominalSize = (n) => {
        if (n !== null) {
            this.setState({
                selectedNominalSize: { 'value': n.value, 'label': n.label },
            }, () => {
                let filter = (this.state.units === true
                    ? { 'style': this.state.selectedStyle.value, 'nominal_size_inches': this.state.selectedNominalSize.value }
                    : { 'style': this.state.selectedStyle.value, 'nominal_size_mm': this.state.selectedNominalSize.value }
                );
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_hdpe_bolts_and_sockets')),
                    filter
                );
                this.setResults(data);
            });
        } else {
            this.resetResults();
        }
    }

    changeUnits = (e) => {
        this.setState({
            units: e.target.checked,
        });
        this.setState({ selectedNominalSize: null });
        this.resetResults();
        localStorage.setItem('units', e.target.checked)
    };

    /* - - - - - - - - - - - - - - - - - - - - - - - - - -
    /* RESULTS
    */
    setResults = (data) => {
        if (Object.entries(this.state.selectedStyle).length > 0 &&
            (Object.entries(this.state.selectedPipeSize).length > 0 || Object.entries(this.state.selectedNominalSize).length > 0 ) ) {
            // console.log(data);
            this.setState({
                results: {
                    //more goes here
                    boltNutSizeInches: data.bolt_nut_size_inches,
                    boltNutSizeMM: data.bolt_nut_size_mm,
                    socketSizeInches: data.socket_size_inches,
                    socketSizeMM: data.socket_size_mm,
                    actualDiameterIn: data.actual_pipe_outside_diameter_inches,
                    actualDiameterMM: data.actual_pipe_outside_diameter_mm
                },
            });
        } else {
            this.resetResults();
        }
    }

    render() {
        const results = this.state.results;
        const strings = this.props.strings;
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{strings.STYLE}</Label>
                        <Select
                            options={this.state.styleOptions}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_A_STYLE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setStyles}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>
                
                <Row className={this.state.selectedStyle.value != null ? "" : "hide"}>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.state.selectedStyle.value} {strings.HELPFUL_INFORMATION}
                        </SelectionHeader>
                    </Col>
                </Row>

                {/* 907 HELPFUL INFO */}
                <Row className={this.state.selectedStyle.value === '907' ? "" : "hide"}>
                    <Col xs={6}>
                        <Label>{strings.PIPE_TYPE}</Label>
                    </Col>
                    <Col xs={6} className="toggle-container">
                        <label>
                            <Toggle
                                defaultChecked={this.state.pipeType}
                                className="victaulic-toggle"
                                icons={false}
                                onChange={this.changeType} />
                            <span className="victaulic-toggle-label">
                                {(this.state.pipeType === true ? 'ISO' : 'IPS')}
                            </span>
                        </label>
                    </Col>
                </Row>

                <Row className={this.state.selectedStyle.value === '907' ? "extra-padding" : "hide"}>
                    <Col xs={12} className={this.state.pipeType != null ? "" : "hide"}>
                        <Label>{strings.PIPE_SIZE} ({(this.state.pipeType === true ? strings.MM : strings.INCHES)})</Label>
                        <Select
                            options={(this.state.pipeType === true ? this.state.pipeSizeOptions.iso : this.state.pipeSizeOptions.ips)}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_A_PIPE_SIZE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setPipeSize}
                            value={this.state.selectedPipeSize}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedStyle.value === '907' ? "" : "hide"}>
                    <Col xs={12}>
                        <CategoryHeader>
                            {strings.BOLT} / {strings.SOCKET_SIZE}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row className={this.state.selectedStyle.value === '907' ? "" : "hide"}>
                    <Col xs={12} className={this.state.pipeType === true ? "hide" : ""}>
                        <Label>{strings.BOLT} / {strings.NUT_SIZE} ({strings.INCHES})</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="bolt_nut_size_inches_in" 
                            value={results.boltNutSizeInches || ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                    <Col xs={12} className={this.state.pipeType === true ? "hide" : ""}>
                        <Label>{strings.SOCKET_SIZE} ({strings.INCHES})</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="socket_size_inches_in" 
                            value={results.socketSizeInches || ''} 
                            readOnly="readonly" 
                        />
                    </Col>

                    <Col xs={12} className={this.state.pipeType === false ? "hide" : ""}>
                        <Label>{strings.BOLT} / {strings.NUT_SIZE} - {strings.METRIC} ({strings.INCHES})</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="bolt_nut_size_inches_mm" 
                            value={ (results.boltNutSizeInches && results.boltNutSizeMM) ? (results.boltNutSizeInches + ' (' + results.boltNutSizeMM + ')') : ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                    <Col xs={12} className={this.state.pipeType === false ? "hide" : ""}>
                        <Label>{strings.SOCKET_SIZE} - {strings.MM} ({strings.INCHES})</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="socket_size_inches_mm" 
                            value={ (results.socketSizeMM && results.socketSizeInches) ? (results.socketSizeMM + ' (' + results.socketSizeInches + ')') : '' } 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>

                {/* 995 HELPFUL INFO */}
                <Row className={this.state.selectedStyle.value === '995' ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.NOMINAL_SIZE} ({(this.state.units === true ? this.props.strings.INCHES : this.props.strings.MM)})</Label>
                        <Select
                            options={(this.state.units === true ? this.state.nominalSizeOptions.inches : this.state.nominalSizeOptions.mm)}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                            className="input-filter"
                            isClearable={true}
                            isDisabled={Object.entries(this.state.selectedStyle).length === 0}
                            onChange={this.setNominalSize}
                            value={this.state.selectedNominalSize}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedStyle.value === '995' ? "extra-padding" : "hide"}>
                    <Col xs={6}>
                        <Label>{this.props.strings.UNITS}</Label>
                    </Col>
                    <Col xs={6} className="toggle-container">
                        <label>
                            <Toggle
                                defaultChecked={this.state.units}
                                className="victaulic-toggle"
                                icons={false}
                                onChange={this.changeUnits} />
                            <span className="victaulic-toggle-label">
                                {(this.state.units === true ? this.props.strings.IMPERIAL : this.props.strings.METRIC)}
                            </span>
                        </label>
                    </Col>
                </Row>

                <Row className={this.state.selectedStyle.value === '995' ? "" : "hide"}>
                    <Col xs={12} className={this.state.units === true ? "" : "hide"}>
                        <Label>{strings.ACTUAL_PIPE_OUTSIDE_DIAMETER} ({strings.INCHES} / {strings.MM})</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="actual_pipe_outside_diameter_inches_mm" 
                            value={ (results.actualDiameterIn && results.actualDiameterMM) ? (results.actualDiameterIn + ' / ' + results.actualDiameterMM) : '' } 
                            readOnly="readonly" 
                        />
                    </Col>
                    <Col xs={12} className={this.state.units === false ? "" : "hide"}>
                        <Label>{strings.SOCKET_SIZE} ({strings.MM})</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="socket_size_inches_mm_995" 
                            value={results.socketSizeMM || ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                    <Col xs={12}>
                        <Label>{strings.SOCKET_SIZE} ({strings.INCHES})</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="socket_size_inches_in_995" 
                            value={results.socketSizeInches || ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>
                <Row className={this.state.selectedStyle.value === '995' ? "extra-padding" : "hide"}>
                    <Col xs={12} className={this.state.units === false ? "" : "hide"}>
                        <Subtext>
                            {ReactHtmlParser(strings.I_900_SECTION_2_NOTE)}
                        </Subtext>
                    </Col>

                    <Col xs={12}>
                        <Subtext>
                            {strings.I_900_SECTION_2_STAR}
                        </Subtext>
                    </Col>
                </Row>
            </Grid>);
    }
}

const SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default HdpeBoltsSockets;