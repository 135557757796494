import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class GrooveSpecification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=cpvc_groove_dimensions',
            getSectionDataHeaderUrl: process.env.REACT_APP_API_URL + 'get/section?table=cpvc_groove_dimensions',
            nominalSizes : {},
            selectedNominalSize: null,
            results: {
                pipeOutsideDiameter: { basic: '', max: '', min: '' },
                gasketSeatA: { basic: '', max: '', min: '' },
                grooveWidthB: { basic: '', max: '', min: '' },
                grooveDiameterC: { max: '', min: '' },
                grooveDepthD: '',
                pipeEndChamferXMax: '',
                minAllowWallThicknessT: '',
            },
        };
    }

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_groove_types', JSON.stringify(response.data.data));
                let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_types'));
                const sizes = [];
                let map = new Map();
                for (const item of data) {
                    if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                        map.set(item.nominal_size, true);    // set any value to Map
                        sizes.push({ value: item.nominal_size, label: item.nominal_size });
                    }
                }
                this.setState({
                    nominalSizes: sizes,
                })
                
            })
            .catch(console.log);
    };

    

    getAllData = async () => {
        await axios.all([this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
            
    };

    componentDidMount() {
        this.getAllData();
    }


    setNominalSize = n => {
        this.setState({
            selectedNominalSize: { 'value': n.value, 'label': n.label },
        }, () => {
            this.setResults();
        });
    };

    setResults = () => {
        const selectedNominalSize = this.state.selectedNominalSize;
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_types'));
        try {
            if (Object.entries(selectedNominalSize).length > 0) {
                let results = _.forEach(_.find(data, {
                    'nominal_size': selectedNominalSize.value
                }))
                // console.log(results)
                this.setState({
                    results: {
                        pipeOutsideDiameter: { 
                            basic: results.pipe_outside_diameter_basic, 
                            max: results.pipe_outside_diameter_max,
                            min: results.pipe_outside_diameter_min 
                        },
                        gasketSeatA: { 
                            basic: results.gasket_seat_a_basic, 
                            max: results.gasket_seat_a_max, 
                            min: results.gasket_seat_a_min 
                        },
                        grooveWidthB: { 
                            basic: results.groove_width_b_basic, 
                            max: results.groove_width_b_max, 
                            min: results.groove_width_b_min 
                        },
                        grooveDiameterC: { 
                            max: results.groove_diameter_c_max, 
                            min: results.groove_diameter_c_min 
                        },
                        grooveDepthD: results.groove_depth_d,
                        pipeEndChamferXMax: results.pipe_end_chamfer_x_max,
                        minAllowWallThicknessT: results.min_wall_thickness_t,
                    },
                });
            }
            this.setState({
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    };

    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionCaution>
                            <h2><i className="icon-caution icon-round-bg"></i> Danger</h2>
                            <div>{ReactHtmlParser(this.props.strings.I_350_SECTION_2_WARNING)}</div>
                        </SectionCaution>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label Label>{this.props.strings.NOMINAL_SIZE_INCHES_DN}</Label>
                        <Select
                            options={this.state.nominalSizes}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                            className="input-filter"
                            onChange={this.setNominalSize}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.NOMINAL_SIZE_INCHES_DN}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.PIPE_OUTSIDE_DIAMETER}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.BASIC}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.pipeOutsideDiameter.basic || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MAX}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.pipeOutsideDiameter.max || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MIN}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.pipeOutsideDiameter.min || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GASKET_SEAT_A}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.BASIC}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.gasketSeatA.basic || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MAX}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.gasketSeatA.max || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MIN}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.gasketSeatA.min || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GROOVE_WIDTH_B}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.BASIC}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveWidthB.basic || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MAX}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveWidthB.max || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MIN}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveWidthB.min || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GROOVE_DIAMETER_C}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Label className="sublabel">{this.props.strings.MAX}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveDiameterC.max || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={6}>
                        <Label className="sublabel">{this.props.strings.MIN}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveDiameterC.min || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GROOVE_DEPTH_D_REF}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveDepthD || ''} className="full" readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.PIPE_END_CHAMFER_X_MAX}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.pipeEndChamferXMax || ''} className="full" readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.MIN_WALL_THICKNESS_T}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.minAllowWallThicknessT || ''} className="full" readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SectionDivider />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_350_SECTION_2_FOOTER_1)}
                        </Subtext>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_350_SECTION_2_FOOTER_2)}
                        </Subtext>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_350_SECTION_2_FOOTER_3)}
                        </Subtext>
                    </Col>
                </Row>

            </Grid>
        );
    }
};

const SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    SectionCaution = styled.div`
        border: 1px solid #333333;
        padding: 0;
        margin: 20px 0 0 0;

        h2 {
            font-family: Rajdhani, sans-serif;
            font-size: 20px;
            background: #333333;
            color: #ffffff;
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
            margin:0 0 20px 0;

            i {
                font-size: 14px;
                vertical-align: middle;
                position:relative;
                top:-1px;
            }
        }

        div {
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            text-transform: none;
            padding: 0 10px;
            position: relative;
            top: -10px;
            margin: 0 0 5px 0;
        }

        .icon-round-bg {
            background: #ff7f00;
            color: #ffffff;
        }
    `,
    SectionDivider = styled.div`
        border-top: 1px solid #efefef;
        border-collapse: collapse;
        height: 1px;
        margin: 10px 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 15px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default GrooveSpecification;