import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import Toggle from 'react-toggle';

import '../../../assets/css/app.css';
import '../../../assets/css/react-toggle.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class CpvcFlangeConnections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=cpvc_flange_connections',
            units: localStorage.getItem('units') !== undefined ? (localStorage.getItem('units') === 'true') : true, // TRUE --> IMPERIAL || FALSE --> METRIC
            nominalDiameter: [],
            nominalDiameterOptions: {
                inches: [],
                dn: [],
            },
            selectedNominalDiameter: [],
            results: {
                numberOfBoltHoles: '',
                boltDiameterInches: '',
                boltDiameterMM: '',
                minBoltLengthInches: '',
                minBoltLengthMM: '',
                recommendedTorqueFtLbs: '',
                recommendedTorqueNm: '',
            },
        };
    }

    getNominalDiameters = async (units) => {
        axios.get(this.state.getSectionDataUrl + '&select=nominal_diameter_' + units + '|actual_outside_diameter_'+(units === 'inches' ? 'inches' : 'mm')+'&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_cpvc_flange_connections_nominal_diameter_' + units, JSON.stringify(response.data.data));
                let nominalDiameters = [];
                response.data.data.map((value, key) => {
                    // console.log(response.data.data);

                    if (value.nominal_diameter_inches || value.nominal_diameter_dn) {
                        nominalDiameters.push({
                            'value': (units === 'inches' ? value.nominal_diameter_inches : value.nominal_diameter_dn),
                            'label': (units === 'inches' ? value.nominal_diameter_inches+' ('+value.actual_outside_diameter_inches+')' : value.nominal_diameter_dn+' ('+value.actual_outside_diameter_mm+')')
                        });
                    }
                    return true;
                });
                units === 'inches'
                    ? this.setState({ nominalDiameterOptions: { inches: nominalDiameters, dn: this.state.nominalDiameterOptions.dn } })
                    : this.setState({ nominalDiameterOptions: { inches: this.state.nominalDiameterOptions.inches, dn: nominalDiameters } });
            })
            .catch(console.log);
    }

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_cpvc_flange_connections', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    }

    getAllData = async () => {
        await axios.all([this.getData(), this.getNominalDiameters('inches'), this.getNominalDiameters('dn')])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                });
            }))
            .finally(() => {
                this._isMounted = true;
            });
    }

    componentDidMount() {
        this.getAllData();
    }

    setNominalDiameters = (n) => {
        if (n !== null) {
            this.setState({
                selectedNominalDiameter: { 'value': n.value, 'label': n.label },
            }, () => {
                let filter = (this.state.units === true
                    ? { 'nominal_diameter_inches': this.state.selectedNominalDiameter.value }
                    : { 'nominal_diameter_dn': this.state.selectedNominalDiameter.value }
                );
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_cpvc_flange_connections')),
                    filter
                );
                this.setResults(data);
            });
        } else {
            this.resetResults();
        }
    }

    resetResults = () => {
        this.setState({
            results: {
                numberOfBoltHoles: '',
                boltDiameterInches: '',
                boltDiameterMM: '',
                minBoltLengthInches: '',
                minBoltLengthMM: '',
                recommendedTorqueFtLbs: '',
                recommendedTorqueNm: '',
            },
        })
    }

    changeUnits = (e) => {
        this.setState({
            units: e.target.checked,
        });
        this.setState({ selectedNominalDiameter: null });
        this.resetResults();
        localStorage.setItem('units', e.target.checked);
    }

    setDiameterInformation = (s) => {
        if (s !== null) {
            this.setState({
                selectedNominalDiameter: { 'value': s.value, 'label': s.label },
            }, () => {
                // filter the Size options
                let optionsIn = [];
                let optionsMm = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_cpvc_flange_connections')),
                        { 'style': this.state.selectedNominalDiameter.value }
                    ), (values, index) => {
                        if (values.nominal_diameter_inches) {
                            optionsIn.push(values);
                        }
                        if (values.nominal_diameter_dn) {
                            optionsMm.push(values);
                        }
                        // console.log(values);
                    });
                let nominalDiameterOptionsIn = [];
                let nominalDiamterOptionsDn = [];
                let map = new Map();
                for (const item of optionsIn) {
                    if (!map.has(item.nominal_diameter_inches) && item.nominal_diameter_inches !== '' && item.nominal_diameter_inches !== null) {
                        map.set(item.nominal_diameter_inches, true);    // set any value to Map
                        nominalDiameterOptionsIn.push({ value: item.nominal_diameter_inches, label: item.nominal_diameter_inches });
                    }
                }
                let map2 = new Map();
                for (const item of optionsMm) {
                    if (!map2.has(item.nominal_diameter_dn) && item.nominal_diameter_dn !== '' && item.nominal_diameter_dn !== null) {
                        map2.set(item.nominal_diameter_dn, true);    // set any value to Map
                        nominalDiamterOptionsDn.push({ value: item.nominal_diameter_dn, label: item.nominal_diameter_dn });
                    }
                }
                this.setState({
                    nominalDiameterOptions: { inches: nominalDiameterOptionsIn, dn: nominalDiamterOptionsDn }
                })

                this.resetResults();
            });
        } else {
            this.resetResults();
        }
    }

    setResults = (data) => {
        if (Object.entries(this.state.selectedNominalDiameter).length > 0) {
            this.setState({
                results: {
                    numberOfBoltHoles: data.number_of_bolt_holes,
                    boltDiameterInches: data.bolt_diameter_inches,
                    boltDiameterMM: data.bolt_diameter_mm,
                    minBoltLengthInches: data.min_bolt_length_inches,
                    minBoltLengthMM: data.min_bolt_length_mm,
                    recommendedTorqueFtLbs: data.recommeded_torque_ft_lbs,
                    recommendedTorqueNm: data.recommended_torque_n_m,
                },
            });
        } else {
            this.resetResults();
        }
    }

    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionCaution>
                            <h2><i className="icon-caution icon-round-bg"></i> {this.props.strings.CAUTION}</h2>
                            <div>{ReactHtmlParser(this.props.strings.I_350_SECTION_4_WARNING)}</div>
                        </SectionCaution>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.NOMINAL} ({this.props.strings.ACTUAL_OUTSIDE}) {this.props.strings.DIAMETER} - {(this.state.units === true ? this.props.strings.INCHES : this.props.strings.MM)}</Label>
                        <Select
                            options={(this.state.units === true ? this.state.nominalDiameterOptions.inches : this.state.nominalDiameterOptions.dn)}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_DIAMETER}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setNominalDiameters}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className="extra-padding">
                    <Col xs={6}>
                        <Label>{this.props.strings.UNITS}</Label>
                    </Col>
                    <Col xs={6} className="toggle-container">
                        <label>
                            <Toggle
                                defaultChecked={this.state.units}
                                className="victaulic-toggle"
                                icons={false}
                                onChange={this.changeUnits} />
                            <span className="victaulic-toggle-label">
                                {(this.state.units === true ? this.props.strings.IMPERIAL : this.props.strings.METRIC)}
                            </span>
                        </label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.FLANGE_CONNECTIONS}
                        </CategoryHeader>
                    </Col>
                </Row>

                
                <Row>
                    <Col xs={6}>
                        <Label>{this.props.strings.NUMBER_OF_BOLT_HOLES}</Label>
                        <Input type="text" placeholder="&mdash;" id="num_bolt_holes" value={this.state.results.numberOfBoltHoles} readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{this.props.strings.BOLT_DIAMETER} ({this.props.strings.INCHES})</Label>
                        <Input type="text" placeholder="&mdash;" id="bolt_diameter_in" value={this.state.results.boltDiameterInches} readOnly="readonly" />
                    </Col>
                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{this.props.strings.BOLT_DIAMETER} ({this.props.strings.MM})</Label>
                        <Input type="text" placeholder="&mdash;" id="bolt_diameter_mm" value={this.state.results.boltDiameterMM} readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{this.props.strings.MIN_BOLT_LENGTH} ({this.props.strings.INCHES})</Label>
                        <Input type="text" placeholder="&mdash;" id="min_bolt_length_in" value={this.state.results.minBoltLengthInches} readOnly="readonly" />
                    </Col>
                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{this.props.strings.MIN_BOLT_LENGTH} ({this.props.strings.MM})</Label>
                        <Input type="text" placeholder="&mdash;" id="min_bolt_length_mm" value={this.state.results.minBoltLengthMM} readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{this.props.strings.RECOMMENDED_TORQUE} ({this.props.strings.FOOT_POUNDS_SHORT})</Label>
                        <Input type="text" placeholder="&mdash;" id="recommended_torque_ft_lb" value={this.state.results.recommendedTorqueFtLbs} readOnly="readonly" />
                    </Col>
                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{this.props.strings.RECOMMENDED_TORQUE} ({ReactHtmlParser(this.props.strings.NEWTON_METERS)})</Label>
                        <Input type="text" placeholder="&mdash;" id="recommended_torque_nw_me" value={this.state.results.recommendedTorqueNm} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className="extra-padding">
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_350_SECTION_4_FOOTER)}
                        </Subtext>
                    </Col>
                </Row>

            </Grid>
        );
    }
};

const SectionCaution = styled.div`
        border: 1px solid #333333;
        padding: 0;
        margin: 20px 0;
        font-weight: bold;

        h2 {
            font-family: Rajdhani, sans-serif;
            font-size: 20px;
            background: #333333;
            color: #ffffff;
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
            margin:0 0 20px 0;

            i {
                font-size: 14px;
                vertical-align: middle;
                position:relative;
                top:-1px;
            }
        }

        div {
            font-family: 'Open Sans', sans-serif;
            text-transform: none;
            padding: 0 10px;
            position: relative;
            top: -10px;
            margin: 0 0 5px 0;
        }

        .icon-round-bg {
            background: #ff7f00;
            color: #ffffff;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default CpvcFlangeConnections;