import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import Toggle from 'react-toggle';

import '../../../assets/css/app.css';
import '../../../assets/css/react-toggle.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class CopperSupportSpacing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tableOptions: [
                { value: 'support_spacing', label: 'Support Spacing' },
                { value: 'helpful_information', label: 'Helpful Information' },
            ],
            selectedTableType: {value: '', label: ''},
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=copper_support_spacing',
            unit: localStorage.getItem(this.props.sectionId + '_copper_support_spacing_unit') !== undefined ? (localStorage.getItem(this.props.sectionId + '_copper_support_spacing_unit') === 'true') : true,
            nominalSelectDisabled: true,
            standardOptions: [],
            nominalSizeOptions: [],
            selectedStandard: {value: '', label: ''},
            selectedNominalSize: null,
            results: {
                maxSpanBetweenSupportsWaterServiceB319Feet: '',
                maxSpanBetweenSupportsWaterServiceB319Meters: '',
                maxSpanBetweenSupportsWaterServiceNFPAFeet: '',
                maxSpanBetweenSupportsWaterServiceNFPAMeters: '',
                maxSpanBetweenSupportsGasServiceB319Feet: '',
                maxSpanBetweenSupportsGasServiceB319Meters: '',
                maxSpanBetweenSupportsMeters: '',
            },
            getSectionHelpfulInformationDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=copper_support_spacing_helpful_information',
            helpfulInformationStyleOptions: [],
            helpfulInformationStandardOptions: [],
            helpfulInformationNominalSizeOptions: [],
            selectedHelpfulInformationStyle: null,
            selectedHelpfulInformationStandard: null,
            selectedHelpfulInformationNominalSize: null,
            helpfulInformationStandardDisabled: true,
            helpfulInformationNominalSizeDisabled: true,
            helpfulInformationResults: [],
        };
    }

    /* ****** Select Table Type ****** */
    setTableType = type => {
        if (type !== null) {
            this.setState({
                selectedTableType: { 'value': type.value, 'label': type.label },
            });
        } else {
            console.log('No Table Type');
        }
       
    }

    /* ****** Support Spacing ****** */
    setStandardOptions = () => {
        let standards = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_standard'));
        data.map((value, key) => {
            standards.push({ 'value': value.standard, 'label': value.standard });
            return true;
        });
        this.setState({ 
            standardOptions: standards 
        });
    }

    getStandardData = async () => {
        axios.get(this.state.getSectionDataUrl + '&select=standard&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_support_spacing_standard', JSON.stringify(response.data.data));
                this.setStandardOptions();
            })
            .catch(console.log);
    };

    setNominalSizeUSOptions = (unit) => {
        let nominalSizes = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_nominal_size_' + unit));
        data.map((value, key) => {
            if(unit === 'inches') { // inches
                if(value.nominal_size_inches !== '') {
                    nominalSizes.push({ 'value': value.nominal_size_inches, 'label': value.nominal_size_inches });
                }
            } else { // mm
                if(value.nominal_size_mm !== '') {
                    nominalSizes.push({ 'value': value.nominal_size_mm, 'label': value.nominal_size_mm });
                }
            }
            return true;
        });

        this.setState({ 
            nominalSizeOptions: nominalSizes 
        });
    }

    setNominalSizeAUSOptions = () => {
        let nominalSizes = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_nominal_size_dn'));
        data.map((value, key) => {
            if(value.nominal_size_dn !== '') {
                nominalSizes.push({ 'value': value.nominal_size_dn, 'label': value.nominal_size_dn });
            }
            return true;
        });
        this.setState({ 
            nominalSizeOptions: nominalSizes 
        });
    }

    getNominalSizeData = async () => {
        axios.get(this.state.getSectionDataUrl + '&select=nominal_size_inches&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_support_spacing_nominal_size_inches', JSON.stringify(response.data.data));
            })
            .catch(console.log);

        axios.get(this.state.getSectionDataUrl + '&select=nominal_size_mm&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_support_spacing_nominal_size_mm', JSON.stringify(response.data.data));
            })
            .catch(console.log);
        
        axios.get(this.state.getSectionDataUrl + '&select=nominal_size_dn&distinct=true')
        .then((response) => {
            localStorage.setItem(this.props.sectionId + '_copper_support_spacing_nominal_size_dn', JSON.stringify(response.data.data));
        })
        .catch(console.log);
    };

    setStandard = std => {
        if( std !== null ) {
            this.setState({
                selectedStandard: { 'value': std.value, 'label': std.label }
            }, () => {

                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing')),
                        { 'standard': this.state.selectedStandard.value }
                    ), (values, index) => {
                        options.push(values)
                    }
                );  
                let nominalSizeOptions = [];
                let map = new Map();

                if(this.state.selectedStandard.value === 'CTS US Standard') { // DN
                    if (this.state.unit === true) { // Inches
                        for (const item of options) {
                            if (!map.has(item.nominal_size_inches) && item.nominal_size_inches !== '' && item.nominal_size_inches !==null ) {
                                map.set(item.nominal_size_inches, true);
                                nominalSizeOptions.push({ 'value': item.nominal_size_inches, 'label': item.nominal_size_inches })
                            }
                        }
                    } else { // MM
                        for (const item of options) {
                            if (!map.has(item.nominal_size_mm) && item.nominal_size_mm !== '' && item.nominal_size_mm !==null ) {
                                map.set(item.nominal_size_mm, true);
                                nominalSizeOptions.push({ 'value': item.nominal_size_mm, 'label': item.nominal_size_mm })
                            }
                        }
                    }
                } else if (this.state.selectedStandard.value === 'Australian Standard - AS 1432') { // AUS
                    for (const item of options) {
                        if (!map.has(item.nominal_size_dn) && item.nominal_size_dn !== '' && item.nominal_size_dn !==null ) {
                            map.set(item.nominal_size_dn, true);
                            nominalSizeOptions.push({ 'value': item.nominal_size_dn, 'label': item.nominal_size_dn })
                        }
                    }
                } else { // EU
                    for (const item of options) {
                        if (!map.has(item.nominal_size_mm) && item.nominal_size_mm !== '' && item.nominal_size_mm !==null ) {
                            map.set(item.nominal_size_mm, true);
                            nominalSizeOptions.push({ 'value': item.nominal_size_mm, 'label': item.nominal_size_mm })
                        }
                    }
                }
                this.resetResults();
                this.setState({
                    nominalSizeOptions: nominalSizeOptions,
                    selectedNominalSize: null,
                });

            });
        } else {
            this.resetResults();
        }
    }

    setUnit = (e) => {
        localStorage.setItem(this.props.sectionId + '_copper_support_spacing_unit', e.target.checked);
        if(e !== null) {
            this.setState({
                unit: e.target.checked,
            }, () => {

                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing')),
                        { 'standard': this.state.selectedStandard.value }
                    ), (values, index) => {
                        options.push(values)
                    }
                );  
                let nominalSizeOptions = [];
                let map = new Map();

                if(this.state.unit === true) {
                    for (const item of options) {
                        if (!map.has(item.nominal_size_inches) && item.nominal_size_inches !== '' && item.nominal_size_inches !==null ) {
                            map.set(item.nominal_size_inches, true);
                            nominalSizeOptions.push({ 'value': item.nominal_size_inches, 'label': item.nominal_size_inches })
                        }
                    }
                } else {
                    for (const item of options) {
                        if (!map.has(item.nominal_size_mm) && item.nominal_size_mm !== '' && item.nominal_size_mm !==null ) {
                            map.set(item.nominal_size_mm, true);
                            nominalSizeOptions.push({ 'value': item.nominal_size_mm, 'label': item.nominal_size_mm })
                        }
                    }
                }
                this.setState({
                    nominalSizeOptions: nominalSizeOptions,
                    selectedNominalSize: null,
                });
            });
        } else {
            this.resetResults();
        }
        
    }

    setNominalSize = nom => {
        if(nom !== null) {
            this.setState({
                selectedNominalSize: { 'value': nom.value , 'label': nom.value }
            }, () => {
                let data = [];
                if(this.state.selectedStandard.value === 'CTS US Standard') {
                    if(this.state.unit === true) {
                        data = _.filter(
                            JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing')),
                            { 
                                'standard': this.state.selectedStandard.value,
                                'nominal_size_inches': this.state.selectedNominalSize.value
                            }
                        )
                    } else {
                        data = _.filter(
                            JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing')),
                            { 
                                'standard': this.state.selectedStandard.value,
                                'nominal_size_mm': this.state.selectedNominalSize.value
                            }
                        )
                    }
                } else if(this.state.selectedStandard.value === 'Australian Standard - AS 1432') {
                    data = _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing')),
                        { 
                            'standard': this.state.selectedStandard.value,
                            'nominal_size_dn': this.state.selectedNominalSize.value
                        }
                    )
                } else {
                    data = _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing')),
                        { 
                            'standard': this.state.selectedStandard.value,
                            'nominal_size_mm': this.state.selectedNominalSize.value
                        }
                    )
                }
                data = data[0];
                this.setResults(data);
            });
        } else {
            this.resetResults();
        }
    }

    resetResults = () => {
        this.setState({
            results: {
                maxSpanBetweenSupportsWaterServiceB319Feet: '',
                maxSpanBetweenSupportsWaterServiceB319Meters: '',
                maxSpanBetweenSupportsWaterServiceNFPAFeet: '',
                maxSpanBetweenSupportsWaterServiceNFPAMeters: '',
                maxSpanBetweenSupportsGasServiceB319Feet: '',
                maxSpanBetweenSupportsGasServiceB319Meters: '',
                maxSpanBetweenSupportsMeters: '',
            },
        })
    }

    /* ********* Helpful Information *********** */
    setHelpfulInformationStyleOptions = () => {
        let helpfulInformationStyles = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_helpful_style'));
        data.map((value, key) => {
            helpfulInformationStyles.push({ 'value': value.style, 'label': value.style });
            return true;
        });
        this.setState({ 
            helpfulInformationStyleOptions: helpfulInformationStyles 
        });
    }

    getHelpfulInformationStyles = async () => {
        axios.get(this.state.getSectionHelpfulInformationDataUrl + '&select=style&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_support_spacing_helpful_style', JSON.stringify(response.data.data));
                this.setHelpfulInformationStyleOptions();
            })
            .catch(console.log);
    };

    setHelpfulInformationStandardOptions = () => {
        let helpfulInformationStandards = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_helpful_standard'));
        data.map((value, key) => {
            if(value.standard !== '') {
                helpfulInformationStandards.push({ 'value': value.standard, 'label': value.standard });
            }
            return true;
        });
        this.setState({ 
            helpfulInformationStandardOptions: helpfulInformationStandards 
        });
    }

    getHelpfulInformationStandards = async () => {
        axios.get(this.state.getSectionHelpfulInformationDataUrl + '&select=standard&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_support_spacing_helpful_standard', JSON.stringify(response.data.data));
                this.setHelpfulInformationStandardOptions();
            })
            .catch(console.log);
    };

    setHelpfulInformationNominalSizeOptions = () => {
        let helpfulInformationNominalSizes = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_helpful_nominal_size'));
        data.map((value, key) => {
            if(value.nominal_size !== '') {
                helpfulInformationNominalSizes.push({ 'value': value.nominal_size, 'label': value.nominal_size });
            }
            return true;
        });
        this.setState({ 
            helpfulInformationNominalSizeOptions: helpfulInformationNominalSizes 
        });
    }

    getHelpfulInformationNominalSizes = async () => {
        axios.get(this.state.getSectionHelpfulInformationDataUrl + '&select=nominal_size&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_support_spacing_helpful_nominal_size', JSON.stringify(response.data.data));
                this.setHelpfulInformationNominalSizeOptions();
            })
            .catch(console.log);
    };

    setHelpfulInformationStyle = st => {
        if(st !== null) {
            this.setState({
                selectedHelpfulInformationStyle: { 'value': st.value, 'label': st.value }
            }, () => {
                let data = _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_helpful')),
                    { 'style': this.state.selectedHelpfulInformationStyle.value }
                );
                    
                // Filter Diameter Options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_helpful')),
                        {
                            'style': this.state.selectedHelpfulInformationStyle.value, 
                        }
                    ), (values, index) => {
                        options.push(values)
                    }
                );
                let helpfulInformationStandardOptions = [];
                let helpfulInformationNominalSizeOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.standard) && item.standard !== '' && item.standard !==null ) {
                        map.set(item.standard, true);
                        helpfulInformationStandardOptions.push({ 'value': item.standard, 'label': item.standard })
                    }
                }
                for (const item of options) {
                    if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !==null ) {
                        map.set(item.nominal_size, true);
                        helpfulInformationNominalSizeOptions.push({ 'value': item.nominal_size, 'label': item.nominal_size })
                    }
                }
                this.setHelpfulInformationResults(data, helpfulInformationStandardOptions, helpfulInformationNominalSizeOptions);
                this.setState({
                    helpfulInformationStandardDisabled: null,
                    helpfulInformationNominalSizeDisabled: true,
                    selectedHelpfulInformationStandard: null,
                    selectedHelpfulInformationNominalSize: null,
                });

                if(this.state.selectedHelpfulInformationStyle.value === 'Style 622') {
                    this.setState({
                        helpfulInformationResults: [{
                            nut_size: data[0].nut_size,
                            run_size: data[0].run_size,
                            bolt_size: data[0].bolt_size,
                            socket_size: data[0].socket_size,
                            bolts_required: data[0].bolts_required,
                            required_mating_face_sealing_surface_a_max: data[0].required_mating_face_sealing_surface_a_max,
                            required_mating_face_sealing_surface_b_max: data[0].required_mating_face_sealing_surface_b_max,
                        }],
                    });
                
                
                } else {
                    this.clearResults();
                }

                
            });
        } else {
            this.setHelpfulInformationStyleOptions();
            this.setHelpfulInformationStandardOptions();
            this.setHelpfulInformationNominalSizeOptions();
            this.setState({
                selectedHelpfulInformationStyle: null,
                selectedHelpfulInformationStandard: null,
                selectedHelpfulInformationNominalSize: null,
                helpfulInformationResults: []
            });
        }
    }
    
    setHelpfulInformationStandard = stndr => {
        if( stndr !== null ) {
            this.setState({ selectedHelpfulInformationStandard: { 'value': stndr.value, 'label': stndr.label } }, ()=> {
                let data = _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_helpful')),
                    {
                        'style': this.state.selectedHelpfulInformationStyle.value, 
                        'standard': this.state.selectedHelpfulInformationStandard.value,
                    }
                );

                // Filter Diameter Options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_helpful')),
                        {
                            'style': this.state.selectedHelpfulInformationStyle.value, 
                            'standard': this.state.selectedHelpfulInformationStandard.value,
                        }
                    ), (values, index) => {
                        options.push(values)
                    }
                );

                let helpfulInformationNominalSizeOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null ) {
                        map.set(item.nominal_size, true);
                        helpfulInformationNominalSizeOptions.push({ 'value': item.nominal_size, 'label': item.nominal_size })
                    }
                }
                this.setHelpfulInformationResults(data, this.state.helpfulInformationStandardOptions, helpfulInformationNominalSizeOptions);
                this.setState({
                    helpfulInformationNominalSizeDisabled: null,
                    selectedHelpfulInformationNominalSize: null,
                });
                this.clearResults();
            });

            
        } else {
            this.setHelpfulInformationStyleOptions();
            this.setHelpfulInformationStandardOptions();
            this.setHelpfulInformationNominalSizeOptions();
            this.setState({
                selectedHelpfulInformationStyle: null,
                selectedHelpfulInformationStandard: null,
                selectedHelpfulInformationNominalSize: null,
                helpfulInformationResults: []
            });
        }
    }

    setHelpfulInformationNominalSize = nom => {
        if( nom !== null) {
            this.setState({ selectedHelpfulInformationNominalSize: { 'value': nom.value, 'label': nom.label } }, ()=> {
                let data = _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_support_spacing_helpful')),
                    {
                        'style': this.state.selectedHelpfulInformationStyle.value, 
                        'standard': this.state.selectedHelpfulInformationStandard.value,
                        'nominal_size': this.state.selectedHelpfulInformationNominalSize.value
                    }                                        
                );

                this.setHelpfulInformationResults(data, this.state.helpfulInformationStandardOptions, this.state.helpfulInformationNominalSizeOptions);
            });

            
        } else {
            this.setHelpfulInformationStyleOptions();
            this.setHelpfulInformationStandardOptions();
            this.setHelpfulInformationNominalSizeOptions();
            this.setState({
                selectedHelpfulInformationStyle: null,
                selectedHelpfulInformationStandard: null,
                selectedHelpfulInformationNominalSize: null,
                helpfulInformationResults: []
            });
        }

    }

    setHelpfulInformationResults = (data, helpfulInformationStandardOptions, helpfulInformationNominalSizeOptions) => {
        try {
            if (Object.entries(this.state.selectedHelpfulInformationStyle).length > 0 &&
                this.state.selectedHelpfulInformationStandard !== null &&
                this.state.selectedHelpfulInformationNominalSize !== null) {
                
                this.setState({ 
                    selectedHelpfulInformationStyle: { 'value': data[0].style, 'label': data[0].style },
                    selectedHelpfulInformationStandard: { 'value': data[0].standard, 'label': data[0].standard },
                    selectedHelpfulInformationNominalSize: { 'value': data[0].nominal_size, 'label': data[0].nominal_size },
                    helpfulInformationResults: data,
                });

            }

            this.setState({
                helpfulInformationStandardOptions: helpfulInformationStandardOptions,
                helpfulInformationNominalSizeOptions: helpfulInformationNominalSizeOptions,
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    }

    clearResults = () => {
        this.setState({
            helpfulInformationResults: []
        });
    }
    
    /* ********* Get Data / Set Results ******** */
    getData = async () => {
        //Get all data Support Spacing
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_support_spacing', JSON.stringify(response.data.data));
            })
            .catch(console.log);
        
        //Get all data Helpful Information
        axios.get(this.state.getSectionHelpfulInformationDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_support_spacing_helpful', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([   
            this.getStandardData(),
            this.getNominalSizeData(),
            this.getHelpfulInformationStyles(),
            this.getHelpfulInformationStandards(),
            this.getHelpfulInformationNominalSizes(),
            this.getData()
        ])
        .then(axios.spread(() => {
            // All requests are now complete
            this.setState({
                loading: false,
            });

        }))
        .finally(() => {
            this._isMounted = true;
        });
    };

    componentDidMount() {
        this.getAllData();
    }

    setResults = (data) => {
        if (Object.entries(this.state.selectedStandard).length > 0 && 
        this.state.selectedNominalSize !== null) {
            this.setState({
                results: {
                    maxSpanBetweenSupportsWaterServiceB319Feet: data.max_span_between_supports_water_service_b31_9_feet,
                    maxSpanBetweenSupportsWaterServiceB319Meters: data.max_span_between_supports_water_service_b31_9_meters,
                    maxSpanBetweenSupportsWaterServiceNFPAFeet: data.max_span_between_supports_water_service_nfpa_feet,
                    maxSpanBetweenSupportsWaterServiceNFPAMeters: data.max_span_between_supports_water_service_nfpa_meters,
                    maxSpanBetweenSupportsGasServiceB319Feet: data.max_span_between_supports_gas_service_b31_9_feet,
                    maxSpanBetweenSupportsGasServiceB319Meters: data.max_span_between_supports_gas_service_b31_9_meters,
                    maxSpanBetweenSupportsMeters: data.max_span_between_supports_meters,
                },
            });
        } else {
            this.resetResults();
        }
    }

    render() {

        const {
            maxSpanBetweenSupportsWaterServiceB319Feet,
            maxSpanBetweenSupportsWaterServiceB319Meters,
            maxSpanBetweenSupportsWaterServiceNFPAFeet,
            maxSpanBetweenSupportsWaterServiceNFPAMeters,
            maxSpanBetweenSupportsGasServiceB319Feet,
            maxSpanBetweenSupportsGasServiceB319Meters,
            maxSpanBetweenSupportsMeters
        } = this.state.results;

        const standardUSResults = (this.state.selectedStandard.value === 'CTS US Standard' || this.state.selectedStandard.value === '') ? (
            <div>
                <Row>
                    <Col xs={6}>
                        <Label>{this.props.strings.I_600_SECTION_5_WATER_SERVICE_B_31_9} {(this.state.unit === true ? '(FEET)' : '(METERS)')}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={ (this.state.unit === true ? maxSpanBetweenSupportsWaterServiceB319Feet : maxSpanBetweenSupportsWaterServiceB319Meters ) } 
                            readOnly="readonly" 
                        />
                    </Col>
                    <Col xs={6}>
                        <Label>{this.props.strings.I_600_SECTION_5_WATER_SERVICE_NFPA} {(this.state.unit === true ? '(FEET)' : '(METERS)')}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={ (this.state.unit === true ? maxSpanBetweenSupportsWaterServiceNFPAFeet : maxSpanBetweenSupportsWaterServiceNFPAMeters) } 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.I_600_SECTION_5_GAS_AIR_SERVICE_B_31_9} {(this.state.unit === true ? '(FEET)' : '(METERS)')}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={ (this.state.unit === true ? maxSpanBetweenSupportsGasServiceB319Feet : maxSpanBetweenSupportsGasServiceB319Meters) } 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_600_SECTION_5_INFO)}
                        </Subtext>
                    </Col>
                </Row>

            </div>
        ) : '';

        const standardAUSEUResults = (this.state.selectedStandard.value === 'Australian Standard - AS 1432' || this.state.selectedStandard.value === 'European Standard - EN 1057' || this.state.selectedStandard.value === '') ? (
            <Row>
                <Col xs={12}>
                    <Label>{this.props.strings.METERS}</Label>
                    <Input 
                        type="text" 
                        placeholder="&mdash;" 
                        value={maxSpanBetweenSupportsMeters} 
                        readOnly="readonly" 
                    />
                </Col>
            </Row>
        ) : '';

        const style641InfoEU = (this.state.selectedHelpfulInformationStyle !== null && this.state.selectedHelpfulInformationStyle.value === 'Style 641' && this.state.selectedHelpfulInformationStandard !== null && this.state.selectedHelpfulInformationStandard.value === 'European Standard') ? (
            <Row className="extra-padding">
                <Col xs={12}>
                    {ReactHtmlParser(this.props.strings.I_600_SECTION_5_STYLE_641_INFO)}
                </Col>
            </Row>
        ) : '';

        const style641Info = (this.state.selectedHelpfulInformationStyle !== null && this.state.selectedHelpfulInformationStyle.value === 'Style 641') ? (
            <Row className="extra-padding">
                <Col xs={12}>
                    <SectionCaution>
                        <h2>{this.props.strings.NOTE}</h2>
                        <div>{ReactHtmlParser(this.props.strings.I_600_SECTION_5_STYLE_641_NOTE)}</div>
                    </SectionCaution>
                </Col>
            </Row>
        ) : '';
        
       
        const helpfulInfoResults = (this.state.helpfulInformationResults.length) ? (this.state.helpfulInformationResults.map( (item, index) => { return item; } )) : [];

        return (
            <Grid>
                
                <Row className="extra-padding">
                    <Col xs={12}>
                        <Label>{this.props.strings.MAKE_A_SELECTION_BELOW}</Label>
                        <Select
                            options={this.state.tableOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_CHOICE}
                            className="input-filter"
                            onChange={this.setTableType}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <div className={this.state.selectedTableType.value === 'support_spacing' ? '' : 'hide'}>

                    <Row>
                        <Col xs={12}>
                            <SectionIntro>
                                {ReactHtmlParser(this.props.strings.I_600_SECTION_5_HEADER)}
                            </SectionIntro>
                        </Col>
                    </Row>
                    
                    <Row className="extra-padding">
                        <Col xs={12}>
                            <Label>Standard</Label>
                            <Select
                                options={this.state.standardOptions}
                                getOptionValue={option => option.value}
                                placeholder={this.props.strings.SELECT_A_STANDARD}
                                className="input-filter"
                                onChange={this.setStandard}
                                // isClearable={true}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>
                    </Row>  

                    <Row className={this.state.selectedStandard.value === 'CTS US Standard' ? "" : "hide"}>
                        <Col xs={6}>
                            <Label>Units</Label>
                        </Col>
                        <Col xs={6} className="toggle-container">
                            <label>
                                <Toggle
                                    defaultChecked={this.state.unit}
                                    className="victaulic-toggle"
                                    icons={false}
                                    onChange={this.setUnit} />
                                <span className="victaulic-toggle-label">
                                    {(this.state.unit === true ? this.props.strings.IMPERIAL : this.props.strings.METRIC)}
                                </span>
                            </label>
                        </Col>
                    </Row> 

                    <Row>
                        <Col xs={12}>
                            <Label>Nominal Size</Label>
                            <Select
                                options={this.state.nominalSizeOptions}
                                getOptionValue={option => option.value}
                                placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                                className="input-filter"
                                onChange={this.setNominalSize}
                                // isDisabled={this.state.nominalSelectDisabled ? true : null}
                                // isClearable={true}
                                value={this.state.selectedNominalSize}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>
                    </Row>   

                    <Row>
                        <Col xs={12}>
                            <SectionDivider />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <CategoryHeader>
                            {this.props.strings.I_600_SECTION_5_SUGGESTED_MAX_SPAN}
                            </CategoryHeader>
                        </Col>
                    </Row>
                    
                    <Row className="extra-padding"></Row>
                    
                    {standardUSResults}

                    {standardAUSEUResults}

                    <Row className="extra-padding">
                        <Col xs={12}>
                            <SectionCaution>
                                <h2>{this.props.strings.NOTICE}</h2>
                                <div>{ReactHtmlParser(this.props.strings.I_600_SECTION_5_NOTICE)}</div>
                            </SectionCaution>
                        </Col>
                    </Row>

                </div>

                <div className={this.state.selectedTableType.value === 'helpful_information' ? '' : 'hide'}>
                    
                    <Row>
                        <Col xs={12}>
                            <Label>{this.props.strings.STYLE}</Label>
                            <Select
                                options={this.state.helpfulInformationStyleOptions}
                                getOptionValue={option => option.value}
                                placeholder={this.props.strings.SELECT_A_STYLE}
                                className="input-filter"
                                onChange={this.setHelpfulInformationStyle}
                                value={this.state.selectedHelpfulInformationStyle}
                                // isClearable={true}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>
                    </Row>  
                    
                    <Row className={(this.state.selectedHelpfulInformationStyle !== null && this.state.selectedHelpfulInformationStyle.value === 'Style 622') ? 'hide' : ''}>
                        <Col xs={12}>
                            <Label>Standard</Label>
                            <Select
                                options={this.state.helpfulInformationStandardOptions}
                                getOptionValue={option => option.value}
                                placeholder={this.props.strings.SELECT_A_STANDARD}
                                className="input-filter"
                                onChange={this.setHelpfulInformationStandard}
                                value={this.state.selectedHelpfulInformationStandard}
                                isDisabled={this.state.helpfulInformationStandardDisabled ? true : null}
                                // isClearable={true}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>
                    </Row>  

                    <Row className={(this.state.selectedHelpfulInformationStyle !== null && this.state.selectedHelpfulInformationStyle.value === 'Style 622') ? 'hide' : ''}>
                        <Col xs={12}>
                            <Label>{this.props.strings.NOMINAL_SIZE}</Label>
                            <Select
                                options={this.state.helpfulInformationNominalSizeOptions}
                                getOptionValue={option => option.value}
                                placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                                className="input-filter"
                                onChange={this.setHelpfulInformationNominalSize}
                                value={this.state.selectedHelpfulInformationNominalSize}
                                isDisabled={this.state.helpfulInformationNominalSizeDisabled ? true : null}
                                // isClearable={true}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>
                    </Row>  

                    <Row>
                        <Col xs={12}>
                            <CategoryHeader>{this.props.strings.HELPFUL_INFORMATION}</CategoryHeader>
                        </Col>
                    </Row>

                        {
                            helpfulInfoResults.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <Row className="extra-padding" >


                                            <Col xs={12} className={item.run_size ? '' : 'hide' }>
                                                <Label>{this.props.strings.RUN_SIZE}</Label>
                                                <Input 
                                                    type="text" placeholder="&mdash;" 
                                                    value={ item.run_size } 
                                                    readOnly="readonly" 
                                                />
                                            </Col>

                                            <Col xs={6} className={item.nut_size ? '' : 'hide' }>
                                                <Label>{this.props.strings.NUT_SIZE}</Label>
                                                <Input 
                                                    type="text" placeholder="&mdash;" 
                                                    value={ item.nut_size } 
                                                    readOnly="readonly" 
                                                />
                                            </Col>

                                            <Col xs={6} className={item.socket_size ? '' : 'hide' }>
                                                <Label>{this.props.strings.SOCKET_SIZE}</Label>
                                                <Input 
                                                    type="text" placeholder="&mdash;" 
                                                    value={ item.socket_size } 
                                                    readOnly="readonly" 
                                                />
                                            </Col>

                                            <Col xs={6} className={item.bolts_required ? '' : 'hide' }>
                                                <Label>{this.props.strings.I_600_SECTION_5_STYLE_641_BOLTS_REQUIRED}</Label>
                                                <Input 
                                                    type="text" placeholder="&mdash;" 
                                                    value={ item.bolts_required } 
                                                    readOnly="readonly" 
                                                />
                                            </Col>

                                            <Col xs={6} className={item.bolt_size ? '' : 'hide' }>
                                                <Label>{this.props.strings.BOLT_SIZE}</Label>
                                                <Input 
                                                    type="text" placeholder="&mdash;" 
                                                    value={ item.bolt_size } 
                                                    readOnly="readonly" 
                                                />
                                            </Col>

                                            <Col xs={6} className={item.required_mating_face_sealing_surface_a_max ? '' : 'hide' }>
                                                <Label>{this.props.strings.REQUIRED_MATING_FACE_SEALING_SURFACE} {this.props.strings.A_MAXIMUM}</Label>
                                                <Input 
                                                    type="text" placeholder="&mdash;" 
                                                    value={ item.required_mating_face_sealing_surface_a_max } 
                                                    readOnly="readonly" 
                                                />
                                            </Col>

                                            <Col xs={6} className={item.required_mating_face_sealing_surface_b_max ? '' : 'hide' }>
                                                <Label>{this.props.strings.REQUIRED_MATING_FACE_SEALING_SURFACE} {this.props.strings.B_MINIMUM}</Label>
                                                <Input 
                                                    type="text" placeholder="&mdash;" 
                                                    value={ item.required_mating_face_sealing_surface_b_max } 
                                                    readOnly="readonly" 
                                                />
                                            </Col>

                                        </Row>
                                        <SectionDivider />
                                    </div>
                                    
                                )
                            })
                        }
                    {style641InfoEU}
                    {style641Info}
                    
                </div>

            </Grid>
        );
    }
}

const SectionIntro = styled.div`
        border-bottom: 0.5px solid #efefef;
        padding-bottom: 15px;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    SectionDivider = styled.div`
        border-top: 1px solid #efefef;
        border-collapse: collapse;
        height: 1px;
        margin: 10px 0;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 16px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;

        &.full {
            width: 100%;
        }
    `,  
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        margin: 10px 0 0 0;
        text-transform: uppercase;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `,
    Subtext = styled.div`
        font-size: 15px;
    `,
    SectionCaution = styled.div`
    border: 1px solid #333333;
    padding: 0;
    margin: 20px 0 0 0;

    h2 {
        font-family: Rajdhani, sans-serif;
        font-size: 20px;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        margin:0 0 20px 0;

        i {
            font-size: 14px;
            vertical-align: middle;
            position:relative;
            top:-1px;
        }
    }

    div {
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        text-transform: none;
        padding: 0 10px;
        position: relative;
        top: -10px;
        margin: 0 0 5px 0;
    }

    .icon-round-bg {
        background: #ff7f00;
        color: #ffffff;
    }`;

export default CopperSupportSpacing;