import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class CpvcHangerSupportSpacing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=cpvc_hanger_support_spacing',
            scheduleOptions: [],
            nominalDiameterOptions: [],
            outsideDiameterOptions: [],
            selectedSchedule: {},
            selectedNominalDiameter: null,
            selectedOutsideDiameter: null,
            nominalSelectDisabled: true,
            outsideSelectDisabled: true,
            results: {
                operating_temp_60_16: {in: '', mm: ''},
                operating_temp_80_27: {in: '', mm: ''},
                operating_temp_100_38: {in: '', mm: ''},
                operating_temp_120_49: {in: '', mm: ''},
                operating_temp_140_60: {in: '', mm: ''},
                operating_temp_180_82: {in: '', mm: ''},
            },
        };
    }

    // Set Schedule Data
    setScheduleOptions = () => {
        let schedules = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_support_schedule'));
        data.map((value, key) => {
            schedules.push({ 'value': value.schedule, 'label': value.schedule });
            return true;
        });
        this.setState({ 
            scheduleOptions: schedules 
        });
    }

    // Get Schedule Data
    getScheduleData = async () => {
        axios.get(this.state.getSectionDataUrl + '&select=schedule&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hanger_support_schedule', JSON.stringify(response.data.data));
                this.setScheduleOptions();
            })
            .catch(console.log);
    };

    // Set Nominal Diameter Data 
    setNominalDiameterOptions = () => {
        let nominalDiameters = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_support_nominal_diameter'));
        data.map((value, key) => {
            nominalDiameters.push({ 'value': value.nominal_diameter_inches, 'label': value.nominal_diameter_inches + ' / ' + value.nominal_diameter_dn });
            return true;
        });
        this.setState({ nominalDiameterOptions: nominalDiameters });
    }

    // Get Nominal Diameter Data
    getNominalDiameter = async () => {
        axios.get(this.state.getSectionDataUrl + '&select=nominal_diameter_inches|nominal_diameter_dn&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hanger_support_nominal_diameter', JSON.stringify(response.data.data));
                this.setNominalDiameterOptions();
            })
            .catch(console.log);
    };

    // Set Outside Diameter Data
    setOutsideDiameterOptions = () => {
        let outsideDiameters = []; 
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_support_outside_diameter'));
        data.map((value, key) => {
            outsideDiameters.push({ 'value': value.actual_outside_diameter_inches, 'label': value.actual_outside_diameter_inches + ' / ' + value.actual_outside_diameter_mm });
            return true;
        });
        this.setState({ outsideDiameterOptions: outsideDiameters });
    }

    // Get Outside Diameter Data
    getOutsideDiameter = async () => {
        axios.get(this.state.getSectionDataUrl + '&select=actual_outside_diameter_inches|actual_outside_diameter_mm&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hanger_support_outside_diameter', JSON.stringify(response.data.data));
                this.setOutsideDiameterOptions();
            })
            .catch(console.log);
    };

    // Get complete data set from API
    getData = async () => {
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hanger_support_spacing', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    // Get All Data
    getAllData = async () => {
        await axios.all([this.getScheduleData(),this.getNominalDiameter(),this.getOutsideDiameter(),this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                });

            }))
            .finally(() => {
                this._isMounted = true;
            });
    };


    // ComponentDidMount - Run getAllData()
    componentDidMount() {
        this.getAllData();
    }

    // Clear Results 
    clearResults = () => {
        this.setState({
            results: {
                operating_temp_60_16: {in: '', mm: ''},
                operating_temp_80_27: {in: '', mm: ''},
                operating_temp_100_38: {in: '', mm: ''},
                operating_temp_120_49: {in: '', mm: ''},
                operating_temp_140_60: {in: '', mm: ''},
                operating_temp_180_82: {in: '', mm: ''},
            },
        });
    }

    // Set Schedule
    setSchedule = s => {
        if( s !== null ) {
            this.setState({ 
                selectedSchedule: { 'value': s.value, 'label': s.label } 
            } , ()=> {
                let data = _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_support_spacing')),
                    {
                        'schedule': this.state.selectedSchedule.value, 
                    }
                );
                data = data[0];

                // Filter Diameter Options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_support_spacing')),
                        {
                            'schedule': this.state.selectedSchedule.value, 
                        }
                    ), (values, index) => {
                        options.push(values)
                    }
                );
                let nominalDiameterOptions = [];
                let outsideDiameterOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.nominal_diameter_inches) && item.nominal_diameter_inches !== '' && item.nominal_diameter_inches !==null ) {
                        map.set(item.nominal_diameter_inches, true);
                        nominalDiameterOptions.push({ 'value': item.nominal_diameter_inches, 'label': item.nominal_diameter_inches + ' / ' + item.nominal_diameter_dn })
                    }
                }
                for (const item of options) {
                    if (!map.has(item.actual_outside_diameter_inches) && item.actual_outside_diameter_inches !== '' && item.actual_outside_diameter_inches !==null ) {
                        map.set(item.actual_outside_diameter_inches, true);
                        outsideDiameterOptions.push({ 'value': item.actual_outside_diameter_inches, 'label': item.actual_outside_diameter_inches + ' / ' + item.actual_outside_diameter_mm })
                    }
                }
                this.setResults(data, nominalDiameterOptions, outsideDiameterOptions);
                this.setState({
                    nominalSelectDisabled: null,
                    outsideSelectDisabled: true,
                    selectedNominalDiameter: null,
                    selectedOutsideDiameter: null,
                });
                this.clearResults();
            });
        } else {
            this.setScheduleOptions();
            this.setNominalDiameterOptions();
            this.setOutsideDiameterOptions();
            this.setState({
                selectedSchedule: {},
                selectedNominalDiameter: null,
                selectedOutsideDiameter: null, 
                results: {
                    operating_temp_60_16: {in: '', mm: ''},
                    operating_temp_80_27: {in: '', mm: ''},
                    operating_temp_100_38: {in: '', mm: ''},
                    operating_temp_120_49: {in: '', mm: ''},
                    operating_temp_140_60: {in: '', mm: ''},
                    operating_temp_180_82: {in: '', mm: ''},
                }
            });
        }
    }

    // Set Nominal Diameter
    setNominalDiameter = nd => {
        if( nd !== null ) {
            this.setState({ selectedNominalDiameter: { 'value': nd.value, 'label': nd.label } }, ()=> {
                let data = _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_support_spacing')),
                    {
                        'schedule': this.state.selectedSchedule.value, 
                        'nominal_diameter_inches': this.state.selectedNominalDiameter.value,
                    }
                );
                data = data[0];

                // Filter Diameter Options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_support_spacing')),
                        {
                            'schedule': this.state.selectedSchedule.value, 
                            'nominal_diameter_inches': this.state.selectedNominalDiameter.value,
                        }
                    ), (values, index) => {
                        options.push(values)
                    }
                );

                let outsideDiameterOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.actual_outside_diameter_inches) && item.actual_outside_diameter_inches !== '' && item.actual_outside_diameter_inches !==null ) {
                        map.set(item.actual_outside_diameter_inches, true);
                        outsideDiameterOptions.push({ 'value': item.actual_outside_diameter_inches, 'label': item.actual_outside_diameter_inches + ' / ' + item.actual_outside_diameter_mm })
                    }
                }
                this.setResults(data, this.state.nominalDiameterOptions, outsideDiameterOptions);
                this.setState({
                    outsideSelectDisabled: null,
                    selectedOutsideDiameter: null,
                });
                this.clearResults();
            });
        } else {
            this.setScheduleOptions();
            this.setNominalDiameterOptions();
            this.setOutsideDiameterOptions();
            this.setState({
                selectedSchedule: '',
                selectedNominalDiameter: '',
                selectedOutsideDiameter: '',
                results: {
                    operating_temp_60_16: {in: '', mm: ''},
                    operating_temp_80_27: {in: '', mm: ''},
                    operating_temp_100_38: {in: '', mm: ''},
                    operating_temp_120_49: {in: '', mm: ''},
                    operating_temp_140_60: {in: '', mm: ''},
                    operating_temp_180_82: {in: '', mm: ''},
                }
            });
        }
    }

    // Set Outside Diameter
    setOutsideDiameter = od => {
        if( od !== null) {
            this.setState({ selectedOutsideDiameter: { 'value': od.value, 'label': od.label } }, ()=> {
                let data = _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_support_spacing')),
                    {
                        'schedule': this.state.selectedSchedule.value, 
                        'nominal_diameter_inches': this.state.selectedNominalDiameter.value,
                        'actual_outside_diameter_inches': this.state.selectedOutsideDiameter.value
                    }                                        
                );
                data = data[0]; 
    
                // Filter Diameter Options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_support_spacing')),
                        {
                            'schedule': this.state.selectedSchedule.value, 
                            'nominal_diameter_inches': this.state.selectedNominalDiameter.value,
                            'actual_outside_diameter_inches': this.state.selectedOutsideDiameter.value
                        }
                    ), (values, index) => {
                        options.push(values)
                    }
                );
                let nominalDiameterOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.nominal_diameter_inches) && item.nominal_diameter_inches !== '' && item.nominal_diameter_inches !==null ) {
                        map.set(item.nominal_diameter_inches, true);
                        nominalDiameterOptions.push({ 'value': item.nominal_diameter_inches, 'label': item.nominal_diameter_inches + ' / ' + item.nominal_diameter_dn })
                    }
                }
                this.setResults(data, nominalDiameterOptions, this.state.outsideDiameterOptions);
                this.setNominalDiameterOptions();

            });
        } else {
            this.setScheduleOptions();
            this.setNominalDiameterOptions();
            this.setOutsideDiameterOptions();
            this.setState({
                selectedSchedule: '',
                selectedNominalDiameter: '',
                selectedOutsideDiameter: '',
                results: {
                    operating_temp_60_16: {in: '', mm: ''},
                    operating_temp_80_27: {in: '', mm: ''},
                    operating_temp_100_38: {in: '', mm: ''},
                    operating_temp_120_49: {in: '', mm: ''},
                    operating_temp_140_60: {in: '', mm: ''},
                    operating_temp_180_82: {in: '', mm: ''},
                }
            });
        }

    }


    setResults = (data, nominalDiameterOptions, outsideDiameterOptions) => {
        try {
            if (Object.entries(this.state.selectedSchedule).length > 0 &&
                this.state.selectedNominalDiameter !== null &&
                this.state.selectedOutsideDiameter !== null) {
                this.setState({ 
                    selectedSchedule: { 'value': data.schedule, 'label': data.schedule },
                    selectedNominalDiameter: { 'value': data.nominal_diameter_inches, 'label': data.nominal_diameter_inches + ' / ' + data.nominal_diameter_dn },
                    selectedOutsideDiameter: { 'value': data.actual_outside_diameter_inches, 'label': data.actual_outside_diameter_inches + ' / ' + data.actual_outside_diameter_mm },
                    results: {
                        operating_temp_60_16: {in: data.operating_temp_60_16_spacing_inches, mm: data.operating_temp_60_16_spacing_mm},
                        operating_temp_80_27: {in: data.operating_temp_80_27_spacing_inches, mm: data.operating_temp_80_27_spacing_mm},
                        operating_temp_100_38: {in: data.operating_temp_100_38_spacing_inches, mm: data.operating_temp_100_38_spacing_mm},
                        operating_temp_120_49: {in: data.operating_temp_120_49_spacing_inches, mm: data.operating_temp_120_49_spacing_mm},
                        operating_temp_140_60: {in: data.operating_temp_140_60_spacing_inches, mm: data.operating_temp_140_60_spacing_mm},
                        operating_temp_180_82: {in: data.operating_temp_180_82_spacing_inches, mm: data.operating_temp_180_82_spacing_mm},
                    },
                });
            }
            this.setState({
                nominalDiameterOptions: nominalDiameterOptions,
                outsideDiameterOptions: outsideDiameterOptions,
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    };


    render() {
        //Destructuring this.state.results
        const {
            operating_temp_60_16,
            operating_temp_80_27,
            operating_temp_100_38,
            operating_temp_120_49,
            operating_temp_140_60,
            operating_temp_180_82, 
        } = this.state.results;

        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionIntro>
                            {ReactHtmlParser(this.props.strings.I_350_SECTION_5_HEADER)}
                        </SectionIntro>
                    </Col>
                </Row>

                <Row className="extra-padding">
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.SCHEDULE}</Label>
                        <Select
                            options={this.state.scheduleOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_SCHEDULE}
                            className="input-filter"
                            onChange={this.setSchedule}
                            // isClearable={true}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>   

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.NOMINAL_DIAMETER_IN_DN}</Label>
                        <Select
                            options={this.state.nominalDiameterOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_NOMINAL_DIAMETER}
                            className="input-filter" 
                            onChange={this.setNominalDiameter}
                            isDisabled={this.state.nominalSelectDisabled ? true : null}
                            value={this.state.selectedNominalDiameter}
                            // isClearable={true}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>    
                
                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.OUTSIDE_DIAMETER_IN_MM}</Label>
                        <Select
                            options={this.state.outsideDiameterOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_OUTSIDE_DIAMETER}
                            className="input-filter" 
                            onChange={this.setOutsideDiameter}
                            isDisabled={this.state.outsideSelectDisabled ? true : null}
                            value={this.state.selectedOutsideDiameter}
                            // isClearable={true}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>  

                <Row>
                    <Col xs={12}>
                        <SectionDivider />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>{this.props.strings.OPERATING_TEMPERATURES}</CategoryHeader>
                    </Col>
                </Row>
                
                <Row className="extra-padding">
                    <Col xs={6}>
                        <Label>{ReactHtmlParser(this.props.strings.TEMP_SPACING_60F_16C_FT_M)}</Label>
                        <Input 
                            type="text" placeholder="&mdash;" 
                            value={ (operating_temp_60_16.in  && operating_temp_60_16.mm) ? (operating_temp_60_16.in + ' / ' + operating_temp_60_16.mm) : '' } 
                            readOnly="readonly" 
                        />
                    </Col>
                    <Col xs={6}>
                        <Label>{ReactHtmlParser(this.props.strings.TEMP_SPACING_80F_27C_FT_M)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={ (operating_temp_80_27.in &&  operating_temp_80_27.mm) ? (operating_temp_80_27.in + ' / ' + operating_temp_80_27.mm) : '' } 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>         

                 <Row className="extra-padding">
                    <Col xs={6}>
                        <Label>{ReactHtmlParser(this.props.strings.TEMP_SPACING_100F_38C_FT_M)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={ (operating_temp_100_38.in && operating_temp_100_38.mm) ? (operating_temp_100_38.in + ' / ' + operating_temp_100_38.mm) : '' } 
                            readOnly="readonly" 
                        />
                    </Col>
                    <Col xs={6}>
                        <Label>{ReactHtmlParser(this.props.strings.TEMP_SPACING_120F_49C_FT_M)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={ (operating_temp_120_49.in && operating_temp_120_49.mm) ? (operating_temp_120_49.in + ' / ' + operating_temp_120_49.mm) : '' } 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>         

                <Row className="extra-padding">
                    <Col xs={6}>
                        <Label>{ReactHtmlParser(this.props.strings.TEMP_SPACING_140F_60C_FT_M)}</Label>
                        <Input 
                            type="text" placeholder="&mdash;" 
                            value={ (operating_temp_140_60.in &&  operating_temp_140_60.mm) ? (operating_temp_140_60.in + ' / ' + operating_temp_140_60.mm) : '' } 
                            readOnly="readonly" 
                        />
                    </Col>
                    <Col xs={6}>
                        <Label>{ReactHtmlParser(this.props.strings.TEMP_SPACING_180F_82C_FT_M)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={ (operating_temp_180_82.in && operating_temp_180_82.mm) ? (operating_temp_180_82.in + ' / ' + operating_temp_180_82.mm) : '' } 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>        
                
            </Grid>
        );
    }
}

const SectionIntro = styled.div`
        border-bottom: 0.5px solid #efefef;
        padding-bottom: 15px;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 0;
        margin: 0 0 10px 0;
    `,
    SectionDivider = styled.div`
        border-top: 1px solid #efefef;
        border-collapse: collapse;
        height: 1px;
        margin: 10px 0;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 18px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;

        &.full {
            width: 100%;
        }
    `,  
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        margin: 10px 0 0 0;
        text-transform: uppercase;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default CpvcHangerSupportSpacing;