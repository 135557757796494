import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class GrooveSpecification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=groove_specifications',
            getSectionDataHeaderUrl: process.env.REACT_APP_API_URL + 'get/section?table=groove_types',
            grooveTypeOptions: [],
            nominalSizeOptions: [],
            outsideDiameterOptions: [],
            selectedGrooveType: {},
            selectedNominalSize: {},
            selectedOutsideDiameter: {},
            results: {
                outsideDiameter: { max: '', min: '' },
                gasketSeatA: { basic: '', max: '', min: '' },
                grooveWidthB: { basic: '', max: '', min: '' },
                grooveDiameterC: { basic: '', max: '', min: '' },
                grooveDepthDRef: '',
                minAllowWallThicknessT: '',
                maxAllowFlareDia: '',
                minAllowWallThicknessExtraStrongCarbonSteel: '',
                minAllowWallThicknessStdWallSteel: '',
                minAllowWallThicknessLightWallCarbonSteel: '',
                minAllowWallThicknessLightWallStainlessSteel: '',
            },
        };
    }

    getGrooveTypes = async () => {
        // Retrieve the groove_types for dropdown from the API
        axios.get(this.state.getSectionDataHeaderUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_groove_types', JSON.stringify(response.data.data));
                let grooveTypes = [];
                response.data.data.map((value, key) => {
                    grooveTypes.push({ 'value': value.id, 'label': value.groove_type });
                    return true;
                });
                this.setState({
                    grooveTypeOptions: grooveTypes
                })
            })
            .catch(console.log);
    };

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_groove_specifications', JSON.stringify(response.data.data));
                let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_specifications'));
                let nominalSizes = [];
                let outsideDiameters = [];
                let map = new Map();
                for (const item of data) {
                    if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                        map.set(item.nominal_size, true);    // set any value to Map
                        nominalSizes.push({ value: item.nominal_size, label: item.nominal_size });
                    }
                }
                for (const item of data) {
                    if (!map.has(item.outside_diameter) && item.outside_diameter !== '' && item.outside_diameter !== null) {
                        map.set(item.outside_diameter, true);    // set any value to Map
                        outsideDiameters.push({ value: item.outside_diameter, label: item.outside_diameter });
                    }
                }
                outsideDiameters.push({ value: '', label: 'N/A' });
                this.setState({
                    nominalSizeOptions: nominalSizes,
                    outsideDiameterOptions: outsideDiameters,
                })
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.getGrooveTypes(), this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    setGrooveType = n => {
        this.setState({
            selectedGrooveType: { 'value': n.value, 'label': n.label },
        }, () => {
            let data = _.find(
                JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_specifications')),
                ['groove_type_id', this.state.selectedGrooveType.value]
            );
            // filter the Size options
            let options = [];
            _.forEach(
                _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_specifications')),
                    ['groove_type_id', this.state.selectedGrooveType.value]
                ), (values, index) => {
                    options.push(values)
                });
            let nominalSizeOptions = [];
            let outsideDiameterOptions = [];
            let map = new Map();
            for (const item of options) {
                if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                    map.set(item.nominal_size, true);    // set any value to Map
                    nominalSizeOptions.push({ value: item.nominal_size, label: item.nominal_size });
                }
            }
            for (const item of options) {
                if (!map.has(item.outside_diameter) && item.outside_diameter !== '' && item.outside_diameter !== null) {
                    map.set(item.outside_diameter, true);    // set any value to Map
                    outsideDiameterOptions.push({ value: item.outside_diameter, label: item.outside_diameter });
                }
            }
            if (this.state.selectedGrooveType.value >= 6) {
                outsideDiameterOptions.push({ value: '', label: 'N/A' });
            }
            this.setResults(data, nominalSizeOptions, outsideDiameterOptions);
        });
    };

    setNominalSize = n => {
        this.setState({
            selectedNominalSize: { 'value': n.value, 'label': n.label },
        }, () => {
            let data = _.find(
                JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_specifications')),
                ['nominal_size', this.state.selectedNominalSize.value]
            );
            // filter the Size options
            let options = [];
            _.forEach(
                _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_specifications')),
                    { 'groove_type_id': this.state.selectedGrooveType.value, 'nominal_size': this.state.selectedNominalSize.value }
                ), (values, index) => {
                    options.push(values)
                });
            let outsideDiameterOptions = [];
            let map = new Map();
            for (const item of options) {
                if (!map.has(item.outside_diameter) && item.outside_diameter !== '' && item.outside_diameter !== null) {
                    map.set(item.outside_diameter, true);    // set any value to Map
                    outsideDiameterOptions.push({ value: item.outside_diameter, label: item.outside_diameter });
                }
            }
            if (this.state.selectedGrooveType.value >= 6) {
                outsideDiameterOptions.push({ value: '', label: 'N/A' });
            }
            this.setResults(data, this.state.nominalSizeOptions, outsideDiameterOptions);
        });
    };

    setOutsideDiameter = o => {
        this.setState({
            selectedOutsideDiameter: { 'value': o.value, 'label': o.label }
        }, () => {
            let data = _.find(
                JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_specifications')),
                ['outside_diameter', this.state.selectedOutsideDiameter.value]
            );
            // filter the Size options
            let options = [];
            _.forEach(
                _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_specifications')),
                    { 'groove_type_id': this.state.selectedGrooveType.value, 'outside_diameter': this.state.selectedOutsideDiameter.value }
                ), (values, index) => {
                    options.push(values)
                });
            let nominalSizeOptions = [];
            let map = new Map();
            for (const item of options) {
                if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                    map.set(item.nominal_size, true);    // set any value to Map
                    nominalSizeOptions.push({ value: item.nominal_size, label: item.nominal_size });
                }
            }

            this.setResults(data, nominalSizeOptions, this.state.outsideDiameterOptions);
        });
    };

    setResults = (data, nominalSizeOptions, outsideDiameterOptions) => {
        try {
            if (Object.entries(this.state.selectedGrooveType).length > 0 &&
                Object.entries(this.state.selectedNominalSize).length > 0 &&
                Object.entries(this.state.selectedOutsideDiameter).length > 0) {
                this.setState({ // update the results, ony if all three options are set
                    selectedGrooveType: { 'value': data.groove_type_id, 'label': '' },
                    selectedNominalSize: { 'value': data.nominal_size, 'label': data.nominal_size },
                    selectedOutsideDiameter: { 'value': data.outside_diameter, 'label': data.outside_diameter },
                    results: {
                        outsideDiameter: { max: data.outside_diameter_max, min: data.outside_diameter_min },
                        gasketSeatA: { basic: data.gasket_seat_basic, max: data.gasket_seat_max, min: data.gasket_seat_min },
                        grooveWidthB: { basic: data.groove_width_basic, max: data.groove_width_max, min: data.groove_width_min },
                        grooveDiameterC: { max: data.groove_diameter_max, min: data.groove_diameter_min },
                        grooveDepthDRef: data.groove_depth,
                        minAllowWallThicknessT: data.min_allow_wall_thickness,
                        maxAllowFlareDia: data.max_allow_flare_diameter,
                        minAllowWallThicknessExtraStrongCarbonSteel: data.min_allow_wall_thickness_extra_strong_carbon_steel,
                        minAllowWallThicknessStdWallSteel: data.min_allow_wall_thickness_std_wall_steel,
                        minAllowWallThicknessLightWallCarbonSteel: data.min_allow_wall_thickness_light_wall_carbon_steel,
                        minAllowWallThicknessLightWallStainlessSteel: data.min_allow_wall_thickness_light_wall_stainless_steel,
                    },
                });
            }
            this.setState({ // update the Size options, always
                nominalSizeOptions: nominalSizeOptions,
                outsideDiameterOptions: outsideDiameterOptions,
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    };

    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GROOVE_TYPE}</Label>
                        <Select
                            options={this.state.grooveTypeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_GROOVE_TYPE}
                            className="input-filter"
                            onChange={this.setGrooveType}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.SIZE}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.NOMINAL_SIZE} {this.props.strings.INCHES}/{this.props.strings.MM}</Label>
                        <Select
                            options={this.state.nominalSizeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                            className="input-filter"
                            onChange={this.setNominalSize}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.ACTUAL_PIPE_OUTSIDE_DIAMETER} {this.props.strings.INCHES}/{this.props.strings.MM}</Label>
                        <Select
                            options={this.state.outsideDiameterOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_DIAMETER}
                            className="input-filter"
                            onChange={this.setOutsideDiameter}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.GROOVE_DIMENSIONS} &mdash; {this.props.strings.INCHES}/{this.props.strings.MM}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.PIPE_OUTSIDE_DIAMETER}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Label className="sublabel">{this.props.strings.MAX}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.outsideDiameter.max || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={6}>
                        <Label className="sublabel">{this.props.strings.MIN}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.outsideDiameter.min || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GASKET_SEAT_A}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.BASIC}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.gasketSeatA.basic || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MAX}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.gasketSeatA.max || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MIN}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.gasketSeatA.min || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GROOVE_WIDTH_B}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.BASIC}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveWidthB.basic || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MAX}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveWidthB.max || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MIN}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveWidthB.min || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GROOVE_DIAMETER_C}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.BASIC}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveDiameterC.basic || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MAX}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveDiameterC.max || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{this.props.strings.MIN}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveDiameterC.min || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GROOVE_DEPTH_D}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.grooveDepthDRef || ''} className="full" readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.MIN_ALLOW_WALL_THICKNESS_T}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.minAllowWallThicknessT || ''} className="full" readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.MAX_ALLOW_FLARE_DIA}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.maxAllowFlareDia || ''} className="full" readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SectionDivider />
                    </Col>
                </Row>

                <Row className={this.state.results.minAllowWallThicknessExtraStrongCarbonSteel ? "show" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.MIN_ALLOW_WALL_THICKNESS}</Label><br />
                        <Label className="sublabel">({this.props.strings.EXTRA_STRONG_CARBON_STEEL})</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.minAllowWallThicknessExtraStrongCarbonSteel || ''} className="full" readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.minAllowWallThicknessStdWallSteel ? "show" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.MIN_ALLOW_WALL_THICKNESS}</Label><br />
                        <Label className="sublabel">({this.props.strings.STANDARD_WALL_STEEL})</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.minAllowWallThicknessStdWallSteel || ''} className="full" readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.minAllowWallThicknessLightWallCarbonSteel ? "show" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.MIN_ALLOW_WALL_THICKNESS}</Label><br />
                        <Label className="sublabel">({this.props.strings.LIGHT_WALL_CARBON_STEEL})</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.minAllowWallThicknessLightWallCarbonSteel || ''} className="full" readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.minAllowWallThicknessLightWallStainlessSteel ? "show" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.MIN_ALLOW_WALL_THICKNESS}</Label><br />
                        <Label className="sublabel">({this.props.strings.LIGHT_WALL_STAINLESS_STEEL})</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.minAllowWallThicknessLightWallStainlessSteel || ''} className="full" readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_100_SECTION_3_FOOTER)}
                        </Subtext>
                    </Col>
                </Row>

            </Grid>
        );
    }
};

const SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    SectionDivider = styled.div`
        border-top: 1px solid #efefef;
        border-collapse: collapse;
        height: 1px;
        margin: 10px 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default GrooveSpecification;