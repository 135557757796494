import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class HangerSupportSpacing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionHangerSpacingDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=hanger_spacing',
            getSectionSystemsDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=hanger_spacing_systems',
            getSectionPipeLengthsDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=hanger_spacing_pipe_lengths	',

            systemTypeOptions: [],
            nominalSizeOptions: [],
            outsideDiameterOptions: [],
            pipeLengthOptions: [],
            serviceTypeOptions: [],

            selectedSystemType: {},
            selectedNominalSize: {},
            selectedOutsideDiameter: {},
            selectedPipeLength: {},
            selectedServiceType: {},

            results: {
                suggestedMaxSpanBetweenSupports: '',
                waterPowerPipingCode: '',
                waterBuildingServicesPipingCode: '',
                waterFireSprinklerSystems: '',
                gasAirPowerPipingCode: '',
                gasAirBuildingServicesPipingCode: '',
                gasAirFireSprinklerSystems: '',
                pipeLength_1: '',
                pipeLength_2: '',
                pipeLength_3: '',
                pipeLength_4: '',
                pipeLength_5: '',
                pipeLength_6: '',
                pipeLength_7: '',
                pipeLength_8: '',
                pipeLength_9: '',
                pipeLength_10: '',
                wallThickness: '',
                schedule: '',
            },
            displayResults: false,
        };
    }

    getHangerSpacingData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionHangerSpacingDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hanger_spacing', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    getSystemsData = async () => {
        // Retrieve the systems data from the API
        axios.get(this.state.getSectionSystemsDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hanger_spacing_systems', JSON.stringify(response.data.data));
                let systemTypes = [];
                response.data.data.map((value, key) => {
                    systemTypes.push({ 'value': value.id, 'label': value.hanger_system });
                    return true;
                });
                this.setState({ systemTypeOptions: systemTypes, });
            })
            .catch(console.log);
    };

    getPipeLengthsData = async () => {
        // Retrieve the pipe_lengths data from the API
        axios.get(this.state.getSectionPipeLengthsDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hanger_spacing_pipe_lengths', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    initializeForm = () => {
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_spacing')),
            nominalSizes = [],
            outsideDiameters = [];
        let map = new Map();
        for (const item of data) {
            if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                map.set(item.nominal_size, true);    // set any value to Map
                nominalSizes.push({ value: item.nominal_size, label: item.nominal_size });
            }
        }
        for (const item of data) {
            if (!map.has(item.outside_diameter) && item.outside_diameter !== '' && item.outside_diameter !== null) {
                map.set(item.outside_diameter, true);    // set any value to Map
                outsideDiameters.push({ value: item.outside_diameter, label: item.outside_diameter });
            }
        }
        this.setState({
            nominalSizeOptions: nominalSizes,
            outsideDiameterOptions: outsideDiameters,
            selectedNominalSize: {},
            selectedOutsideDiameter: {},
        });
    }

    getAllData = async () => {
        await axios.all([this.getSystemsData(), this.getHangerSpacingData(), this.getPipeLengthsData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                });

            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
        if (this.state.loading === false) {
            this.initializeForm();
        }
    }

    getPipeLengthLabel = (id) => {
        let result = _.find(
            JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_spacing_pipe_lengths')),
            ['id', id]
        );
        if (result) {
            return result.pipe_length;
        } else {
            return '';
        }
    };

    setSystem = s => {
        if (s !== null) {
            this.setState({
                selectedSystemType: { 'value': s.value, 'label': s.label },
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_spacing')),
                    ['hanger_system_id', this.state.selectedSystemType.value]
                );
                // filter the Size options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_spacing')),
                        ['hanger_system_id', this.state.selectedSystemType.value]
                    ), (values, index) => {
                        options.push(values)
                    });
                let nominalSizeOptions = [],
                    outsideDiameterOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                        map.set(item.nominal_size, true);    // set any value to Map
                        nominalSizeOptions.push({ value: item.nominal_size, label: item.nominal_size });
                    }
                }
                for (const item of options) {
                    if (!map.has(item.outside_diameter) && item.outside_diameter !== '' && item.outside_diameter !== null) {
                        map.set(item.outside_diameter, true);    // set any value to Map
                        outsideDiameterOptions.push({ value: item.outside_diameter, label: item.outside_diameter });
                    }
                }
                this.setResults(data, nominalSizeOptions, outsideDiameterOptions);
            });
        } else {
            this.setState({
                results: {
                    suggestedMaxSpanBetweenSupports: '',
                    waterPowerPipingCode: '',
                    waterBuildingServicesPipingCode: '',
                    waterFireSprinklerSystems: '',
                    gasAirPowerPipingCode: '',
                    gasAirBuildingServicesPipingCode: '',
                    gasAirFireSprinklerSystems: '',
                    pipeLength_1: '',
                    pipeLength_2: '',
                    pipeLength_3: '',
                    pipeLength_4: '',
                    pipeLength_5: '',
                    pipeLength_6: '',
                    pipeLength_7: '',
                    pipeLength_8: '',
                    pipeLength_9: '',
                    pipeLength_10: '',
                    wallThickness: '',
                    schedule: '',
                },
                displayResults: false,
            });
        }
        this.initializeForm();
    };

    setNominalSize = n => {
        if (n !== null) {
            this.setState({
                selectedNominalSize: { 'value': n.value, 'label': n.label },
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_spacing')),
                    { 'hanger_system_id': this.state.selectedSystemType.value, 'nominal_size': this.state.selectedNominalSize.value }
                );
                // filter the Size options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_spacing')),
                        { 'hanger_system_id': this.state.selectedSystemType.value, 'nominal_size': this.state.selectedNominalSize.value }
                    ), (values, index) => {
                        options.push(values)
                    });
                let outsideDiameterOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.outside_diameter) && item.outside_diameter !== '' && item.outside_diameter !== null) {
                        map.set(item.outside_diameter, true);    // set any value to Map
                        outsideDiameterOptions.push({ value: item.outside_diameter, label: item.outside_diameter });
                    }
                }
                this.setResults(data, this.state.nominalSizeOptions, outsideDiameterOptions);
            });
        } else {
            this.initializeForm();
            this.setState({
                results: {
                    suggestedMaxSpanBetweenSupports: '',
                    waterPowerPipingCode: '',
                    waterBuildingServicesPipingCode: '',
                    waterFireSprinklerSystems: '',
                    gasAirPowerPipingCode: '',
                    gasAirBuildingServicesPipingCode: '',
                    gasAirFireSprinklerSystems: '',
                    pipeLength_1: '',
                    pipeLength_2: '',
                    pipeLength_3: '',
                    pipeLength_4: '',
                    pipeLength_5: '',
                    pipeLength_6: '',
                    pipeLength_7: '',
                    pipeLength_8: '',
                    pipeLength_9: '',
                    pipeLength_10: '',
                    wallThickness: '',
                    schedule: '',
                },
                displayResults: false,
            });
        }
    };

    setOutsideDiameter = o => {
        if (o !== null) {
            this.setState({
                selectedOutsideDiameter: { 'value': o.value, 'label': o.label },
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_spacing')),
                    { 'hanger_system_id': this.state.selectedSystemType.value, 'outside_diameter': this.state.selectedOutsideDiameter.value }
                );
                // filter the Size options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_hanger_spacing')),
                        { 'hanger_system_id': this.state.selectedSystemType.value, 'outside_diameter': this.state.selectedOutsideDiameter.value }
                    ), (values, index) => {
                        options.push(values)
                    });
                let nominalSizeOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                        map.set(item.nominal_size, true);    // set any value to Map
                        nominalSizeOptions.push({ value: item.nominal_size, label: item.nominal_size });
                    }
                }
                this.setResults(data, nominalSizeOptions, this.state.outsideDiameterOptions);
            });
        } else {
            this.initializeForm();
            this.setState({
                results: {
                    suggestedMaxSpanBetweenSupports: '',
                    waterPowerPipingCode: '',
                    waterBuildingServicesPipingCode: '',
                    waterFireSprinklerSystems: '',
                    gasAirPowerPipingCode: '',
                    gasAirBuildingServicesPipingCode: '',
                    gasAirFireSprinklerSystems: '',
                    pipeLength_1: '',
                    pipeLength_2: '',
                    pipeLength_3: '',
                    pipeLength_4: '',
                    pipeLength_5: '',
                    pipeLength_6: '',
                    pipeLength_7: '',
                    pipeLength_8: '',
                    pipeLength_9: '',
                    pipeLength_10: '',
                    wallThickness: '',
                    schedule: '',
                },
                displayResults: false,
            });
        }
    };

    setResults = (data, nominalSizeOptions, outsideDiameterOptions) => {
        try {
            if (Object.entries(this.state.selectedSystemType).length > 0 &&
                Object.entries(this.state.selectedNominalSize).length > 0 &&
                Object.entries(this.state.selectedOutsideDiameter).length > 0) {
                this.setState({ // update the results, ony if all three options are set
                    selectedSystemType: this.state.selectedSystemType,
                    selectedNominalSize: this.state.selectedNominalSize,
                    selectedOutsideDiameter: this.state.selectedOutsideDiameter,
                    results: {
                        suggestedMaxSpanBetweenSupports: data.max_span_between_supports,
                        waterPowerPipingCode: data.water_power_piping_code,
                        waterBuildingServicesPipingCode: data.water_building_services_piping_code,
                        waterFireSprinklerSystems: data.water_fire_sprinkler_systems,
                        gasAirPowerPipingCode: data.gas_air_power_piping_code,
                        gasAirBuildingServicesPipingCode: data.gas_air_building_services_piping_code,
                        gasAirFireSprinklerSystems: data.gas_air_fire_sprinkler_systems,
                        pipeLength_1: data[1],
                        pipeLength_2: data[2],
                        pipeLength_3: data[3],
                        pipeLength_4: data[4],
                        pipeLength_5: data[5],
                        pipeLength_6: data[6],
                        pipeLength_7: data[7],
                        pipeLength_8: data[8],
                        pipeLength_9: data[9],
                        pipeLength_10: data[10],
                        wallThickness: data.wall_thickness,
                        schedule: data.schedule,
                    },
                    displayResults: true,
                });
            } else {
                this.setState({ displayResults: false, });
            }

            this.setState({ // update the Size options, always
                nominalSizeOptions: nominalSizeOptions,
                outsideDiameterOptions: outsideDiameterOptions,
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    };

    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionIntro>
                            {ReactHtmlParser(this.props.strings.I_100_SECTION_6_INTRO)}
                        </SectionIntro>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.SYSTEM_TYPE}</Label>
                        <Select
                            options={this.state.systemTypeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_SYSTEM_TYPE}
                            className="input-filter"
                            onChange={this.setSystem}
                            isClearable={true}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedSystemType.value ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.NOMINAL_SIZE} {this.props.strings.INCHES}/{this.props.strings.MM}</Label>
                        <Select
                            options={this.state.nominalSizeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                            className="input-filter"
                            onChange={this.setNominalSize}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedSystemType.value ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.ACTUAL_PIPE_OUTSIDE_DIAMETER} {this.props.strings.INCHES}/{this.props.strings.MM}</Label>
                        <Select
                            options={this.state.outsideDiameterOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_DIAMETER}
                            className="input-filter"
                            onChange={this.setOutsideDiameter}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <div className={this.state.displayResults === true ? "show" : "hide"}>
                    <Row>
                        <Col xs={12}>
                            <SectionDivider />
                        </Col>
                    </Row>

                    <Row className={this.state.results.suggestedMaxSpanBetweenSupports ? "show" : "hide"}>
                        <Col xs={12}>
                            <Label>{this.props.strings.SUGGESTED_MAX_SPAN_BETWEEN_SUPPORTS}</Label>&nbsp;
                        <Label className="sublabel">{this.props.strings.FEET}/{this.props.strings.METERS}</Label>
                            <Input type="text" className="full" value={this.state.results.suggestedMaxSpanBetweenSupports} readOnly="readonly" />
                        </Col>
                    </Row>

                    <Row className={this.state.results.waterPowerPipingCode || this.state.results.gasAirPowerPipingCode ? "show" : "hide"}>
                        <Col xs={12}>
                            <Label>{this.props.strings.POWER_PIPING_CODE}</Label>&nbsp;
                            <Label className="sublabel">{this.props.strings.FEET}/{this.props.strings.METERS}</Label>
                        </Col>
                    </Row>
                    <Row className={this.state.results.waterPowerPipingCode || this.state.results.gasAirPowerPipingCode ? "show" : "hide"}>
                        <Col xs={6} className={this.state.results.waterPowerPipingCode ? "show" : "hide"}>
                            <Label className="sublabel">{this.props.strings.WATER}</Label>
                            <Input type="text" value={this.state.results.waterPowerPipingCode} readOnly="readonly" />
                        </Col>
                        <Col xs={6} className={this.state.results.gasAirPowerPipingCode ? "show" : "hide"}>
                            <Label className="sublabel">{this.props.strings.GAS_AIR}</Label>
                            <Input type="text" value={this.state.results.gasAirPowerPipingCode} readOnly="readonly" />
                        </Col>
                    </Row>

                    <Row className={this.state.results.gasAirPowerPipingCode || this.state.results.gasAirBuildingServicesPipingCode ? "show" : "hide"}>
                        <Col xs={12}>
                            <Label>{this.props.strings.BUILDING_SERVICES_PIPING_CODE}</Label>&nbsp;
                        <Label className="sublabel">{this.props.strings.FEET}/{this.props.strings.METERS}</Label>

                        </Col>
                    </Row>

                    <Row className={this.state.results.waterBuildingServicesPipingCode || this.state.results.gasAirBuildingServicesPipingCode ? "show" : "hide"}>
                        <Col xs={6} className={this.state.results.waterBuildingServicesPipingCode ? "show" : "hide"}>
                            <Label className="sublabel">{this.props.strings.WATER} </Label>
                            <Input type="text" value={this.state.results.waterBuildingServicesPipingCode} readOnly="readonly" />
                        </Col>
                        <Col xs={6} className={this.state.results.gasAirBuildingServicesPipingCode ? "show" : "hide"}>
                            <Label className="sublabel">{this.props.strings.GAS_AIR}</Label>
                            <Input type="text" value={this.state.results.gasAirBuildingServicesPipingCode} readOnly="readonly" />
                        </Col>
                    </Row>

                    <Row className={this.state.results.waterFireSprinklerSystems || this.state.results.gasAirFireSprinklerSystems ? "show" : "hide"}>
                        <Col xs={12}>
                            <Label>{this.props.strings.FIRE_SPRINKLER_SYSTEMS}</Label>&nbsp;
                        <Label className="sublabel">{this.props.strings.FEET}/{this.props.strings.METERS}</Label>
                        </Col>
                    </Row>

                    <Row className={this.state.results.waterFireSprinklerSystems || this.state.results.gasAirFireSprinklerSystems ? "show" : "hide"}>
                        <Col xs={6} className={this.state.results.waterFireSprinklerSystems ? "show" : "hide"}>
                            <Label className="sublabel">{this.props.strings.WATER} </Label>
                            <Input type="text" value={this.state.results.waterFireSprinklerSystems} readOnly="readonly" />
                        </Col>
                        <Col xs={6} className={this.state.results.gasAirFireSprinklerSystems ? "show" : "hide"}>
                            <Label className="sublabel">{this.props.strings.GAS_AIR}</Label>
                            <Input type="text" className="full" value={this.state.results.gasAirFireSprinklerSystems} readOnly="readonly" />
                        </Col>
                    </Row>


                    <Row className={this.state.results.pipeLength_1 ? "" : "hide"}>
                        <Col xs={12}>
                            <Label>{this.props.strings.PIPE_LENGTH_HANGERS} {this.props.strings.FEET}/{this.props.strings.METERS}</Label>
                        </Col>
                    </Row>

                    <Row className={this.state.results.pipeLength_1 ? "" : "hide"}>
                        <Col xs={6}>
                            <Label>{this.getPipeLengthLabel(1)}</Label>
                            <Input type="text" value={this.state.results.pipeLength_1} readOnly="readonly" />
                        </Col>
                        <Col xs={6}>
                            <Label>{this.getPipeLengthLabel(2)}</Label>
                            <Input type="text" value={this.state.results.pipeLength_2} readOnly="readonly" />
                        </Col>
                    </Row>

                    <Row className={this.state.results.pipeLength_3 ? "" : "hide"}>
                        <Col xs={6}>
                            <Label>{this.getPipeLengthLabel(3)}</Label>
                            <Input type="text" value={this.state.results.pipeLength_3} readOnly="readonly" />
                        </Col>
                        <Col xs={6}>
                            <Label>{this.getPipeLengthLabel(4)}</Label>
                            <Input type="text" value={this.state.results.pipeLength_4} readOnly="readonly" />
                        </Col>
                    </Row>

                    <Row className={this.state.results.pipeLength_5 ? "" : "hide"}>
                        <Col xs={6}>
                            <Label>{this.getPipeLengthLabel(5)}</Label>
                            <Input type="text" value={this.state.results.pipeLength_5} readOnly="readonly" />
                        </Col>
                        <Col xs={6}>
                            <Label>{this.getPipeLengthLabel(6)}</Label>
                            <Input type="text" value={this.state.results.pipeLength_6} readOnly="readonly" />
                        </Col>
                    </Row>

                    <Row className={this.state.results.pipeLength_7 ? "" : "hide"}>
                        <Col xs={6}>
                            <Label>{this.getPipeLengthLabel(7)}</Label>
                            <Input type="text" value={this.state.results.pipeLength_7} readOnly="readonly" />
                        </Col>
                        <Col xs={6}>
                            <Label>{this.getPipeLengthLabel(8)}</Label>
                            <Input type="text" value={this.state.results.pipeLength_8} readOnly="readonly" />
                        </Col>
                    </Row>

                    <Row className={this.state.results.pipeLength_9 ? "" : "hide"}>
                        <Col xs={6}>
                            <Label>{this.getPipeLengthLabel(9)}</Label>
                            <Input type="text" value={this.state.results.pipeLength_9} readOnly="readonly" />
                        </Col>
                        <Col xs={6}>
                            <Label>{this.getPipeLengthLabel(10)}</Label>
                            <Input type="text" value={this.state.results.pipeLength_10} readOnly="readonly" />
                        </Col>
                    </Row>


                    <Row className={this.state.results.wallThickness ? "" : "hide"}>
                        <Col xs={12}>
                            <Label>{this.props.strings.WALL_THICKNESS}</Label>
                            <Input type="text" className="full" value={this.state.results.wallThickness} readOnly="readonly" />
                        </Col>
                    </Row>

                    <Row className={this.state.results.schedule ? "" : "hide"}>
                        <Col xs={12}>
                            <Label>{this.props.strings.SCHEDULE}</Label>
                            <Input type="text" className="full" value={this.state.results.schedule} readOnly="readonly" />
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col xs={12}>
                        <Subtext>

                        </Subtext>
                    </Col>
                </Row>
            </Grid>
        );
    }
};

const SectionIntro = styled.p`
        border-bottom: 0.5px solid #efefef;
        padding-bottom: 15px;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 0;
        margin: 0 0 10px 0;
    `,
    SectionDivider = styled.div`
        border-top: 1px solid #efefef;
        border-collapse: collapse;
        height: 1px;
        margin: 10px 0;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;

        &.full {
            width: 100%;
        }
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;
    `;

export default HangerSupportSpacing;