import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class LubricationGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=lubrication_guide',
            nominalSizeOptions: [],
            selectedNominalSize: {},
            results: {
                actualPipeOutsideDiameter: '',
                perTube: '',
                perQuart: '',
            },
        };
    }

    setNominalSizeOptions = (response) => {
        let nominalSize = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_lubrication_guide'));
        data.map((value, key) => {
            nominalSize.push({ 'value': value.nominal_size, 'label': value.nominal_size });
            return true;
        });
        this.setState({
            nominalSizeOptions: nominalSize
        });
    };

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_lubrication_guide', JSON.stringify(response.data.data));
                this.setNominalSizeOptions();
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    setNominalSize = n => {
        if (n !== null) {
            this.setState({
                selectedNominalSize: { 'value': n.value, 'label': n.label },
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_lubrication_guide')),
                    ['nominal_size', this.state.selectedNominalSize.value]
                );
                this.setState({
                    selectedNominalSize: { 'value': this.state.selectedNominalSize.value, 'label': this.state.selectedNominalSize.value },
                    results: {
                        actualPipeOutsideDiameter: data.outside_diameter,
                        perTube: data.gaskets_per_tube,
                        perQuart: data.gaskets_per_quart,
                    },
                });
            });
        } else {
            this.setState({
                selectedNominalSize: {},
                results: {
                    actualPipeOutsideDiameter: '',
                    perTube: '',
                    perQuart: '',
                },
            });
        }
    };

    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionIntro>
                            {ReactHtmlParser(this.props.strings.I_100_SECTION_5_INTRO)}
                        </SectionIntro>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.COUPLING_SIZE}:
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.NOMINAL_SIZE} {this.props.strings.INCHES}/{this.props.strings.MM}</Label>
                        <Select
                            options={this.state.nominalSizeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setNominalSize}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.ACTUAL_PIPE_OUTSIDE_DIAMETER} {this.props.strings.INCHES}/{this.props.strings.MM}</Label>
                        <Input type="text" placeholder="&mdash;" className="full" value={this.state.results.actualPipeOutsideDiameter || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.NUMBER_OF_GASKETS}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Label>{this.props.strings.PER_TUBE}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.perTube || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={6}>
                        <Label>{this.props.strings.PER_QUART}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.perQuart || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_100_SECTION_5_FOOTER)}
                        </Subtext>
                    </Col>
                </Row>
            </Grid>
        );
    }
};

const SectionIntro = styled.p`
        border-bottom: 0.5px solid #efefef;
        padding-bottom: 15px;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 0;
        margin: 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;

        &.full {
            width: 100%;
        }
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default LubricationGuide;