import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga4";

import Main from './components/Main';
import './index.css';
import './assets/css/hammer.css';

import * as serviceWorker from './serviceWorker';

ReactGA.initialize("G-FSE6JC677X");
ReactGA.send("pageview");

render(
    <BrowserRouter>
        <Main />
    </BrowserRouter>,
    document.querySelector('#root')
);

serviceWorker.register();
