import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import Toggle from 'react-toggle';

import '../../../assets/css/app.css';
import '../../../assets/css/react-toggle.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class CpvcExpansionContraction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            units: localStorage.getItem('units') !== undefined ? (localStorage.getItem('units') === 'true') : true, // TRUE --> IMPERIAL || FALSE --> METRIC
            tableOptions: [
                {
                    label: 'Configuration Lengths',
                    value: 'lengths',
                },
                {
                    label: 'Expansion / Contraction',
                    value: 'expansion',
                },
            ],
            selectedTableType: [],
            // lengths
            getSectionDataUrlLengths: process.env.REACT_APP_API_URL + 'get/section?table=cpvc_configuration_lengths',
            pipeTypeOptions: [],
            selectedPipeType: [],
            deltaOptions: [],
            selectedDelta: [],
            diameterOptions: [],
            selectedDiameter: [],
            resultsLengths: [{
                pipeRunLength20FeetInches: '',
                pipeRunLength20FeetMM: '',
                pipeRunLength40FeetInches: '',
                pipeRunLength40FeetMM: '',
                pipeRunLength60FeetInches: '',
                pipeRunLength60FeetMM: '',
                pipeRunLength80FeetInches: '',
                pipeRunLength80FeetMM: '',
                pipeRunLength100FeetInches: '',
                pipeRunLength100FeetMM: '',
                pipeRunLength125FeetInches: '',
                pipeRunLength125FeetMM: '',
                pipeRunLength150FeetInches: '',
                pipeRunLength150FeetMM: '',
                pipeRunLength200FeetInches: '',
                pipeRunLength200FeetMM: '',
            }],
            // expansion
            getSectionDataUrlExpansion: process.env.REACT_APP_API_URL + 'get/section?table=cpvc_expansion_contraction',
            pipeMaterialOptions: [],
            selectedPipeMaterial: [],
            results: [{
                coefficientOfThermalExpansionIn: '',
                coefficientOfThermalExpansionMm: '',
            }],
        };
    }

    // BOTH IN HERE
    getData = async () => {
        // LENGTHS
        axios.get(this.state.getSectionDataUrlLengths)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_cpvc_configuration_lengths', JSON.stringify(response.data.data));
            })
            .catch(console.log);
        // CONTRACTION
        axios.get(this.state.getSectionDataUrlExpansion)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_cpvc_expansion_contraction', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    }

    // BOTH IN HERE
    getAllData = async () => {
        await axios.all([
            this.getData(), 
            //length
            this.getPipeType(),
            this.getDelta(),
            this.getDiameter(),
            //expansion
            this.getPipeMaterial('inches'), 
            this.getPipeMaterial('mm')
            ])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                });
            }))
            .finally(() => {
                this._isMounted = true;
            });
    }

    componentDidMount() {
        this.getAllData();
    }

    // SET TABLE TYPE
    setTableType = (n) => {
        if (n !== null) {
            this.setState({
                selectedTableType: { 'value': n.value, 'label': n.label },
            });
        } else {
            console.log('No Table Type');
        }
    }

    changeUnits = (e) => {
        this.setState({
            units: e.target.checked,
        });
        // this.setState({ selectedPipeType: null });
        this.resetResults();
        localStorage.setItem('units', e.target.checked)
    }

    /* - - - - - - - - - - - - - - - - - - - - - - - - - -
    /* LENGTHS
    */

    // changeUnits = (e) => {
    //     this.setState({ selectedPipeMaterial: null });
    //     this.resetResults();
    // }
    
    // GET PIPE MATERIAL
    getPipeType = async (units) => {
        axios.get(this.state.getSectionDataUrlLengths + '&select=type&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_cpvc_configuration_lengths_type', JSON.stringify(response.data.data));
                let pipeType = [];
                response.data.data.map((value, key) => {
                    if (value.type) {
                        pipeType.push({
                            'value': value.type,
                            'label': value.type
                        });
                    }
                    return true;
                });
                this.setState({ pipeTypeOptions:pipeType })
            })
            .catch(console.log);
    }

    // GET DELTA
    getDelta = async (units) => {
        axios.get(this.state.getSectionDataUrlLengths + '&select=delta_t&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_cpvc_configuration_lengths_delta_t', JSON.stringify(response.data.data));
                let deltat = [];
                response.data.data.map((value, key) => {
                    if (value.delta_t) {
                        deltat.push({
                            'value': value.delta_t,
                            'label': value.delta_t
                        });
                    }
                    return true;
                });
                this.setState({ deltaOptions:deltat })
            })
            .catch(console.log);
    }

    // GET DIAMETER
    getDiameter = async (units) => {
        axios.get(this.state.getSectionDataUrlLengths + '&select=nominal_diameter_inches&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_cpvc_configuration_lengths_nominal_diameter_actual', JSON.stringify(response.data.data));
                let diameters = [];
                response.data.data.map((value, key) => {
                    if (value.nominal_diameter_inches) {
                        diameters.push({
                            'value': value.nominal_diameter_inches,
                            'label': value.nominal_diameter_inches
                        });
                    }
                    return true;
                });
                this.setState({ diameterOptions:diameters })
            })
            .catch(console.log);
    }

    // SET PIPE MATERIAL
    setPipeType = (p) => {
        if (p !== null) {
            this.setState({
                selectedPipeType: { 'value': p.value, 'label': p.label },
                // selectedDelta: { 'value': '', 'label': '' },
                // selectedDiameter: { 'value': '', 'label': '' },
            }, () => {
                // filter the Size options
                let deltas = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_cpvc_configuration_lengths')),
                        { 'type': this.state.selectedPipeType.value }
                    ), (values, index) => {
                        if (values.delta_t) {
                            deltas.push(values);
                        }
                    });
                let deltaOptions = [];
                let map = new Map();
                for (const item of deltas) {
                    if (!map.has(item.delta_t) && item.delta_t !== '' && item.delta_t !== null) {
                        map.set(item.delta_t, true);    // set any value to Map
                        deltaOptions.push({ 
                            value: item.delta_t, 
                            label: item.delta_t 
                        });
                    }
                }
                this.setState({
                    deltaOptions: { options: deltaOptions }
                })

                this.resetResultsLengths();
            });
        } else {
            this.resetResultsLengths();
        }
    }

    // SET DELTA
    setDelta = (d) => {
        if (d !== null) {
            this.setState({
                selectedDelta: { 'value': d.value, 'label': d.label },
                // selectedDiameter: { 'value': '', 'label': '' },
            }, () => {
                // filter the Size options
                let diameters = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_cpvc_configuration_lengths')),
                        { 'delta_t': this.state.selectedDelta.value }
                    ), (values, index) => {
                        if (values.nominal_diameter_inches) {
                            diameters.push(values);
                        }
                    });
                let diameterOptions = [];
                let map = new Map();
                for (const item of diameters) {
                    if (!map.has(item.nominal_diameter_inches) && item.nominal_diameter_inches !== '' && item.nominal_diameter_inches !== null) {
                        map.set(item.nominal_diameter_inches, true);    // set any value to Map
                        diameterOptions.push({ 
                            value: item.nominal_diameter_inches, 
                            label: `${item.nominal_diameter_inches} (  ${item.nominal_diameter_dn} ) /  ${item.actual_outside_diameter_inches} ( ${item.actual_outside_diameter_mm} )`
                        });
                    }
                }
                this.setState({
                    diameterOptions: { options: diameterOptions }
                })

                this.resetResultsLengths();
            });
        } else {
            this.resetResultsLengths();
        }
    }

    // SET DIAMETER
    setDiameter = (n) => {
        if (n !== null) {
            this.setState({
                selectedDiameter: { 'value': n.value, 'label': n.label },
            }, () => {
                let filter = {
                    'type': this.state.selectedPipeType.value,
                    'delta_t': this.state.selectedDelta.value,
                    'nominal_diameter_inches': this.state.selectedDiameter.value,
                }
                let dataLengths = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_cpvc_configuration_lengths')),
                    filter
                );
                this.setResultsLengths(dataLengths);
            });
        } else {
            this.resetResultsLengths();
        }
    }
    
    resetResultsLengths = () => {
        this.setState({
            resultsLengths: {
                pipeRunLength20FeetInches: '',
                pipeRunLength20FeetMM: '',
                pipeRunLength40FeetInches: '',
                pipeRunLength40FeetMM: '',
                pipeRunLength60FeetInches: '',
                pipeRunLength60FeetMM: '',
                pipeRunLength80FeetInches: '',
                pipeRunLength80FeetMM: '',
                pipeRunLength100FeetInches: '',
                pipeRunLength100FeetMM: '',
                pipeRunLength125FeetInches: '',
                pipeRunLength125FeetMM: '',
                pipeRunLength150FeetInches: '',
                pipeRunLength150FeetMM: '',
                pipeRunLength200FeetInches: '',
                pipeRunLength200FeetMM: '',
            },
        })
    }

    setResultsLengths = (data) => {
        if (Object.entries(this.state.selectedPipeType).length > 0 &&
            Object.entries(this.state.selectedDelta).length > 0 &&
            Object.entries(this.state.selectedDiameter).length > 0) {
            this.setState({
                resultsLengths: {
                    pipeRunLength20FeetInches: data.pipe_run_length_20_feet_inches,
                    pipeRunLength20FeetMM: data.pipe_run_length_20_feet_mm,
                    pipeRunLength40FeetInches: data.pipe_run_length_40_feet_inches,
                    pipeRunLength40FeetMM: data.pipe_run_length_40_feet_mm,
                    pipeRunLength60FeetInches: data.pipe_run_length_60_feet_inches,
                    pipeRunLength60FeetMM: data.pipe_run_length_60_feet_mm,
                    pipeRunLength80FeetInches: data.pipe_run_length_80_feet_inches,
                    pipeRunLength80FeetMM: data.pipe_run_length_80_feet_mm,
                    pipeRunLength100FeetInches: data.pipe_run_length_100_feet_inches,
                    pipeRunLength100FeetMM: data.pipe_run_length_100_feet_mm,
                    pipeRunLength125FeetInches: data.pipe_run_length_125_feet_inches,
                    pipeRunLength125FeetMM: data.pipe_run_length_125_feet_mm,
                    pipeRunLength150FeetInches: data.pipe_run_length_150_feet_inches,
                    pipeRunLength150FeetMM: data.pipe_run_length_150_feet_mm,
                    pipeRunLength200FeetInches: data.pipe_run_length_200_feet_inches,
                    pipeRunLength200FeetMM: data.pipe_run_length_200_feet_mm,
                },
            });
        } else {
            this.resetResultsLengths();
        }
    }

    /* - - - - - - - - - - - - - - - - - - - - - - - - - -
    /* CONTRACTION
    */
   
    // GET PIPE MATERIAL
    getPipeMaterial = async (units) => {
        axios.get(this.state.getSectionDataUrlExpansion + '&select=pipe_material&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_cpvc_expansion_contraction_pipe_material', JSON.stringify(response.data.data));
                let PipeMaterial = [];
                response.data.data.map((value, key) => {
                    // console.log(response.data.data);

                    if (value.pipe_material) {
                        PipeMaterial.push({
                            'value': value.pipe_material,
                            'label': value.pipe_material
                        });
                    }
                    return true;
                });
                this.setState({ pipeMaterialOptions:PipeMaterial })
            })
            .catch(console.log);
    }

    // SET PIPE MATERIAL
    setPipeMaterial = (n) => {
        if (n !== null) {
            this.setState({
                selectedPipeMaterial: { 'value': n.value, 'label': n.label },
            }, () => {
                let filter = {'pipe_material': this.state.selectedPipeMaterial.value}
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_cpvc_expansion_contraction')),
                    filter
                );
                this.setResults(data);
            });
        } else {
            this.resetResults();
        }
    }

    resetResults = () => {
        this.setState({
            results: {
                coefficientOfThermalExpansionIn: '',
                coefficientOfThermalExpansionMm: '',
            },
        })
    }

    setResults = (data) => {
        if (Object.entries(this.state.selectedPipeMaterial).length > 0) {
            this.setState({
                results: {
                    coefficientOfThermalExpansionIn: data.coefficient_of_thermal_expansion_in_in_f,
                    coefficientOfThermalExpansionMm: data.coefficient_of_thermal_expansion_mm_mm_c,
                },
            });
        } else {
            this.resetResults();
        }
    }

    render() {
        const resultsLengths = this.state.resultsLengths;
        const strings = this.props.strings;
        return (
            <Grid>
                {/* SELECT A TOOL TYPE */}
                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row className="extra-padding">
                    <Col xs={12}>
                        <Label>{strings.TOOL_TYPE}</Label>
                        <Select
                            options={this.state.tableOptions}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_A_TOOL_TYPE}
                            className="input-filter"
                            isClearable={false}
                            onChange={this.setTableType}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                {/* LENGTHS TOOL */}
                <Row className={this.state.selectedTableType.value === 'lengths' ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{strings.PIPE_TYPE}</Label>
                        <Select
                            options={this.state.pipeTypeOptions}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_A_PIPE_TYPE}
                            className="input-filter"
                            isClearable={false}
                            onChange={this.setPipeType}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'lengths' ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{strings.DELTA_T}</Label>
                        <Select
                            options={this.state.deltaOptions.options}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_A_DELTA_T}
                            className="input-filter"
                            isClearable={false}
                            isDisabled={Object.entries(this.state.selectedPipeType).length === 0}
                            onChange={this.setDelta}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'lengths' ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{strings.NOMINAL + ' / ' + strings.ACTUAL_OUTSIDE} {strings.DIAMETER}</Label>
                        <Select
                            options={this.state.diameterOptions.options}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_A_DIAMETER}
                            className="input-filter"
                            isClearable={false}
                            isDisabled={Object.entries(this.state.selectedDelta).length === 0}
                            onChange={this.setDiameter}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'lengths' ? "extra-padding" : "hide"}>
                    <Col xs={6}>
                        <Label>{strings.UNITS}</Label>
                    </Col>
                    <Col xs={6} className="toggle-container">
                        <label>
                            <Toggle
                                defaultChecked={this.state.units}
                                className="victaulic-toggle"
                                icons={false}
                                onChange={this.changeUnits} />
                            <span className="victaulic-toggle-label">
                                {(this.state.units === true ? strings.IMPERIAL : strings.METRIC)}
                            </span>
                        </label>
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'lengths' ? "" : "hide"}>
                    <Col xs={12}>
                        <CategoryHeader>Configuration Length - {(this.state.units === true ? strings.INCHES : strings.MM)}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'lengths' ? "" : "hide"}>
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 20 {(this.state.units === true ? strings.FEET : strings.METERS)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_20_feet_inches" 
                            value={resultsLengths.pipeRunLength20FeetInches || ''} 
                            readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 6 {ReactHtmlParser(strings.METERS)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_20_feet_mm" 
                            value={resultsLengths.pipeRunLength20FeetMM || ''} 
                            readOnly="readonly" />
                    </Col>
                
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 40 {ReactHtmlParser(strings.FEET)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_40_feet_inches" 
                            value={resultsLengths.pipeRunLength40FeetInches || ''} 
                            readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 12 {ReactHtmlParser(strings.METERS)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_40_feet_mm" 
                            value={resultsLengths.pipeRunLength40FeetMM || ''} 
                            readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'lengths' ? "" : "hide"}>
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 60 {ReactHtmlParser(strings.FEET)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_60_feet_inches" 
                            value={resultsLengths.pipeRunLength60FeetInches || ''} 
                            readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 18 {ReactHtmlParser(strings.METERS)}</Label>
                        <Input 
                            type="text" placeholder="&mdash;" 
                            id="pipe_run_length_60_feet_mm" 
                            value={resultsLengths.pipeRunLength60FeetMM || ''} 
                            readOnly="readonly" />
                    </Col>
                
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 80 {ReactHtmlParser(strings.FEET)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_80_feet_inches" 
                            value={resultsLengths.pipeRunLength80FeetInches || ''} 
                            readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 24 {ReactHtmlParser(strings.METERS)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_80_feet_mm" 
                            value={resultsLengths.pipeRunLength80FeetMM || ''} 
                            readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'lengths' ? "" : "hide"}>
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 100 {ReactHtmlParser(strings.FEET)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_100_feet_inches" 
                            value={resultsLengths.pipeRunLength100FeetInches || ''} 
                            readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 31 {ReactHtmlParser(strings.METERS)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_100_feet_mm" 
                            value={resultsLengths.pipeRunLength100FeetMM || ''} 
                            readOnly="readonly" />
                    </Col>
               
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 125 {ReactHtmlParser(strings.FEET)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_125_feet_inches" 
                            value={resultsLengths.pipeRunLength125FeetInches || ''} 
                            readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 38 {ReactHtmlParser(strings.METERS)}</Label>
                        <Input
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_125_feet_mm" 
                            value={resultsLengths.pipeRunLength125FeetMM || ''} 
                            readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'lengths' ? "" : "hide"}>
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 150 {ReactHtmlParser(strings.FEET)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_150_feet_inches" 
                            value={resultsLengths.pipeRunLength150FeetInches || ''} 
                            readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 46 {ReactHtmlParser(strings.METERS)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_150_feet_mm" 
                            value={resultsLengths.pipeRunLength150FeetMM || ''} 
                            readOnly="readonly" />
                    </Col>
               
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 200 {ReactHtmlParser(strings.FEET)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_200_feet_inches" 
                            value={resultsLengths.pipeRunLength200FeetInches || ''} 
                            readOnly="readonly" />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>{strings.PIPE_RUN_LENGTH} - 61 {ReactHtmlParser(strings.METERS)}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="pipe_run_length_200_feet_mm" 
                            value={resultsLengths.pipeRunLength200FeetMM || ''} 
                            readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'lengths' ? "" : "hide"}>
                    <Col xs={12}>
                        <SectionCaution>
                            <h2><i className="icon-caution icon-round-bg"></i> {this.props.strings.NOTICE}</h2>
                            <div>{ReactHtmlParser(this.props.strings.I_350_SECTION_6_CAUTION)}</div>
                        </SectionCaution>
                    </Col>
                </Row>

                {/* EXPANSION TOOL */}
                <Row className={this.state.selectedTableType.value === 'expansion' ? "" : "hide"}>
                    <Col xs={12}>
                        <SectionIntro>
                            {ReactHtmlParser(strings.I_350_SECTION_6_INTRO)}
                        </SectionIntro>
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'expansion' ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{strings.PIPE_MATERIAL}</Label>
                        <Select
                            options={this.state.pipeMaterialOptions}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_A_PIPE_MATERIAL}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setPipeMaterial}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'expansion' ? "" : "hide"}>
                    <Col xs={12}>
                        <CategoryHeader>
                            {ReactHtmlParser(strings.COEFFICIENT_OF_THERMAL_EXPANSION + ' (<span>' + strings.COEFFICIENT_A + '</span>)')}
                        </CategoryHeader>
                    </Col>
                </Row>
                
                <Row className={this.state.selectedTableType.value === 'expansion' ? "" : "hide"}>
                    <Col xs={6}>
                        <Label>{ReactHtmlParser(strings.IN_IN_F)}</Label>
                        <Input type="text" placeholder="&mdash;" id="coefficient_thermal_in" value={this.state.results.coefficientOfThermalExpansionIn || ''} readOnly="readonly" />
                    </Col>

                    <Col xs={6}>
                        <Label>{ReactHtmlParser(strings.MM_MM_C)}</Label>
                        <Input type="text" placeholder="&mdash;" id="coefficient_thermal_mm" value={this.state.results.coefficientOfThermalExpansionMm || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'expansion' ? "" : "hide"}>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(strings.I_350_SECTION_6_FOOTER)}
                        </Subtext>
                    </Col>
                </Row>

            </Grid>
        );
    }
}

const SectionCaution = styled.div`
        border: 1px solid #333333;
        padding: 0;
        margin: 20px 0;
        font-weight: bold;

        h2 {
            font-family: Rajdhani, sans-serif;
            font-size: 20px;
            background: #333333;
            color: #ffffff;
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
            margin:0 0 20px 0;

            i {
                font-size: 14px;
                vertical-align: middle;
                position:relative;
                top:-1px;
            }
        }

        div {
            font-family: 'Open Sans', sans-serif;
            text-transform: none;
            padding: 0 10px;
            position: relative;
            top: -10px;
            margin: 0 0 5px 0;
        }

        .icon-round-bg {
            background: #ff7f00;
            color: #ffffff;
        }
    `,
    SectionIntro = styled.p`
        border-bottom: 0.5px solid #efefef;
        padding-bottom: 15px;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;

        span {
            text-transform:lowercase;
            font-weight:400;
        }
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default CpvcExpansionContraction;