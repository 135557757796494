import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const toolModelOptions = [
    { value: 'VG28GD', label: 'VG28GD' },
    { value: 'Vic-Groover', label: 'Vic-Groover' },
    { value: 'VG824', label: 'VG824' },
    { value: 'VG412', label: 'VG412' },
];

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class ToolRatings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=awwa_tool_ratings',
            selectedToolModel: {},
            selectedPipeMaterial: {},
            results: [{
                pipeSize: { key: "Pipe Size", value: ReactHtmlParser('&mdash;') },
            }],
        };
    }

    getPipeMaterial = async () => {
        // Retrieve the nominal_size for dropdown from the API
        axios.get(this.state.getSectionDataUrl + '&select=pipe_material&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_pipe_material', JSON.stringify(response.data.data));
                let pipeMaterial = [];
                response.data.data.map((value, key) => {
                    pipeMaterial.push({ 'value': value.pipe_material, 'label': value.pipe_material });
                    return true;
                });
                this.setState({
                    pipeMaterialOptions: pipeMaterial
                })
            })
            .catch(console.log);
    };

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_tool_capacities', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.getPipeMaterial(), this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    setToolModels = tm => {
        this.setState({
            selectedToolModel: { 'value': tm.value, 'label': tm.label },
        }, () => {
            this.setResults();
        });
    };

    setPipeMaterial = pm => {
        this.setState({
            selectedPipeMaterial: { 'value': pm.value, 'label': pm.label },
        }, () => {
            this.setResults();
        });
    };
    setResults = () => {
        const toolCapacities = JSON.parse(localStorage.getItem(this.props.sectionId + '_tool_capacities'));
        const selectedToolModel = this.state.selectedToolModel
        const selectedPipeMaterial = this.state.selectedPipeMaterial
        if (Object.entries(selectedToolModel).length > 0 &&
            Object.entries(selectedPipeMaterial).length > 0) {
            try {
                let data = [],
                    results = [];
                _.forEach(
                    _.filter(
                        toolCapacities, {
                        'tool_model': selectedToolModel.value,
                        'pipe_material': selectedPipeMaterial.value
                    }
                    ), (result) => {
                        _.forEach(result, (value, key) => {
                            if (key.includes("pipe_size_")) {
                                let results = { key: key, value: value }
                                data.push(results)
                            }

                        })
                    })
                if (data.length > 0) {
                    results = data.map((item, index) => ({
                        pipeSize: item || '',
                    }));
                } else {
                    results = [{
                        pipeSize: 'N/A',
                    }];
                }

                this.setState({
                    results: results,
                });

            } catch (e) {
                console.log('Error', e);
                console.log('state', this.state);
            }
        }
    }

    render() {
        const results = this.state.results;

        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionIntro>
                            {ReactHtmlParser(this.props.strings.I_300_SECTION_1_HEADER)}
                        </SectionIntro>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.TOOL_MODELS}</Label>
                        <Select
                            options={toolModelOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_TOOL_TYPE}
                            className="input-filter"
                            onChange={this.setToolModels}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.PIPE_MATERIAL}</Label>
                        <Select
                            options={this.state.pipeMaterialOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_PIPE_MATERIAL}
                            className="input-filter"
                            onChange={this.setPipeMaterial}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SectionDivider />
                    </Col>
                </Row>

                <Row>
                    <ToolRatingsResult>
                        {
                            results.map((value, index) => {
                                const pipeSize = value.pipeSize.key;
                                const sizeclass = value.pipeSize.value;
                                if (sizeclass) {
                                    return (
                                        <Row key={index}>
                                            <Col xs={12}>
                                                <CategoryHeader>
                                                    {pipeSize.replace(/_/g, " ")}
                                                </CategoryHeader>
                                            </Col>
                                            <Col xs={12}>
                                                <Input type="text" placeholder="&mdash;" value={value.pipeSize.value} readOnly="readonly" />
                                            </Col>
                                        </Row>

                                    )
                                } else {
                                    return ''
                                }

                            })
                        }
                    </ToolRatingsResult>
                </Row>
            </Grid>
        );
    }
};

const SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    SectionDivider = styled.div`
        border-top: 1px solid #efefef;
        border-collapse: collapse;
        height: 1px;
        margin: 10px 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        margin: 10px 0 0 0;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px 0 10px 10px;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 97%;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `,
    SectionIntro = styled.p`
        border-bottom: 0.5px solid #efefef;
        padding-bottom: 15px;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    ToolRatingsResult = styled.div`
        margin-bottom: 50px;
        width: 100%;

    `;

export default ToolRatings;