import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import '../assets/css/app.css';
import Navigation from './shared/Navigation';

// I-100
import PipePreparation from './sections/I-100/PipePreparation';
import ToolCapacities from './sections/I-100/ToolCapacities';
import GrooveSpecifications from './sections/I-100/GrooveSpecification';
import GasketSelection from './sections/I-100/GasketSelection';
import LubricationGuide from './sections/I-100/LubricationGuide';
import HangerSupportSpacing from './sections/I-100/HangerSupportSpacing';
import PipeEndSeparation from './sections/I-100/PipeEndSeparation';
// I-300
import ToolRatings from './sections/I-300/ToolRatings';
import GrooveDimensions from './sections/I-300/GrooveDimensions';
import SupportSpacing from './sections/I-300/SupportSpacing';
import AwwaPipeEndSeparation from './sections/I-300/AwwaPipeEndSeparation';
// I-350
import CpvcExpansionContraction from './sections/I-350/CpvcExpansionContraction';
import CpvcFlangeConnections from './sections/I-350/CpvcFlangeConnections';
import CpvcGrooveDimensions from './sections/I-350/CpvcGrooveDimensions';
import CpvcHangerSupportSpacing from './sections/I-350/CpvcHangerSupportSpacing';
import CpvcNutSocketSizes from './sections/I-350/CpvcNutSocketSizes';
import CpvcTemperatureRequirements from './sections/I-350/CpvcTemperatureRequirements';
// I-600
import CopperExpansionContraction from './sections/I-600/CopperExpansionContraction';
import CopperGasketsLubrication from './sections/I-600/CopperGasketsLubrication';
import CopperGrooveDimensions from './sections/I-600/CopperGrooveDimensions';
import CopperSupportSpacing from './sections/I-600/CopperSupportSpacing';
import CopperTubePreparation from './sections/I-600/CopperTubePreparation';
// I-900
import HdpeGasketsLubrication from './sections/I-900/HdpeGasketsLubrication';
import HdpeBoltsSockets from './sections/I-900/HdpeBoltsSockets';
import HdpeInstallation from './sections/I-900/HdpeInstallation';

class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            languages: JSON.parse(localStorage.getItem('languages')),
            strings: JSON.parse(localStorage.getItem('strings')),
            handbooks: JSON.parse(localStorage.getItem('handbooks')),
        };
    }

    componentDidMount() { }

    render() {
        const { guide, id } = this.props.match.params;
        const handbook = _.get(this.state.handbooks, "id_" + guide)
        /* eslint eqeqeq: 0 */
        const section = _.find(handbook.sections, (o) => { return o.id == id });

        return (
            <main>
                <Navigation languages={this.state.languages} strings={this.state.strings} handbooks={this.state.handbooks} />
                <HeaderContainer>
                    <Link to={{
                        pathname: `${process.env.REACT_APP_PUBLIC_URL}guide/${guide}`,
                        state: { languages: this.state.languages, strings: this.state.strings }
                    }}>
                        <ArrowContainer>
                            <Arrow className="icon-back" />
                        </ArrowContainer>
                    </Link>
                    <Header>
                        {section.string}
                    </Header>
                </HeaderContainer>

                <SectionContainer>
                    {(() => {
                        const SectionName = section.key.replace(/\s/g, '').replace("-", '');
                        let componentList = {
                            // I-100
                            PIPE_LENGTHS_SUITABLE_FOR_GROOVING: PipePreparation,
                            TOOL_CAPACITIES: ToolCapacities,
                            GROOVE_SPECIFICATIONS: GrooveSpecifications,
                            GASKET_SELECTION: GasketSelection,
                            LUBRICATION_GUIDE: LubricationGuide,
                            HANGER_SPACING: HangerSupportSpacing,
                            ALLOWABLE_PIPE_END_SEPARATION: PipeEndSeparation,
                            // I-300
                            TOOL_RATINGS: ToolRatings,
                            GROOVE_DIMENSIONS: GrooveDimensions,
                            SUPPORT_SPACING: SupportSpacing,
                            PIPE_END_SEPARATION: AwwaPipeEndSeparation,
                            // I-350
                            CPVC_EXPANSION_AND_CONTRACTION: CpvcExpansionContraction,
                            CPVC_FLANGE_CONNECTIONS: CpvcFlangeConnections,
                            CPVC_GROOVE_DIMENSIONS: CpvcGrooveDimensions,
                            CPVC_HANGER_SUPPORT_SPACING: CpvcHangerSupportSpacing,
                            CPVC_NUT_AND_SOCKET_SIZES: CpvcNutSocketSizes,
                            CPVC_TEMPERATURE_REQUIREMENTS: CpvcTemperatureRequirements,
                            // I-600
                            COPPER_EXPANSION_AND_CONTRACTION: CopperExpansionContraction,
                            COPPER_GASKETS_AND_LUBRICATION: CopperGasketsLubrication,
                            COPPER_GROOVE_DIMENSIONS: CopperGrooveDimensions,
                            COPPER_SUPPORT_SPACING: CopperSupportSpacing,
                            COPPER_TUBE_PREPARATION: CopperTubePreparation,
                            // I-900
                            HDPE_GASKETS_AND_LUBRICATION: HdpeGasketsLubrication,
                            HDPE_BOLTS_AND_SOCKETS: HdpeBoltsSockets,
                            HDPE_INSTALLATION: HdpeInstallation,

                        }
                        let componentName = SectionName;
                        let ChildComponent = componentList[componentName];
                        return <ChildComponent strings={this.state.strings} sectionId={id} />
                    })()}
                </SectionContainer>

            </main >
        );
    }
};

const HeaderContainer = styled.div`
        width: 100%;
        background: #ff7f00;
        position: relative;
        top: 63px;
        font-family: Rajdhani, sans-serif;
        color: #ffffff;
        clear: both;

    `,
    Header = styled.h1`
        font-size: 24px;
        text-align: center;
        position: relative;
        top: 0px;
        width: 60%;
        margin: 0 auto;
        padding: 10px 0;
        text-transform: uppercase;
        line-height:1.1;
    `,
    SectionContainer = styled.div`
        font-family: 'Open Sans', sans-serif;
        padding: 0 15px;
        position: relative;
        top: 63px;
        clear: both;
    `,
    ArrowContainer = styled.div`
        right: 15px;
        position: relative;
        z-index: 2;
        top: 10px;
        left: 15px;
        float: left;
        color: #ffffff;
    `,
    Arrow = styled.i`
        font-size: 26px;
        position: relative;
    `;

export default Section;