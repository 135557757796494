import React from 'react';

const VictaulicSVG = ({ width }) => (
    <svg
        className="victaulic-logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 217.7 62.9"
        width={width}
    >
        <path
            d="M87.9 41.1c.1.1.4.2.4.2h5.3l.2-.4V37l-.3-.4s-13.2-5.3-21.1.6c-.1.1-.2.6-.2.6v11.1s.5.7.4.7c8.7 4.7 21-.5 21-.5l.3-.6v-3.7l-.5-.4h-5s-.4 0-.4.2c-.4 1.6-5.4 5.9-9.6 1.3-2.1-2.3-.5-5.3.1-6.1.6-1 6-4.9 9.4 1.3zm118.7.1c.1.1.4.2.4.2h5.3l.2-.4v-3.9l-.3-.4s-13.2-5.3-21.1.6c-.1.1-.2.6-.2.6V49l.4.7c8.7 4.7 21-.5 21-.5l.3-.6v-3.8l-.5-.4h-5s-.4 0-.4.2c-.4 1.6-5.4 5.9-9.6 1.3-2.1-2.3-.5-5.3.1-6.1.6-.8 6.1-4.7 9.4 1.4zM62.7 39.9l.4-.4h4.8l.6.5v10.9l-.5.3h-4.9l-.4-.6V39.9zm-.2-5l.3-.4h5.1l.5.3V37l-.3.4h-5.2l-.4-.3v-2.2zm118.6 4.9l.4-.4h4.8l.6.5v10.9l-.5.4h-4.9l-.4-.6V39.8zm-.1-5l.3-.4h5.2l.4.5v2l-.4.4h-5l-.6-.4v-2.1h.1zm-82.1-3.3l.5-.4h5l.4.3v4.3l.4.4h4.1l.4.3v2.2l-.2.5H105l-.2.2v7.5c-.1 1.2 2.1 2 2.2.1.1-.6.1-1.6.1-1.6l.3-.3h5.1l.2.4V48c-.1 1.7-3.4 3.8-7.5 3.5-6.6-.4-6.3-2.7-6.3-2.7v-9.5l-.3-.3h-2.3l-.3-.1v-2.5l.3-.3h2.5l.2-.4-.1-4.2zM131.3 43c0-.7 0-2.4-.9-3.5-1.6-2-6.8-2.7-8.5 1.8 0 0-.2.2-.4.2h-6.1l-.3-.4v-3.2l.2-.3s10.2-7 23.6 1.1c.3.2 0 12.7 0 12.7l-.3.3h-3.8l-.8-.6-.2-1.1s-11.6 4.3-18.6-1.3c-.3-.2 0-3.1 0-3.1S121 40 131 43.5c0 0 .3-.1.3-.5zm-1.2 3.7c0-.9-2-1.6-4.4-1.6s-4.4.7-4.4 1.6 2 1.6 4.4 1.6 4.4-.7 4.4-1.6zM160 35.5l.3-.3h5.4l.2.2v15.4l-.3.2h-4.4l-.8-.5-.5-1.1s-6.1 4.3-16 .3c-.3-.1-.7-.4-.9-1-.1-.6 0-13.2 0-13.2l.3-.3h5.1l.5.2s.1 9.1 0 9.7c0 0 0 .9.5 1.4 1.5 1.7 7.3 3.1 10.2-.5l.4-.8c.1-.4 0-9.7 0-9.7zm10-6.8l.3-.4h6.2l.3.3v22.3l-.3.2h-6.3l-.3-.3V28.7h.1zm44.9 2.5c-1.4 0-2.6 1.2-2.6 2.6s1.1 2.6 2.6 2.6c1.4 0 2.6-1.2 2.6-2.6s-1.2-2.6-2.6-2.6zm-.6 1.4h1c.2 0 .5 0 .5.5 0 .4-.3.5-.6.5h-.9v-1zm0 1.4h.9c.5 0 .5.3.5.5 0 .1 0 .5.1.7h.5v-.1c-.1-.1-.1-.1-.1-.3v-.5c0-.4-.2-.5-.3-.5.2-.1.4-.3.4-.7 0-.6-.5-.8-.9-.8h-1.3v2.9h.4l-.2-1.2zm-2.4 28.8H47.1l3.7-4.2H212v4.2h-.1zM.4 34h8.8l.9.4 17.5 22.7s3.2 3.4 6.4 0c3.2-3.5 39.6-56 39.6-56L74.7 0h8.9l.2.8L40 62.6l-.8.3H22.5l-.6-.3L0 34.5l.4-.5z"
            fill="#ffffff"
        />
    </svg>
);

export default VictaulicSVG;
