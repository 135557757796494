import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import Toggle from 'react-toggle';

import '../../../assets/css/app.css';
import '../../../assets/css/react-toggle.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class CopperExpansionContraction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            units: localStorage.getItem('units') !== undefined ? (localStorage.getItem('units') === 'true') : true, // TRUE --> IMPERIAL || FALSE --> METRIC
            tableOptions: [
                {
                    label: 'Thermal Expansion',
                    value: 'thermal_expansion',
                },
                {
                    label: 'Expansion Loop Lengths',
                    value: 'loop_lengths',
                },
            ],
            selectedTableType: [],
            // THERMAL EXPANSION
            getSectionDataUrlExpansion: process.env.REACT_APP_API_URL + 'get/section?table=copper_thermal_expansion',
            temperatureOptions: [],
            selectedTemperature: [],
            resultsExpansion: {
                expansionContractionInches: '',
                expansionContractionMM: ''
            },
            // LOOP LENGTH
            getSectionDataUrlLoop: process.env.REACT_APP_API_URL + 'get/section?table=copper_expansion_loop_lengths',
            expansionOptions: {
                inches: [],
                mm: []
            },
            selectedExpansion: [],
            resultsLoopLength : {
                loopLength2Inches: '',
                loopLength50mm: '',
                loopLength2_5Inches: '',
                loopLength65mm: '',
                loopLength3Inches: '',
                loopLength80mm: '',
                loopLength4Inches: '',
                loopLength100mm: '',
                loopLength5Inches: '',
                loopLength125mm: '',
                loopLength6Inches: '',
                loopLength150mm: '',
                loopLength8Inches: '',
                loopLength200mm: '',
            }
        };
    }

    // BOTH OF THEM
    getData = async () => {
        // EXPANSION
        axios.get(this.state.getSectionDataUrlExpansion)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_thermal_expansion', JSON.stringify(response.data.data));
            })
            .catch(console.log);
        // LOOP LENGTH
        axios.get(this.state.getSectionDataUrlLoop)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_expansion_loop_lengths', JSON.stringify(response.data.data));
            })
            .catch(console.log);

    }
    getAllData = async () => {
        await axios.all([
            this.getData(), 
            this.getTemperatures(),
            this.getExpansion('inches'),
            this.getExpansion('mm'),
            ])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                });
            }))
            .finally(() => {
                this._isMounted = true;
            });
    }
    componentDidMount() {
        this.getAllData();
    }
    setTableType = (n) => {
        if (n !== null) {
            this.setState({
                selectedTableType: { 'value': n.value, 'label': n.label },
            });
        } else {
            console.log('No Table Type');
        }
    }

    /* - - - - - - - - - - - - - - - - - - - - - - - - - -
    /* THERMAL EXPANSION
    */
    getTemperatures = async (units) => {
        axios.get(this.state.getSectionDataUrlExpansion + '&select=temperature_f|temperature_c&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_thermal_expansion_temperature', JSON.stringify(response.data.data));
                let temperatures = [];
                response.data.data.map((value, key) => {
                    // console.log(response.data.data);

                    if (value.temperature_f || value.temperature_c) {
                        temperatures.push({
                            'value': value.temperature_f,
                            'label': value.temperature_f + ReactHtmlParser(this.props.strings.DEGREES_F) + ' / ' + value.temperature_c + ReactHtmlParser(this.props.strings.DEGREES_C)
                        });
                    }
                    return true;
                });
                this.setState({ temperatureOptions: { options: temperatures } });
            })
            .catch(console.log);
    }
    setTemperature = (n) => {
        if (n !== null) {
            this.setState({
                selectedTemperature: { 'value': n.value, 'label': n.label },
            }, () => {
                let filter = {'temperature_f': this.state.selectedTemperature.value }
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_thermal_expansion')),
                    filter
                );
                this.setResultsExpansion(data);
            });
        } else {
            this.resetResultsExpansion();
        }
    }
    resetResultsExpansion = () => {
        this.setState({
            resultsExpansion: {
                expansionContractionInches: '',
                expansionContractionMM: '',
            },
        })
    }
    setResultsExpansion = (data) => {
        if (Object.entries(this.state.selectedTemperature).length > 0) {
            this.setState({
                resultsExpansion: {
                    expansionContractionInches: data.expansion_contraction_inches,
                    expansionContractionMM: data.expansion_contraction_mm,
                },
            });
        } else {
            this.resetResultsExpansion();
        }
    }

    /* - - - - - - - - - - - - - - - - - - - - - - - - - -
    /* EXPANSION LOOP LENGTHS
    */
   getExpansion = async (units) => {
       axios.get(this.state.getSectionDataUrlLoop + '&select=expansion_' + units + '&distinct=true')
           .then((response) => {
               localStorage.setItem(this.props.sectionId + '_copper_expansion_loop_lengths_expansion_' + units, JSON.stringify(response.data.data));
               let expansions = [];
               response.data.data.map((value, key) => {
                   if (value.expansion_inches || value.expansion_mm) {
                       expansions.push({
                           'value': (units === 'inches' ? value.expansion_inches : value.expansion_mm),
                           'label': (units === 'inches' ? value.expansion_inches : value.expansion_mm)
                       });
                   }
                   return true;
               });
               units === 'inches'
                   ? this.setState({ expansionOptions: { inches: expansions, mm: this.state.expansionOptions.mm } })
                   : this.setState({ expansionOptions: { inches: this.state.expansionOptions.inches, mm: expansions } });
           })
           .catch(console.log);
   }
   setExpansion = (n) => {
       if (n !== null) {
           this.setState({
               selectedExpansion: { 'value': n.value, 'label': n.label },
           }, () => {
               let filter = (this.state.units === true
                    ? { 'expansion_inches': this.state.selectedExpansion.value }
                    : { 'expansion_mm': this.state.selectedExpansion.value }
                );
               let data = _.find(
                   JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_expansion_loop_lengths')),
                   filter
               );
               this.setResultsLoopLength(data);
           });
           // console.log(this.state.selectedExpansion);
       } else {
           this.resetResultsLoopLength();
       }
   }
   changeUnits = (e) => {
        this.setState({
            units: e.target.checked,
        });
        this.setState({ selectedExpansion: null });
        this.resetResultsLoopLength();
        localStorage.setItem('units', e.target.checked);
    }
    resetResultsLoopLength = () => {
        this.setState({
            resultsLoopLength: {
                loopLength2Inches: '',
                loopLength50mm: '',
                loopLength2_5Inches: '',
                loopLength65mm: '',
                loopLength3Inches: '',
                loopLength80mm: '',
                loopLength4Inches: '',
                loopLength100mm: '',
                loopLength5Inches: '',
                loopLength125mm: '',
                loopLength6Inches: '',
                loopLength150mm: '',
                loopLength8Inches: '',
                loopLength200mm: '',
            },
        })
    }
    setResultsLoopLength = (data) => {
        if (Object.entries(this.state.selectedExpansion).length > 0) {
            this.setState({
                resultsLoopLength: {
                    loopLength2Inches: data.loop_length_l_2_inches,
                    loopLength50mm: data.loop_length_l_50_mm,
                    loopLength2_5Inches: data.loop_length_l_2_5_inches,
                    loopLength65mm: data.loop_length_l_65_mm,
                    loopLength3Inches: data.loop_length_l_3_inches,
                    loopLength80mm: data.loop_length_l_80_mm,
                    loopLength4Inches: data.loop_length_l_4_inches,
                    loopLength100mm: data.loop_length_l_100_mm,
                    loopLength5Inches: data.loop_length_l_5_inches,
                    loopLength125mm: data.loop_length_l_125_mm,
                    loopLength6Inches: data.loop_length_l_6_inches,
                    loopLength150mm: data.loop_length_l_150_mm,
                    loopLength8Inches: data.loop_length_l_8_inches,
                    loopLength200mm: data.loop_length_l_200_mm,
                },
            });
            // console.log(this.state.resultsLoopLength);
        } else {
            this.resetResultsLoopLength();
        }
    }

    /* - - - - - - - - - - - - - - - - - - - - - - - - - -
    /* RENDER TIME
    */
    render() {
        const resultsExpansion = this.state.resultsExpansion;
        const resultsLoopLength = this.state.resultsLoopLength;
        const strings = this.props.strings;
        return (
            <Grid>
                {/* SELECT A TOOL TYPE */}
                <Row className="extra-padding">
                    <Col xs={12}>
                        <Label>{strings.TOOL_TYPE}</Label>
                        <Select
                            options={this.state.tableOptions}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_A_TOOL_TYPE}
                            className="input-filter"
                            isClearable={false}
                            onChange={this.setTableType}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>
                {/* THERMAL EXPANSION */}
                <Row className={this.state.selectedTableType.value === 'thermal_expansion' ? "" : "hide"}>
                    <Col xs={12}>
                        <SectionIntro>
                            {ReactHtmlParser(strings.I_600_SECTION_4_INTRO)}
                        </SectionIntro>
                    </Col>    
                </Row>

                <Row className={this.state.selectedTableType.value === 'thermal_expansion' ? "" : "hide"}>
                    <Col xs={12}>
                        <SelectionHeader>
                            {strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'thermal_expansion' ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{ReactHtmlParser(strings.TEMPERATURE)}</Label>
                        <Select
                            options={this.state.temperatureOptions.options}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_TEMPERATURE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setTemperature}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'thermal_expansion' ? "extra-padding" : "hide"}>
                    <Col xs={12}>
                        <Label>{strings.EXPANSION_CONTRACTION_COPPER} ({strings.INCHES} / {strings.MM})</Label>
                        <Input type="text" placeholder="&mdash;" id="expansion_contraction_inches" value={resultsExpansion.expansionContractionInches + ' ' + strings.INCHES_SHORT + ' / ' + resultsExpansion.expansionContractionMM + ' ' + strings.MM} readOnly="readonly" />
                    </Col>
                </Row>

                {/* EXPANSION LOOP LENGTHS */}
                <Row className={this.state.selectedTableType.value === 'loop_lengths' ? "" : "hide"}>
                    <Col xs={12}>
                        <SelectionHeader>
                            {strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'loop_lengths' ? "extra-padding" : "hide"}>
                    <Col xs={6}>
                        <Label>{strings.UNITS}</Label>
                    </Col>
                    <Col xs={6} className="toggle-container">
                        <label>
                            <Toggle
                                defaultChecked={this.state.units}
                                className="victaulic-toggle"
                                icons={false}
                                onChange={this.changeUnits} />
                            <span className="victaulic-toggle-label">
                                {(this.state.units === true ? strings.IMPERIAL : strings.METRIC)}
                            </span>
                        </label>
                    </Col>
                </Row>
                
                <Row className={this.state.selectedTableType.value === 'loop_lengths' ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{strings.EXPANSION} ({(this.state.units === true ? this.props.strings.INCHES : this.props.strings.MM)})</Label>
                        <Select
                            options={(this.state.units === true ? this.state.expansionOptions.inches : this.state.expansionOptions.mm)}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_EXPANSION}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setExpansion}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'loop_lengths' ? "" : "hide"}>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.LOOP_LENGTH_L_TITLE} ({(this.state.units === true ? this.props.strings.INCHES : this.props.strings.MM)})
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'loop_lengths' ? "" : "hide"}>
                    {/* IN */}
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>2 {strings.INCHES}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_2_inches" 
                            value={resultsLoopLength.loopLength2Inches || ''} 
                            readOnly="readonly" 
                        />
                    </Col>

                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>2.5 {strings.INCHES}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_2_5_inches" 
                            value={resultsLoopLength.loopLength2_5Inches || ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                    {/* MM */}
                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>50 {strings.MM}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_50_mm" 
                            value={resultsLoopLength.loopLength50mm || ''} 
                            readOnly="readonly" 
                        />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>65 {strings.MM}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_65_mm" 
                            value={resultsLoopLength.loopLength65mm || ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'loop_lengths' ? "" : "hide"}>
                    {/* IN */}
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>3 {strings.INCHES}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_3_inches" 
                            value={resultsLoopLength.loopLength3Inches || ''} 
                            readOnly="readonly" 
                        />
                    </Col>

                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>4 {strings.INCHES}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_4_inches" 
                            value={resultsLoopLength.loopLength4Inches || ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                    {/* MM */}
                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>80 {strings.MM}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_80_mm" 
                            value={resultsLoopLength.loopLength80mm || ''} 
                            readOnly="readonly" 
                        />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>100 {strings.MM}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_100_mm" 
                            value={resultsLoopLength.loopLength100mm || ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'loop_lengths' ? "" : "hide"}>
                    {/* IN */}
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>5 {strings.INCHES}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_5_inches" 
                            value={resultsLoopLength.loopLength5Inches || ''} 
                            readOnly="readonly" 
                        />
                    </Col>

                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>6 {strings.INCHES}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_6_inches" 
                            value={resultsLoopLength.loopLength6Inches || ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                    {/* MM */}
                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>125 {strings.MM}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_125_mm" 
                            value={resultsLoopLength.loopLength125mm || ''} 
                            readOnly="readonly" 
                        />
                    </Col>

                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>150 {strings.MM}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_150_mm" 
                            value={resultsLoopLength.loopLength150mm || ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'loop_lengths' ? "" : "hide"}>
                    {/* IN */}
                    <Col xs={6} className={this.state.units === false ? "hide" : ""}>
                        <Label>8 {strings.INCHES}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_8_inches" 
                            value={resultsLoopLength.loopLength8Inches || ''} 
                            readOnly="readonly" 
                        />
                    </Col>

                    {/* MM */}
                    <Col xs={6} className={this.state.units === true ? "hide" : ""}>
                        <Label>200 {strings.MM}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="loop_length_l_200_mm" 
                            value={resultsLoopLength.loopLength200mm || ''} 
                            readOnly="readonly" 
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedTableType.value === 'loop_lengths' ? "extra-padding" : "hide"}>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(strings.I_600_SECTION_4_LOOP_FOOTER)}
                        </Subtext>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

const SectionIntro = styled.p`
        border-bottom: 0.5px solid #efefef;
        padding-bottom: 15px;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default CopperExpansionContraction;