import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const IconContainer = styled.div`
        position: relative;
        z-index: 99999;
        &:focus {
            outline: none;
        }
    `,
    IconLine = styled.div`
        background-color: ${props => props.backgroundColor || '#000000'}
        height: 5px;
        margin: 8px 0;
        transition: 0.3s;
        width: ${props => props.width || '35px'};
    `;

const MenuIcon = ({ open, toggleNavMenu }) => {
    return (
        <IconContainer onClick={toggleNavMenu}>
            <IconLine
                backgroundColor="#f09b28"
                style={{
                    transform: open
                        ? 'rotate(-45deg) translate(-9px, 6px)'
                        : 'none',
                    background: open
                        ? "#ffffff"
                        : "#f09b28"
                }}
            />
            <IconLine
                backgroundColor="#ffffff"
                style={{ opacity: open ? 0 : 1 }}
                width="45px"
            />
            <IconLine
                backgroundColor="#f09b28"
                style={{
                    transform: open
                        ? 'rotate(45deg) translate(-11px, -10px)'
                        : 'none',
                    background: open
                        ? "#ffffff"
                        : "#f09b28"
                }}
                width={open ? '35px' : '15px'}
            />
        </IconContainer>
    );
};

MenuIcon.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleNavMenu: PropTypes.func.isRequired
};

export default MenuIcon;
