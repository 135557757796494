import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class HdpeGasketsLubrication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=hdpe_lubricant_compatibility',
            lubricantOptions: [],
            selectedLubricant: [],
            results: {
                gradeEepdmGaskets: '',
                gradeEFepdmGaskets: '',
                gradeOFluroelastomerGaskets: '',
                gradeTNitrileGaskets: '',
            },
        };
    }

    getLubricants = async (units) => {
        axios.get(this.state.getSectionDataUrl + '&select=lubricant&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hdpe_lubricant_compatibility_lubricant', JSON.stringify(response.data.data));
                let lubricant = [];
                response.data.data.map((value, key) => {

                    if (value.lubricant) {
                        lubricant.push({
                            'value': value.lubricant,
                            'label': value.lubricant
                        });
                    }
                    return true;
                });
                this.setState({ lubricantOptions: { options: lubricant } })
            })
            .catch(console.log);
    }

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_hdpe_lubricant_compatibility', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    }

    getAllData = async () => {
        await axios.all([this.getData(), this.getLubricants()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                });
            }))
            .finally(() => {
                this._isMounted = true;
            });
    }

    componentDidMount() {
        this.getAllData();
    }

    setLubricant = (n) => {
        if (n !== null) {
            this.setState({
                selectedLubricant: { 'value': n.value, 'label': n.label },
            }, () => {
                let filter = {'lubricant': this.state.selectedLubricant.value };
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_hdpe_lubricant_compatibility')),
                    filter
                );
                this.setResults(data);
            });
        } else {
            this.resetResults();
        }
    }

    resetResults = () => {
        this.setState({
            results: {
                gradeEepdmGaskets: '',
                gradeEFepdmGaskets: '',
                gradeOFluroelastomerGaskets: '',
                gradeTNitrileGaskets: '',
            },
        })
    }

    setResults = (data) => {
        if (Object.entries(this.state.selectedLubricant).length > 0) {
            this.setState({
                results: {
                    gradeEepdmGaskets: data.grade_e_epdm_gaskets,
                    gradeEFepdmGaskets: data.grade_ef_epdm_gaskets,
                    gradeOFluroelastomerGaskets: data.grade_o_fluoroelastomer_gaskets,
                    gradeTNitrileGaskets: data.grade_t_nitrile_gaskets,
                },
            });
        } else {
            this.resetResults();
        }
    }

    render() {
        const strings = this.props.strings;
        const results = this.state.results;
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{strings.LUBRICANT}</Label>
                        <Select
                            options={this.state.lubricantOptions.options}
                            getOptionValue={option => option.value}
                            placeholder={strings.SELECT_LUBRICANT}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setLubricant}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {strings.COMPATIBILITY_WITH} {strings.GASKET_GRADE}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row className="extra-padding">
                    <Col xs={12}>
                        <Label>{strings.GRADE_E} {strings.EPDM_GASKETS}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="grade_e_epdm_gaskets" 
                            value={results.gradeEepdmGaskets} 
                            readOnly="readonly" />
                    </Col>
                </Row>

                <Row className="extra-padding">
                    <Col xs={12}>
                        <Label>{strings.GRADE_EF} {strings.EPDM_GASKETS}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="grade_ef_epdm_gaskets" 
                            value={results.gradeEFepdmGaskets} 
                            readOnly="readonly" />
                    </Col>
                </Row>

                <Row className="extra-padding">
                    <Col xs={12}>
                        <Label>{strings.GRADE_O} {strings.FLUOROELASTOMER_GASKETS}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="grade_o_fluoroelastomer_gaskets" 
                            value={results.gradeOFluroelastomerGaskets} 
                            readOnly="readonly" />
                    </Col>
                </Row>

                <Row className="extra-padding">
                    <Col xs={12}>
                        <Label>{strings.GRADE_T} ({strings.NITRILE_GASKETS})</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            id="grade_t_nitrile_gaskets" 
                            value={results.gradeTNitrileGaskets} 
                            readOnly="readonly" />
                    </Col>
                </Row>

            </Grid>
        );
    }
}

const SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default HdpeGasketsLubrication;