import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Accordion from './Accordion';
import Language from '../Language';
import VictaulicSVG from './VictaulicSVG';
import '../../assets/css/navmenu.css';

const handleChange = (event) => {
    Language.setLanguage(event.target.value);
};

const NavMenu = ({ open, toggleNavMenu, languages, strings, handbooks }) => {
    const date = new Date();
    const year = date.getFullYear();
    const handbooksArr = Object.entries(handbooks);
    const languagesArr = Object.entries(languages);

    return (
        <NavMenuContainer open={open}>
            <NavMenuHeaderContainer>
                <SVGLinkContainer href="https://victaulic.com">
                    <VictaulicSVG width="122px" />
                </SVGLinkContainer>
            </NavMenuHeaderContainer>
            <Accordion>
                {
                    handbooksArr.map((handbook, key) =>
                        <NavHandbook
                            label={handbook[1].handbook.code}
                            key={handbook[1].handbook.id}
                            active={handbook[1].handbook.active}
                            className={(handbook[1].handbook.active === 1 ? "active " : "inactive ") + handbook[1].handbook.code}
                        >
                            <NavSubMenuItem key={handbook[1].handbook.code}>
                                <Link to={{
                                    pathname: `${process.env.REACT_APP_PUBLIC_URL}guide/${handbook[1].handbook.id}`,
                                    state: { languages: languages, strings: strings }
                                }} className="nav-item" onClick={toggleNavMenu}>{handbook[1].handbook.code + ' ' + strings.HOME}</Link>
                            </NavSubMenuItem>
                            {
                                handbook[1].sections.map((section, index) => {
                                    return <NavSubMenuItem key={section.id}>
                                        <Link to={{
                                            pathname: `${process.env.REACT_APP_PUBLIC_URL}section/${handbook[1].handbook.id}/${section.id}`,
                                            state: { languages: languages, strings: strings }
                                        }} className="nav-item" onClick={toggleNavMenu}>{section.string}</Link>
                                    </NavSubMenuItem>
                                })
                            }
                        </NavHandbook>
                    )
                }
            </Accordion>
            <NavMenuFooterContainer>
                <LanguageContainer>
                    {strings.LANGUAGE_PREFERENCE}<br />
                    <img alt="" src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAC3UlEQVRYhc1Yz2tTQRD+8pogGFHQgpeQU/FgKEopnrSWQhH8iXfBiuSsUPAvKBRyqpCDlFoVTz0W9aCHUooH7UEQb40HLR4qtKFKDZhaRlZm4WPYl5cnJLwPFib7zc5M3syb3X05EUEKHAJwCcAEgBEApwAMAhgAcABgG8AGgA8AVgC8BvC7a/MumC5GWUTqIrIr6bCr68rd+ElSKIpITUTaKYOwaKud4v8Gc1ZEGglOdhJ+WzTUbqpgLotIyxh6JyKzZu5mwu9ZXcdoqf2ugnGKnJY9EamKiCv2NZpfUn0GdN5jTddV1Y5HOxRQKDX8RL6KSEW5UTK2LyJDMcEMKe8xqvOn1Z5Hy6bMFivXyKaIlIhfJO45zdtgoLzHAs2XTEANLmoOpmZSUyGuICJN4scSghmjuaau91zFpKxmgymb17dq0jdJ3Betg07B5FTPY9LYqxLX9n0o0t73AEBB5XUAC6Y3XiT5jeuVSb1U9ULrofbXVS6of+S1xd8ixU8AbpvFV0l2f2AqJogpo8frPxtd5+ecys7/tHuc1wEsx//JvuFGpJteFjCR193X45XuvIwTlKYmgBeG55Q+M9w1AMdVfglgx/Bux7+i8oir7C2q7JKpejfGiV8N8KG3yY9V4sYDfIn4rUij8/gWSNcRkn+mTCfrFwM8+xuM9GDk8CvGYJ7kVspgWL8Qo+P9DkR6QkNM5A77JB9OGQzr/4nR8X4PIlOwpYAyP7GjKYNh/b0Az/6283pmPakTj2LeJo9hAE87OLfcMMnTgWbJ9brhmt4cgHsdHPQLDyM9xWcBK+7JuL3pO4BjGtBjAG9NcPcBnInhn5B8h+TzAO6q/BHAnLHJ/I9/paLNp07N5705IrgxQ/x8l01vnuZnDJdTPx71zJ1nenXSu0BzqU96vTwDL5q9aJO42DNwr28HlTS3Az8yc2/igDJxo+SUZeKuzUWdia8QPPryfSY7X64A/AUFNOLOb6YP1gAAAABJRU5ErkJggg==" />
                    <LanguageSelect onChange={handleChange}>
                        {
                            languagesArr.map((language, index) =>
                                <option value={language[1].code} disabled={language[1].active === 0 ? 'disabled' : ''} key={language[1].id}>
                                    {language[1].name}
                                </option>
                            )
                        }
                    </LanguageSelect>
                </LanguageContainer>
                <Copyright>
                    &copy; {year} Victaulic. {strings.ALL_RIGHTS_RESERVED}. <a target="_blank"
                        href={strings.TERMS_OF_USE_URL}
                        rel="noopener noreferrer"
                    >
                        {strings.TERMS_OF_USE}
                    </a>
                </Copyright>
            </NavMenuFooterContainer>
        </NavMenuContainer>
    );
};

NavMenu.propTypes = {
    open: PropTypes.bool.isRequired
};

const NavMenuContainer = styled.div`
        background-color: #333333;
        font-family: 'Rajdhani', sans-serif;
        height: 100%;
        left: 0;
        overflow-x: hidden;
        position: fixed;
        top: 0px;
        width: ${props => (props.open ? '100%' : '0')};
        transition: 0.3s;
        z-index: 9;
    `,
    NavHandbook = styled.div`
    `,
    NavSubMenuItem = styled.div`
        font-family: 'Open Sans', sans-serif;
        background-color: ${props => props.backgroundColor || 'transparent'}
        border-bottom: none;
        padding: 0;
        color: #ffffff;
        display: block;
        font-size: ${props => (props.site ? '24px' : '18px')};
        font-weight: ${props => props.fontWeight || '100'}
        padding: ${props => (props.site ? '10px 0' : '5px 0')};
        text-align: center;
        transition: 0.3s;
        a {
            font-size: 16px
        }
    `,
    NavMenuHeaderContainer = styled.footer`
        color: white;
        text-align: center;
        font-size: 16px;
        padding: 0 10px;
        height: 35px;
        margin: 5px 0 20px;
    `,
    SVGLinkContainer = styled.a`
        display: block;
        margin-bottom: 5px;
        text-decoration: none;
    `,
    NavMenuFooterContainer = styled.footer`
        color: #ffffff;
        background: #333333;
        text-align: center;
        font-size: 14px;
        padding: 0;
        margin: 40px 0 70px;
        font-family: 'Open Sans', sans-serif;
    `,
    LanguageContainer = styled.div`
        font-size: 16px;
        img {
            height: 20px;
            width: 20px;
            margin: 0 5px 0 0;
            vertical-align: middle;
        }
        select {
            vertical-align: middle;
            font-size: 16px;
            color: #ffffff;
            font-weight: bold;
            option {

            }
        }
    `,
    LanguageSelect = styled.select`
        background: transparent;
        border: 0px;
    `,
    Copyright = styled.div`
        position: relative;
        bottom: 0;
        padding-bottom: 5px;
        margin: 40px auto 0;
        width: 100%;
        text-align: center;

        a {
            color: #ffffff;
            text-decoration: none;

            &:visited {
                color: #ffffff;
                text-decoration: none;
            }
        }
    `;

export default NavMenu;
