import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import Terms from './Terms';
import HomeLayout from './shared/HomeLayout';

class Language extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: JSON.parse(localStorage.getItem('languages')),
            strings: JSON.parse(localStorage.getItem('strings')),
            langSet: localStorage.getItem('language'),
            termsSet: localStorage.getItem('terms'),
            next: process.env.REACT_APP_PUBLIC_URL,
            getLanguageUrl: process.env.REACT_APP_API_URL + 'language',
        };
        this.deferredPrompt = props.deferredPrompt;
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getStrings = () => {
        // Retrieve the strings model from the API (default to en)
        axios.get(this.state.getLanguageUrl + '/' + this.state.langSet)
            .then((response) => {
                localStorage.setItem('strings', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    }

    setLanguage = (lang) => {
        localStorage.setItem('language', lang);
        this.setState({ langSet: lang }, () => {
            this.getStrings()
        });
    };

    render() {
        if (this.state.langSet === 'en') return (
            <Terms deferredPrompt={this.deferredPrompt} />
        );

        else return (
            <main>
                <HomeLayout language={this.state.languages} strings={this.state.strings} />
                <LanguageContainer>
                    <Heading>
                        <img alt="" src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAEI0lEQVRYhc2YT4hVdRTHP/N8w6OMJBPajNPmEQ6DNBgUpZK5MLKyjIwWQkbNTGqQIrppES0E0UXjonC00GAWkYETmWELSyaLhGJCbEDepsmFgn8wKn28UePI9/c4Hu69b55E+YVhzu+ev/f3O/ec83sdV3bNow1UgCeBpcAC4AFgDjADuAqcA04BPwNHgMNAvch8ZXCiSZenGUc3sAVYDczKkbGA7tPfYuAt4BIwAmwHJls5KbXgz5ShGrC+IJA8zJJeTXZm3mowfcA4sBnozJG50GKd0Ck747LbVjDLge+Bqnv2I7AtyL3eYr1NeglV2V0+3WBMcBS4Q+u/gAHgUWChk/sUOBB0D+h5wkLpDcgOsjuaFVAMxrbwM3cslnSPAHuAh5SYhing7ay30/Mp0YulZ/oPuyTulJ+bjswHY8m13+3I73qzk1qvc7KfKCmzUBM/4Q39/1X2UkDmZ399uKeZ1D6Yd1yO2JY+BZx2b/K8k92TE0gW/wW306d1POnIqvJ7UzBWRzY4AxvdjhiWAPeI/g0YaxHMmOSQ3hLHOyn7CRvqwz3dPpgtLvrjwIfB+OOO/hq43iKY65LL0kf2j4vulP8bFbiiyppwAnglKD/jaHuBNTlBrAlyXj/m2AkltWF1fbhnk/WmFcDnhe/53+C5kpre7YClZXXfhC/VeT3udcdk5f6LwPdH+nHgPQvMFn0QOB/41vGfFr3AjumMOq1hrvucE+xL+Eb00fBlEJK5I/C+dcn7hNYeXapnhrMlRZcQAzHc5eg/MvhF8PJZHdv7m1PSHIIrRBF+5vm7zWC8fF7nT35nlDShUTBrNBx9Z5vBePmpHJnk92opJGxXhrDfsbvbDMbL/5nB9/7OlTWzpgTelfM1JcwH9hU4j7z5jt6UUSx9vp6yr2lI8+r/jZ0lTfG3A46UdZ245Ibtj4DvQnDW0R/M4e919KuOXgS8JvoXYCjY9Hzzf7ise82Ipvh0zv2hmFVdMNdCbvhg/PPHHH0w8Kw4rnXrkcrgRD111u3uE7ZOGgfro45ellFpIzokl6WPBvnUsRvy32zzk2Eb3wN63drK+EXR97tZOA+LJIf0fBvolf2EocrgxCRh5njXzRxWiL5ydaChiT6hv0UwA44edbveJbup0NXklxiMFbdVwGWtrWkeczv0gZN9OdypPKriE/R6ZW+u1uZnVWVwollU41XFbnwvujfp1iXMduInN/ta4m/NCWar62dj0uuXnW49b8jPuFfMusQd0k0g7ZBt6W7gB71ZwkvAyqC7Us8Tjklvtzuay7J/KDou+kmkT/eovONAw9bsgnVETanQ3BH/k0jRxT9d0neEzu0RHecF0pCdvng0Hq1+ErHksmuE7c77qpTtwORNz/TNTt7MdAPT/bHI6sCb6rz/6i9XTQD/AGaD804uUlHmAAAAAElFTkSuQmCC" />
                        Select Your Language
                    </Heading>
                    <LanguageContent>
                        {
                            this.state.languages.map((language, index) =>
                                <button
                                    onClick={() => this.setLanguage(language.code)}
                                    className={language.active === 0 ? 'disabled' : ''}
                                    key={index}>
                                    {language.name} <ArrowContainer><Arrow className="icon-back" /></ArrowContainer>
                                </button>
                            )
                        }
                    </LanguageContent>
                </LanguageContainer>
            </main>
        );
    };
};

const Heading = styled.h1`
        width: 100%;
        margin: 0;
        color #ffffff;
        text-align: center;
        text-transform: uppercase;
        font-size: 24px;
        font-family: Rajdhani, sans-serif;
        position: absolute;
        top: -50px;

        img {
            height: 20px;
            width: 20px;
            margin: 0 5px 0 0;
            vertical-align: middle;
        }
    `,
    LanguageContainer = styled.div`
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        left: 0;
        position: relative;
        width: 100%;
        margin: 45% 3% 0 3%;

        width: 94%;
        background-color: #ffffff;
    `,
    LanguageContent = styled.div`
        width: 100%;
        margin: 0 0 0 0;
        text-align: center;
        font-weight: bold;

        button {
            font-weight: bold;
            color: #000000;
            display: block;
            clear: both;
            border: none;
            border-bottom: 0.5px solid #efefef;
            padding: 15px 0 0;
            width: 100%;
            background: #ffffff;

            font-family: 'Open Sans', sans-serif;
            font-size: 15px;

            &.disabled {
                color: #aaaaaa;
                pointer-events: none;
            }
        }
    `,
    ArrowContainer = styled.span`
        right: 15px;
        position: relative;
        z-index: 2;
        top: -8px;
        transform: rotate(180deg);
        float: right;
        color: #f89828;
    `,
    Arrow = styled.i`
        font-size: 26px;
        position: relative;
    `;

export default Language;