import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';

import MenuIcon from './MenuIcon';
import NavMenu from './NavMenu';

import Logo from '../../assets/victaulic.png';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navMenu: false,
            handbooks: JSON.parse(localStorage.getItem('handbooks')),
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleNavMenu = () => {
        this.setState({ navMenu: !this.state.navMenu });
    };

    render() {

        const { navMenu } = this.state;

        return (
            <NavigationContainer>
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <NavigationItemContainer>
                                <li>
                                    <MenuIcon
                                        open={navMenu}
                                        toggleNavMenu={
                                            this.toggleNavMenu
                                        }
                                    />
                                </li>
                                <li>
                                    <VictaulicLogo
                                        src={Logo}
                                        alt="logo"
                                    />
                                </li>
                            </NavigationItemContainer>
                        </Col>
                    </Row>
                </Grid>
                <NavMenu handbooks={this.state.handbooks} languages={this.props.languages} strings={this.props.strings} open={navMenu} toggleNavMenu={this.toggleNavMenu} />
            </NavigationContainer>
        );
    }
}

const NavigationContainer = styled.nav`
        background-color: #333333;
        padding: 0 15px;
        top: 0;
        width: 100%;
    `,
    NavigationItemContainer = styled.ul`
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 60px;
        justify-content: space-between;
        list-style-type: none;
        margin: 0;
        padding: 0;
    `,
    VictaulicLogo = styled.img`
        height: 35px;
        position: relative;
        right: 25px;
        top: 45%;
    `;

export default Navigation;
