import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class PipePreparation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedNominalSize: '',
            selectedOutsideDiameter: '',
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=pipe_lengths_for_grooving',
            nominalSize: [],
            outsideDiameter: [],
            results: {
                minimumLength: '',
                maximumLength: '',
            },
        };
    }

    setNominalSizeOptions = (response) => {
        let nominalSize = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_nominal_size'));
        data.map((value, key) => {
            nominalSize.push({ 'value': value.nominal_size, 'label': value.nominal_size });
            return true;
        });
        this.setState({
            nominalSizeOptions: nominalSize
        });
    };

    getNominalSize = async () => {
        // Retrieve the nominal_size for dropdown from the API
        axios.get(this.state.getSectionDataUrl + '&select=nominal_size')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_nominal_size', JSON.stringify(response.data.data));
                this.setNominalSizeOptions();
            })
            .catch(console.log);
    };

    setOutsideDiameterOptions = () => {
        let outsideDiameter = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_outside_diameter'));
        data.map((value, key) => {
            outsideDiameter.push({ 'value': value.outside_diameter, 'label': value.outside_diameter });
            return true;
        });
        this.setState({
            outsideDiameterOptions: outsideDiameter
        })
    };

    getOutsideDiameter = async () => {
        // Retrieve the outside_diameter for dropdown from the API
        axios.get(this.state.getSectionDataUrl + '&select=outside_diameter')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_outside_diameter', JSON.stringify(response.data.data));
                this.setOutsideDiameterOptions();
            })
            .catch(console.log);
    };

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_pipe_lengths_for_grooving', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.getNominalSize(), this.getOutsideDiameter(), this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    setNominalSize = n => {
        if (n !== null) {
            this.setState({
                selectedNominalSize: { 'value': n.value, 'label': n.label },
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_lengths_for_grooving')),
                    ['nominal_size', this.state.selectedNominalSize.value]
                );
                // filter the Size options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_lengths_for_grooving')),
                        ['nominal_size', this.state.selectedNominalSize.value]
                    ), (values, index) => {
                        options.push(values)
                    });
                let outsideDiameterOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.outside_diameter) && item.outside_diameter !== '' && item.outside_diameter !== null) {
                        map.set(item.outside_diameter, true);    // set any value to Map
                        outsideDiameterOptions.push({ value: item.outside_diameter, label: item.outside_diameter });
                    }
                }
                this.setResults(data, this.state.nominalSizeOptions, outsideDiameterOptions);
            });
        } else {
            this.setNominalSizeOptions();
            this.setOutsideDiameterOptions();
            this.setState({
                selectedNominalSize: '',
                selectedOutsideDiameter: '',
                results: {
                    minimumLength: '',
                    maximumLength: '',
                },
            });
        }
    };

    setOutsideDiameter = o => {
        if (o !== null) {
            this.setState({
                selectedOutsideDiameter: { 'value': o.value, 'label': o.label }
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_lengths_for_grooving')),
                    ['outside_diameter', this.state.selectedOutsideDiameter.value]
                );
                // filter the Size options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_lengths_for_grooving')),
                        ['outside_diameter', this.state.selectedOutsideDiameter.value]
                    ), (values, index) => {
                        options.push(values)
                    });
                let nominalSizeOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                        map.set(item.nominal_size, true);    // set any value to Map
                        nominalSizeOptions.push({ value: item.nominal_size, label: item.nominal_size });
                    }
                }
                this.setResults(data, nominalSizeOptions, this.state.outsideDiameterOptions)
            });
        } else {
            this.setNominalSizeOptions();
            this.setOutsideDiameterOptions();
            this.setState({
                results: {
                    selectedNominalSize: '',
                    selectedOutsideDiameter: '',
                    minimumLength: '',
                    maximumLength: '',
                },
            });
        }
    };

    setResults = (data, nominalSizeOptions, outsideDiameterOptions) => {
        try {
            if (Object.entries(this.state.selectedNominalSize).length > 0 &&
                Object.entries(this.state.selectedOutsideDiameter).length > 0) {
                this.setState({
                    selectedNominalSize: { 'value': data.nominal_size, 'label': data.nominal_size },
                    selectedOutsideDiameter: { 'value': data.outside_diameter, 'label': data.outside_diameter },
                    results: {
                        minimumLength: data.minimum_length,
                        maximumLength: data.maximum_length,
                    },
                });
            } else {
                this.setState({
                    results: {
                        minimumLength: '',
                        maximumLength: '',
                    },
                });
            }
            this.setState({
                nominalSizeOptions: nominalSizeOptions,
                outsideDiameterOptions: outsideDiameterOptions,
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    };

    render() {

        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionIntro>
                            {this.props.strings.I_100_SECTION_1_INTRO}
                        </SectionIntro>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.SIZE}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.NOMINAL_SIZE} {this.props.strings.INCHES}/{this.props.strings.MM}</Label>
                        <Select
                            options={this.state.nominalSizeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setNominalSize}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.ACTUAL_PIPE_OUTSIDE_DIAMETER}</Label>
                        <Select
                            options={this.state.outsideDiameterOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_DIAMETER}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setOutsideDiameter}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.LENGTH} - {this.props.strings.INCHES}/{this.props.strings.MM}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row className={this.state.results.minimumLength === '‡' ? "show" : "hide"}>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_100_SECTION_1_WARNING)}
                        </Subtext>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Label>{this.props.strings.MINIMUM}</Label>
                        <Input type="text" placeholder="&mdash;" id="minimum" value={this.state.results.minimumLength} readOnly="readonly" />
                    </Col>
                    <Col xs={6}>
                        <Label>{this.props.strings.MAXIMUM}</Label>
                        <Input type="text" placeholder="&mdash;" id="maximum" value={this.state.results.maximumLength} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_100_SECTION_1_FOOTER)}
                        </Subtext>
                    </Col>
                </Row>
            </Grid>
        );
    }
};

const SectionIntro = styled.p`
        border-bottom: 0.5px solid #efefef;
        padding-bottom: 15px;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 0;
        margin: 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default PipePreparation;