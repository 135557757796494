
import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class CpvcTemperatureRequirements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedPipeType: '',
            selectedTemp: '',
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=cpvc_temperature_requirements',
            pipeTypes: [],
            temperatures: [],
            results: {
                multiplyBy: ''
            },
        };
    }

    // Set Pipe Type Options
    setPipeTypeOptions = (response) => {
        let pipeTypes = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_type'));
        
        data.map((value, key) => {
            pipeTypes.push({ 'value': value.pipe_type, 'label': value.pipe_type });
            return true;
        });
        this.setState({
            pipeTypesOptions: pipeTypes
        })
    }

    // Get Pipe Types
    getPipeTypes = async () => {
        axios.get(this.state.getSectionDataUrl + '&select=pipe_type&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_pipe_type', JSON.stringify(response.data.data) );
                this.setPipeTypeOptions();
            })
            .catch(console.log)
    }

    // Set Temperature Options
    setTempOptions = () => {
        let temperatures = [];
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_temperature'));
        data.map((value, key) => {
            temperatures.push({ 'value': value.fahrenheit, 'label': value.fahrenheit + ' / ' + value.celsius });
            return true;
        });
        this.setState({
            temperatureOptions: temperatures
        })
    }

    // Get Temperature (Getting just fahrenheit ???)
    getTemp = async () => {
        axios.get(this.state.getSectionDataUrl + '&select=fahrenheit|celsius&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_temperature', JSON.stringify(response.data.data));
                this.setTempOptions();
            })
            .catch(console.log);
    };

    // Get the data set from the API
    getData = async () => {
        //Retrieving data from the API
        axios.get(this.state.getSectionDataUrl)
        .then((response) => {
            localStorage.setItem(this.props.sectionId + '_temperature_requirements', JSON.stringify(response.data.data));
        }).catch(console.log)
    }

    getAllData = async () => {
        await axios.all([this.getPipeTypes(), this.getTemp(), this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    // Set Pipe Types
    setPipeTypes = pt => {
        if(pt !== null) {   
            this.setState({
                selectedPipeType: {'value': pt.value, 'label': pt.label}
            }, () => {
                let data = _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_temperature_requirements')),
                    {'fahrenheit': this.state.selectedTemp.value, 'pipe_type': this.state.selectedPipeType.value}
                )                
                data = data[0];

                //Filter options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_temperature_requirements')),
                        ['pipe_type', this.state.selectedPipeType.value]
                    ), (values, index) => {
                        options.push(values)
                    }
                );
                let temperatureOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.fahrenheit) && item.fahrenheit !== '' && item.fahrenheit !== null ) {
                        map.set(item.fahrenheit, true);
                        temperatureOptions.push({ 'value': item.fahrenheit, 'label': item.fahrenheit + ' / ' + item.celsius });
                    }
                }
                this.setResults(data, this.state.pipeTypesOptions, temperatureOptions);
            });

        } else {
            this.setPipeTypeOptions();
            this.setTempOptions();
            this.setState({
                selectedPipeType: '',
                selectedTemp: '',
                results: {
                    multiplyBy: '',
                },
            });
        }
    };

    // Set Temperature
    setTemperature = temp => {

        if( temp !== null) {
            this.setState({
                selectedTemp: { 'value': temp.value, 'label': temp.label },
            }, () => {
                let data = _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_temperature_requirements')),
                    {'fahrenheit': this.state.selectedTemp.value, 'pipe_type': this.state.selectedPipeType.value}
                )
                data = data[0];

                //Filter options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_temperature_requirements')),
                        ['fahrenheit', this.state.selectedTemp.value]
                    ), (values, index) => {
                        options.push(values)
                    }
                );
                let pipeTypesOptions = [];
                let map = new Map();
                for(const item of options) {
                    if(!map.has(item.pipe_type) && item.pipe_type !== '' && item.pipe_type !== null) {
                        map.set(item.pipe_type, true);
                        pipeTypesOptions.push({ value: item.pipe_type, label: item.pipe_type });
                    }
                }
                this.setResults(data, pipeTypesOptions, this.state.temperatureOptions);
            });
        } else {
            this.setPipeTypeOptions();
            this.setTempOptions();
            this.setState({
                selectedPipeType: '',
                selectedTemp: '',
                results: {
                    multiplyBy: '',
                },
            });
        }

    };

    // Set Results
    setResults = (data, pipeTypesOptions, temperatureOptions) => {
        try {
            if (Object.entries(this.state.selectedPipeType).length > 0 &&
                Object.entries(this.state.selectedTemp).length > 0) {
                this.setState({
                    selectedPipeType: {'value': data.pipe_type, 'label': data.pipe_type},
                    selectedTemp: {'value': data.fahrenheit, 'label': data.fahrenheit},
                    results: {
                        multiplyBy: data.multiply_by
                    }
                });
            } else {
                this.setState({
                    results: {
                        multiplyBy: ''
                    },
                })
            }
            this.setState({
                pipeTypesOptions: pipeTypesOptions,
                temperatureOptions: temperatureOptions,
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    }


    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionIntro>
                         {ReactHtmlParser(this.props.strings.I_350_SECTION_1_HEADER)}
                        </SectionIntro>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>  
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.PIPE_TYPE}</Label>
                        <Select
                            options={this.state.pipeTypesOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_PIPE_TYPE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setPipeTypes}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{ReactHtmlParser(this.props.strings.TEMPERATURE)}</Label>
                        <Select
                            options={this.state.temperatureOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_TEMPERATURE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setTemperature}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SectionDivider />
                    </Col>
                </Row>


                <Row>
                    <Col xs={12}>
                        <CategoryHeader>{this.props.strings.MULTIPLY_BY}</CategoryHeader>
                    </Col>
                    <Col xs={12}>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.multiplyBy} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SectionSpacer />
                    </Col>
                </Row>

            </Grid>
        );
    }
}

const SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    SectionIntro = styled.div`
    border-bottom: 0.5px solid #efefef;
    padding-bottom: 15px;

    span {
        color: #ff7f00;
        font-weight: bold;
    }
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;

        &.full {
            width: 100%;
        }
    `,  
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `,
    SectionDivider = styled.div`
        border-top: 1px solid #efefef;
        border-collapse: collapse;
        height: 1px;
        margin: 10px 0;
    `,
    SectionSpacer = styled.div`
        margin: 20px 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        margin: 10px 0 0 0;
        text-transform: uppercase;
    `;

export default CpvcTemperatureRequirements;

