import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class PipeEndSeparation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=pipe_end_separation',
            getSectionDataTypesUrl: process.env.REACT_APP_API_URL + 'get/section?table=pipe_end_separation_types',
            introText: this.props.strings.I_100_SECTION_7_INTRO,
            couplingTypeOptions: [],
            nominalSizeOptions: [],
            outsideDiameterOptions: [],
            selectedCouplingType: {},
            selectedNominalSize: {},
            selectedOutsideDiameter: {},
            results: {
                displayHeader: false,
                style009h: '',
                style107h: '',
                maxAllowPipeEndSeparation: '',
                minPipeEndSeparation: '',
                maxPipeEndSeparationCutGroove: '',
                maxPipeEndSeparationRollGroove: '',
                linearMovementRollGroove: '',
                degreesPerCouplingRollGroove: '',
                inchesPerFootPipeRollGroove: '',
                linearMovementCutGroove: '',
                degreesPerCouplingCutGroove: '',
                inchesPerFootPipeCutGroove: '',
                degreesPerCouplingAgsRollGroove: '',
                inchesPerFootPipeAgsRollGroove: '',
                degreesPerCouplingDeflectionFromCenterline: '',
                inchesPerFootDeflectionFromCenterline: '',
            },
        };
    }

    getPipeSeparationTypes = async () => {
        // Retrieve the groove_types for dropdown from the API
        axios.get(this.state.getSectionDataTypesUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_pipe_end_separation_types', JSON.stringify(response.data.data));

                let couplingTypes = [];
                response.data.data.map((value, key) => {
                    couplingTypes.push({ 'value': value.id, 'label': value.name });
                    return true;
                });
                this.setState({ couplingTypeOptions: couplingTypes, });
            })
            .catch(console.log);
    };

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_pipe_end_separation', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.getPipeSeparationTypes(), this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                });
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    initializeForm = () => {
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_end_separation'));
        let nominalSizes = [];
        let outsideDiameters = [];
        let map = new Map();
        for (const item of data) {
            if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                map.set(item.nominal_size, true);    // set any value to Map
                nominalSizes.push({ value: item.nominal_size, label: item.nominal_size });
            }
        }
        for (const item of data) {
            if (!map.has(item.outside_diameter) && item.outside_diameter !== '' && item.outside_diameter !== null) {
                map.set(item.outside_diameter, true);    // set any value to Map
                outsideDiameters.push({ value: item.outside_diameter, label: item.outside_diameter });
            }
        }
        this.setState({
            nominalSizeOptions: nominalSizes,
            outsideDiameterOptions: outsideDiameters,
        });
    }

    componentDidMount() {
        this.getAllData();
        if (this.state.loading === false) {
            this.initializeForm();
        }
    }

    setCouplingType = c => {
        if (c !== null) {
            this.setState({
                selectedCouplingType: { 'value': c.value, 'label': c.label },
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_end_separation')),
                    ['pipe_end_separation_type_id', this.state.selectedCouplingType.value]
                );
                // filter the Size options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_end_separation')),
                        ['pipe_end_separation_type_id', this.state.selectedCouplingType.value]
                    ), (values, index) => {
                        options.push(values)
                    });
                let nominalSizeOptions = [];
                let outsideDiameterOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                        map.set(item.nominal_size, true);    // set any value to Map
                        nominalSizeOptions.push({ value: item.nominal_size, label: item.nominal_size });
                    }
                }
                for (const item of options) {
                    if (!map.has(item.outside_diameter) && item.outside_diameter !== '' && item.outside_diameter !== null) {
                        map.set(item.outside_diameter, true);    // set any value to Map
                        outsideDiameterOptions.push({ value: item.outside_diameter, label: item.outside_diameter });
                    }
                }
                this.setState({ selectedNominalSize: {}, selectedOutsideDiameter: {} });
                this.setResults(data, nominalSizeOptions, outsideDiameterOptions);
            });
        } else {
            this.initializeForm();
            this.setState({
                introText: this.props.strings.I_100_SECTION_1_INTRO,
                results: {},
            });
        }
    };

    setNominalSize = n => {
        if (n !== null) {
            this.setState({
                selectedNominalSize: { 'value': n.value, 'label': n.label },
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_end_separation')),
                    { 'pipe_end_separation_type_id': this.state.selectedCouplingType.value, 'nominal_size': this.state.selectedNominalSize.value }
                );
                // filter the Size options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_end_separation')),
                        { 'pipe_end_separation_type_id': this.state.selectedCouplingType.value, 'nominal_size': this.state.selectedNominalSize.value }
                    ), (values, index) => {
                        options.push(values)
                    });
                let outsideDiameterOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.outside_diameter) && item.outside_diameter !== '' && item.outside_diameter !== null) {
                        map.set(item.outside_diameter, true);    // set any value to Map
                        outsideDiameterOptions.push({ value: item.outside_diameter, label: item.outside_diameter });
                    }
                }
                this.setResults(data, this.state.nominalSizeOptions, outsideDiameterOptions);
            });
        } else {
            this.initializeForm();
            this.setState({
                introText: this.props.strings.I_100_SECTION_1_INTRO,
                results: {},
            });
        }
    };

    setOutsideDiameter = o => {
        if (o !== null) {
            this.setState({
                selectedOutsideDiameter: { 'value': o.value, 'label': o.label }
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_end_separation')),
                    { 'pipe_end_separation_type_id': this.state.selectedCouplingType.value, 'outside_diameter': this.state.selectedOutsideDiameter.value }
                );
                // filter the Size options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_pipe_end_separation')),
                        { 'pipe_end_separation_type_id': this.state.selectedCouplingType.value, 'outside_diameter': this.state.selectedOutsideDiameter.value }
                    ), (values, index) => {
                        options.push(values)
                    });
                let nominalSizeOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                        map.set(item.nominal_size, true);    // set any value to Map
                        nominalSizeOptions.push({ value: item.nominal_size, label: item.nominal_size });
                    }
                }
                this.setResults(data, nominalSizeOptions, this.state.outsideDiameterOptions);
            });
        } else {
            this.initializeForm();
            this.setState({
                introText: this.props.strings.I_100_SECTION_1_INTRO,
                results: {},
            });
        }
    };

    setResults = (data, nominalSizeOptions, outsideDiameterOptions) => {
        try {
            if (Object.entries(this.state.selectedCouplingType).length > 0 &&
                Object.entries(this.state.selectedNominalSize).length > 0 &&
                Object.entries(this.state.selectedOutsideDiameter).length > 0) {
                this.setState({ // update the results, ony if all three options are set
                    selectedCouplingType: this.state.selectedCouplingType,
                    selectedNominalSize: this.state.selectedNominalSize,
                    selectedOutsideDiameter: this.state.selectedOutsideDiameter,
                    results: {
                        displayHeader: true,
                        style009h: data.style_009h,
                        style107h: data.style_107h,
                        maxAllowPipeEndSeparation: data.max_allow_pipe_end_separation,
                        minPipeEndSeparation: data.min_pipe_end_separation,
                        maxPipeEndSeparationCutGroove: data.max_pipe_end_separation_cut_groove,
                        maxPipeEndSeparationRollGroove: data.max_pipe_end_separation_roll_groove,
                        linearMovementRollGroove: data.linear_movement_roll_groove,
                        degreesPerCouplingRollGroove: data.degrees_per_coupling_roll_groove,
                        inchesPerFootPipeRollGroove: data.inches_per_foot_pipe_roll_groove,
                        linearMovementCutGroove: data.linear_movement_cut_groove,
                        degreesPerCouplingCutGroove: data.degrees_per_coupling_cut_groove,
                        inchesPerFootPipeCutGroove: data.inches_per_foot_pipe_cut_groove,
                        degreesPerCouplingAgsRollGroove: data.degrees_per_coupling_ags_roll_groove,
                        inchesPerFootPipeAgsRollGroove: data.inches_per_foot_pipe_ags_roll_groove,
                        degreesPerCouplingDeflectionFromCenterline: data.degrees_per_coupling_deflection_from_centerline,
                        inchesPerFootDeflectionFromCenterline: data.inches_per_foot_deflection_from_centerline,
                    },
                });
            } else {
                this.setState({
                    results: {
                        displayHeader: false,
                    },
                });
            }

            if (Object.entries(this.state.selectedCouplingType).length > 0) {
                switch (this.state.selectedCouplingType.value) {
                    case 1:
                        this.setState({ introText: this.props.strings.I_100_SECTION_7_HEADER_1 });
                        break;
                    case 2:
                        this.setState({ introText: this.props.strings.I_100_SECTION_7_HEADER_2 });
                        break;
                    case 3:
                        this.setState({ introText: this.props.strings.I_100_SECTION_7_HEADER_3 });
                        break;
                    case 4:
                        this.setState({ introText: this.props.strings.I_100_SECTION_7_HEADER_4 });
                        break;
                    case 5:
                        this.setState({ introText: this.props.strings.I_100_SECTION_7_HEADER_5 });
                        break;
                    case 6:
                        this.setState({ introText: this.props.strings.I_100_SECTION_7_HEADER_6 });
                        break;
                    case 7:
                        this.setState({ introText: this.props.strings.I_100_SECTION_7_HEADER_7 });
                        break;
                    case 8:
                        this.setState({ introText: this.props.strings.I_100_SECTION_7_HEADER_8 });
                        break;
                    case 9:
                        this.setState({ introText: this.props.strings.I_100_SECTION_7_HEADER_9 });
                        break;
                    default:
                        this.setState({ introText: this.props.strings.I_100_SECTION_1_INTRO });
                        break;
                }
            }

            this.setState({ // update the Size options, always
                nominalSizeOptions: nominalSizeOptions,
                outsideDiameterOptions: outsideDiameterOptions,
            });

        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    };

    render() {
        return (
            <Grid>

                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.COUPLING_TYPE}</Label>
                        <Select
                            options={this.state.couplingTypeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_COUPLING_TYPE}
                            className="input-filter"
                            onChange={this.setCouplingType}
                            isClearable={true}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SectionIntro>
                            <p>{ReactHtmlParser(this.state.introText)}</p>
                        </SectionIntro>
                    </Col>
                </Row>

                <Row className={this.state.selectedCouplingType.value ? "" : "hide"}>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.SIZE}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row className={this.state.selectedCouplingType.value ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.NOMINAL_SIZE} {this.props.strings.INCHES}/{this.props.strings.MM}</Label>
                        <Select
                            options={this.state.nominalSizeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                            className="input-filter"
                            onChange={this.setNominalSize}
                            isClearable={true}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.selectedCouplingType.value ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.ACTUAL_PIPE_OUTSIDE_DIAMETER} {this.props.strings.INCHES}/{this.props.strings.MM}</Label>
                        <Select
                            options={this.state.outsideDiameterOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_DIAMETER}
                            className="input-filter"
                            onChange={this.setOutsideDiameter}
                            isClearable={true}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className={this.state.results.displayHeader ? "" : "hide"}>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.MAX_ALLOW_PIPE_END_SEPARATION} {this.props.strings.INCHES}/{this.props.strings.MM}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row className={this.state.results.style009h || this.state.results.style107h ? "" : "hide"}>
                    <Col xs={6}>
                        <Label>{this.props.strings.STYLE} 009H</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.style009h || ''} readOnly="readonly" />
                    </Col>
                    <Col xs={6}>
                        <Label>{this.props.strings.STYLE} 107H</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.style107h || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.maxAllowPipeEndSeparation ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.MAX_ALLOW_PIPE_END_SEPARATION}</Label>
                        <Input type="text" className="full" value={this.state.results.maxAllowPipeEndSeparation || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.minPipeEndSeparation ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.MIN_PIPE_END_SEPARATION}</Label>
                        <Input type="text" className="full" value={this.state.results.minPipeEndSeparation || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.maxPipeEndSeparationCutGroove ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.MAX_PIPE_END_SEPARATION} {this.props.strings.CUT_GROOVE}</Label>
                        <Input type="text" className="full" value={this.state.results.maxPipeEndSeparationCutGroove || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.maxPipeEndSeparationRollGroove ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.MAX_PIPE_END_SEPARATION} {this.props.strings.ROLL_GROOVE}</Label>
                        <Input type="text" className="full" value={this.state.results.maxPipeEndSeparationRollGroove || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.linearMovementRollGroove ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.LINEAR_MOVEMENT} {this.props.strings.ROLL_GROOVE}</Label>
                        <Input type="text" className="full" value={this.state.results.linearMovementRollGroove || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.degreesPerCouplingRollGroove ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.DEGREES_PER_COUPLING} {this.props.strings.ROLL_GROOVE}</Label>
                        <Input type="text" className="full" value={this.state.results.degreesPerCouplingRollGroove || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.inchesPerFootPipeRollGroove ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.INCHES_PER_FOOT} {this.props.strings.PIPE} {this.props.strings.ROLL_GROOVE}</Label>
                        <Input type="text" className="full" value={this.state.results.inchesPerFootPipeRollGroove || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.linearMovementCutGroove ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.LINEAR_MOVEMENT} {this.props.strings.CUT_GROOVE}</Label>
                        <Input type="text" className="full" value={this.state.results.linearMovementCutGroove || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.degreesPerCouplingCutGroove ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.DEGREES_PER_COUPLING} {this.props.strings.CUT_GROOVE}</Label>
                        <Input type="text" className="full" value={this.state.results.degreesPerCouplingCutGroove || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.inchesPerFootPipeCutGroove ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.INCHES_PER_FOOT} {this.props.strings.PIPE} {this.props.strings.CUT_GROOVE}</Label>
                        <Input type="text" className="full" value={this.state.results.inchesPerFootPipeCutGroove || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.degreesPerCouplingAgsRollGroove ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.DEGREES_PER_COUPLING} {this.props.strings.AGS} {this.props.strings.ROLL_GROOVE}</Label>
                        <Input type="text" className="full" value={this.state.results.degreesPerCouplingAgsRollGroove || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.inchesPerFootPipeAgsRollGroove ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.INCHES_PER_FOOT} {this.props.strings.AGS} {this.props.strings.ROLL_GROOVE}</Label>
                        <Input type="text" className="full" value={this.state.results.inchesPerFootPipeAgsRollGroove || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.degreesPerCouplingDeflectionFromCenterline ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.DEGREES_PER_COUPLING} {this.props.strings.DEFLECTION_FROM_CENTERLINE}</Label>
                        <Input type="text" className="full" value={this.state.results.degreesPerCouplingDeflectionFromCenterline || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.results.inchesPerFootDeflectionFromCenterline ? "" : "hide"}>
                    <Col xs={12}>
                        <Label>{this.props.strings.INCHES_PER_FOOT} {this.props.strings.DEFLECTION_FROM_CENTERLINE}</Label>
                        <Input type="text" className="full" value={this.state.results.inchesPerFootDeflectionFromCenterline || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row className={this.state.selectedCouplingType.value ? "" : "hide"}>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_100_SECTION_7_FOOTER)}
                        </Subtext>
                    </Col>
                </Row>
            </Grid>
        );
    }
};

const SectionIntro = styled.div`
        padding: 0 0 5px 0;
        margin: 15px 0 0 0;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 0;
        margin: 10px 0 10px 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin-bottom: 10px;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;

        &.full {
            width:100%;
        }
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
        margin-top: 15px;
        border-top: 0.5px solid #efefef;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default PipeEndSeparation;