import React, { Component } from 'react';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';

import HomeLayout from './shared/HomeLayout';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            languageSet: localStorage.getItem('language'),
            terms: localStorage.getItem('terms'),
            installPwa: localStorage.getItem('pwa') || null,
            languages: JSON.parse(localStorage.getItem('languages')),
            strings: JSON.parse(localStorage.getItem('strings')),
            handbooks:JSON.parse(localStorage.getItem('handbooks'))
        };
    }

    componentDidMount() { }

    render() {

        const handbooks = Object.entries(this.state.handbooks);

        if (this.state.langCookie === 'en') return <Redirect to={{
            pathname: 'language',
            state: { languages: this.state.language, strings: this.state.strings }
        }} />

        else if (this.state.terms !== 'accepted') return <Redirect to={{
            pathname: 'terms',
            state: { languages: this.state.language, strings: this.state.strings }
        }} />
        
        else return (
            <div>
                <HomeLayout language={this.state.language} strings={this.state.strings} />
                <MenuContainer>
                    {
                        handbooks.map((handbook, handbookIndex) => {
                            const active = (handbook[1]['handbook'].active) ? "" : "disabled"
                            const sectionName = handbook[1]['handbook'].code;
                            handbookIndex++;
                            return (
                                <Link key={handbookIndex} to={{
                                    pathname: process.env.REACT_APP_PUBLIC_URL + "guide/" + handbookIndex,
                                    state: { languages: this.state.languages, strings: this.state.strings }
                                }} className={sectionName.toLowerCase().replace(/-/g, "") + " " + active}>
                                    <ArrowContainer>
                                        <Arrow className="icon-back" />
                                    </ArrowContainer>
                                    <span>{sectionName}</span>
                                    <p>{this.state.strings[sectionName.replace(/-/g, "") + "_DESCRIPTION"]}</p>
                                </Link>
                            )
                        })
                    }
                </MenuContainer>
            </div>
        );
    };
};

const MenuContainer = styled.div`
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        width: 96%;
        height: auto;
        left: -1%;


        margin: 100px 4.5% 20px;
        padding: 0 0;
        text-align: center;
        position: relative;
        z-index: 1;


        opacity: 1;
        transition: opacity .5 ease-in-out;
        -moz-transition: opacity .5 ease-in-out;
        -webkit-transition: opacity .5 ease-in-out;

        a {
            display: block;
            width: 85%;
            margin: 4px auto;
            position: relative;
            z-index: 1;
            top: -18px;
            left: -5px;
            text-transform: uppercase;
            text-align: left;
            color: #ffffff;
            padding: 12px;
            font-weight: bold;
            font-size: 34px;
            font-family: Rajdhani, sans-serif;
            height: 120px;

            &.disabled {
                color: #4d4d4d;
                pointer-events: none;
            }

            &.i100 {
                background-color: #ff7f00;
            }

            &.i300 {
                background-color: #370707;
            }

            &.i350 {
                background-color: #313131;
            }

            &.i600 {
                background-color: #382f16;
            }

            &.i900 {
                background-color: #000000;
            }

            span {
                position: relative;
                top: -40px;
            }

            p {
                font-family: "Open Sans", sans-serif;
                font-weight: normal;
                font-size: 16px;
                text-transform: none;
                position: relative;
                top: -55px;
                width: 85%;
            }
        }
    `,
    ArrowContainer = styled.div`
        right: 0;
        position: relative;
        z-index: 2;
        top: 28px;
        transform: rotate(180deg);
    `,
    Arrow = styled.i`
        font-size: 26px;
        position: relative;
    `;

export default Home;