import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Layout = ({ children }) => {
    return (
        <div>
            <ChildrenContainer>{children}</ChildrenContainer>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.array.isRequired
};

const ChildrenContainer = styled.div`
        overflow-x: hidden;
        overflow-y: scroll;
    `;


export default Layout;
