import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import Toggle from 'react-toggle';


import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class GrooveSpecification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=copper_groove_dimensions',
            getSectionDataHeaderUrl: process.env.REACT_APP_API_URL + 'get/section?table=copper_groove_dimensions',
            tubeTypeOptions: [],
            nominalSizeOptions: [],
            nominalSelectDisabled : true,
            selectedTubeType: {},
            selectedNominalSize: null,
            sizeString : "",
            sizeGraphText: "",
            odText : "",
            units : true,
            results: {
                actualOutsideDiameterInches: {max: "", min : ""},
                actualOutsideDiameterMM: {max: "", min : ""},
                gasketSeatAInches : {basic: "", max: "", min : ""},
                gasketSeatAMM : {basic: "", max: "", min : ""},
                grooveWidthBInches : {max: "", min: ""},
                grooveWidthBMM : {max: "", min: ""},
                grooveDiameterCInches : {max: "", min: ""},
                grooveDiameterCMM : {max: "", min: ""},
                grooveDepthDInches : "",
                grooveDepthDMM : "",
                minAllowWallThicknessT : "",
                maxAllowFlareDiaFInches : "",
                maxAllowFlareDiaFMM : ""
            },
        };
    }

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_groove_types', JSON.stringify(response.data.data));
                let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_types'));
                let tubeTypeOptions = [];
                let map = new Map();
                for (const item of data) {
                    if (!map.has(item.tube_type) && item.tube_type !== '' && item.tube_type !== null) {
                        map.set(item.tube_type, true);    // set any value to Map
                        tubeTypeOptions.push({ value: item.tube_type, label: item.tube_type });
                    }
                }
                this.setState({
                    tubeTypeOptions: tubeTypeOptions,
                })
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.grooveSpecificationOptions, this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    changeUnits = (e) => {
        this.setState({
            units: e.target.checked,
        });
        this.setState({ selectedNominalDiameter: null });
        localStorage.setItem('units', e.target.checked);
    }

    setTubeType = n => {
        this.setState({
            selectedTubeType: { 'value': n.value, 'label': n.label },
        }, () => {
            let options = [];
            let sizeString, sizeGraphText, odText;
            _.forEach(
                _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_types')),
                    ['tube_type', this.state.selectedTubeType.value]
                ), (values, index) => {
                    options.push(values)
                });
            let nominalSizeOptions = [];
            let map = new Map();
            for (const item of options) {
                if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                    map.set(item.nominal_size, true);    // set any value to Map
                    nominalSizeOptions.push({ value: item.nominal_size, label: item.nominal_size });
                    
                }
            }
            if(this.state.selectedTubeType.value === 'CTS US Standard – ASTM B-88 Hard-Drawn Copper Tubing and DWV per ASTM B-306') {
                sizeString = this.props.strings.NOMINAL_SIZE_INCHES_ACTUAL_MM;
                sizeGraphText = this.props.strings.I_600_SECTION_2_US_STANDARD;
                odText = this.props.strings.COPPER_TUBING_OUTSIDE_DIAMETER
            } else if(this.state.selectedTubeType.value === 'European Standard – EN 1057 R250 (Half-Hard) Copper Tubing') {
                sizeString = this.props.strings.EU_STANDARD_COPPER_TUBING_NOMINAL_SIZE
                sizeGraphText = this.props.strings.I_600_SECTION_2_EU_STANDARD;
                odText = this.props.strings.ACTUAL_OD;
            } else if(this.state.selectedTubeType.value === 'Australian Standard – AS 1432 Tables A, B, and D Copper Tubing') {
                sizeString = this.props.strings.AUS_STANDARD_COPPER_TUBING_NOMINAL_SIZE
                sizeGraphText = this.props.strings.I_600_SECTION_2_AUS_STANDARD;
                odText = this.props.strings.ACTUAL_OD
            }
            
            this.setState({
                nominalSizeOptions : nominalSizeOptions,
                nominalSelectDisabled : null,
                selectedNominalSize: null,
                sizeString : sizeString,
                sizeGraphText : sizeGraphText,
                odText : odText
            })

            this.clearResults();
            
        });
    };

    clearResults = () => {
        this.setState({
            results: {
                actualOutsideDiameterInches: {max: "", min : ""},
                actualOutsideDiameterMM: {max: "", min : ""},
                gasketSeatAInches : {basic: "", max: "", min : ""},
                gasketSeatAMM : {basic: "", max: "", min : ""},
                grooveWidthBInches : {max: "", min: ""},
                grooveWidthBMM : {max: "", min: ""},
                grooveDiameterCInches : {max: "", min: ""},
                grooveDiameterCMM : {max: "", min: ""},
                grooveDepthDInches : "",
                grooveDepthDMM : "",
                minAllowWallThicknessT : "",
                maxAllowFlareDiaFInches : "",
                maxAllowFlareDiaFMM : ""
            },
        });
    }

    setNominalSize = n => {
        this.setState({
            selectedNominalSize: { 'value': n.value, 'label': n.label },
        }, () => {
            this.setResults();
        })
        
    };


    setResults = () => {
        const selectedTubeType = this.state.selectedTubeType;
        const selectedNominalSize = this.state.selectedNominalSize;
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_groove_types'));
        try {
            if (Object.entries(selectedTubeType).length > 0 &&
                Object.entries(selectedNominalSize).length > 0) {
                let results = _.forEach(_.find(data, {
                    'tube_type': selectedTubeType.value,
                    'nominal_size': selectedNominalSize.value
                }))
                this.setState({
                    results: {
                        actualOutsideDiameterInches: {
                            max: results.actual_outside_diameter_max_inches, 
                            min: results.actual_outside_diameter_min_inches,
                        },
                        actualOutsideDiameterMM: {
                            max: results.actual_outside_diameter_max_mm, 
                            min: results.actual_outside_diameter_min_mm,
                        },
                        gasketSeatAInches : {
                            basic : results.gasket_seat_a_basic_inches,
                            max : results.gasket_seat_a_max_inches,
                            min : results.gasket_seat_a_min_inches 
                        },
                        gasketSeatAMM : {
                            basic : results.gasket_seat_a_basic_mm,
                            max : results.gasket_seat_a_max_mm,
                            min : results.gasket_seat_a_min_mm 
                        },
                        grooveWidthBInches : {
                            max : results.groove_width_b_max_inches,
                            min : results.groove_width_b_min_inches 
                        },
                        grooveWidthBMM : {
                            max : results.groove_width_b_max_mm,
                            min : results.groove_width_b_min_mm 
                        },
                        grooveDiameterCInches : {
                            max : results.groove_diameter_c_max_inches,
                            min : results.groove_diameter_c_min_inches 
                        },
                        grooveDiameterCMM : {
                            max : results.groove_diameter_c_max_mm,
                            min : results.groove_diameter_c_min_mm 
                        },
                        grooveDepthDInches : results.groove_depth_d_inches,
                        grooveDepthDMM : results.groove_depth_d_mm,
                        minAllowWallThicknessT : results.min_allow_wall_thickness_t,
                        maxAllowFlareDiaFInches : results.max_allow_flare_diameter_f_inches,
                        maxAllowFlareDiaFMM : results.max_allow_diameter_f_mm
                    },
                });
            }
            this.setState({
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    };

    

    render() {
        // console.log(this.state)
        const string = this.props.strings;
        const state = this.state;
        const units = state.units;
        const results = state.results;
        const unit = (units) ? string.INCHES : string.MM;
        const MinWallThicknessT  = (units && state.selectedTubeType.value === 'CTS US Standard – ASTM B-88 Hard-Drawn Copper Tubing and DWV per ASTM B-306') ? (
                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.MIN_ALLOW_WALL_THICKNESS_T}</Label>
                    </Col>
                    <Col xs={12}>
                        <Label className="sublabel">{this.props.strings.INCHES}</Label>
                        <Input type="text" placeholder="&mdash;" value={this.state.results.minAllowWallThicknessT || ''} readOnly="readonly" />
                    </Col>
                </Row>
        ) : ""
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionCaution>
                            <h2><i className="icon-caution icon-round-bg"></i> Warning</h2>
                            <div>{ReactHtmlParser(this.props.strings.I_600_SECTION_2_WARNING)}</div>
                        </SectionCaution>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{string.TUBE_TYPE}</Label>
                        <Select
                            options={this.state.tubeTypeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_GROOVE_TYPE}
                            className="input-filter"
                            onChange={this.setTubeType}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.SIZE}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.state.sizeString}</Label>
                        <Select
                            options={this.state.nominalSizeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_NOMINAL_SIZE}
                            value={this.state.selectedNominalSize}
                            className="input-filter"
                            onChange={this.setNominalSize}
                            isDisabled={this.state.nominalSelectDisabled ? true : null}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row className="extra-padding">
                    <Col xs={6}>
                        <Label>{this.props.strings.UNITS}</Label>
                    </Col>
                    <Col xs={6} className="toggle-container">
                        <label>
                            <Toggle
                                defaultChecked={this.state.units}
                                className="victaulic-toggle"
                                icons={false}
                                onChange={this.changeUnits} />
                            <Span className="victaulic-toggle-label">
                                {(this.state.units === true ? this.props.strings.INCHES : this.props.strings.MM)}
                            </Span>
                        </label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.state.sizeGraphText)}
                        </Subtext>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.GROOVE_DIMENSIONS}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.state.odText}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Label className="sublabel">{this.props.strings.MAX} {unit}</Label>
                        <Input 
                            type="text" placeholder="&mdash;" 
                            value={(units) ? results.actualOutsideDiameterInches.max || '' : results.actualOutsideDiameterMM.max} 
                            readOnly="readonly" 
                        />
                    </Col>
                    <Col xs={6}>
                        <Label className="sublabel">{this.props.strings.MIN} {unit} </Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={(units) ? results.actualOutsideDiameterInches.min || '' : results.actualOutsideDiameterMM.min} 
                            readOnly="readonly" />
                    </Col>
                </Row>

                    {/* GASKET SEAT A */}
                        
                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GASKET_SEAT_A}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={4}>
                        <Label className="sublabel">{string.BASIC} {unit}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={(units) ? results.gasketSeatAInches.basic || '' : results.gasketSeatAMM.basic} 
                            readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{string.MAX} {unit}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={(units) ? results.gasketSeatAInches.max || '' : results.gasketSeatAMM.max} 
                            readOnly="readonly" />
                    </Col>
                    <Col xs={4}>
                        <Label className="sublabel">{string.MIN} {unit} </Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={(units) ? results.gasketSeatAInches.min || '' : results.gasketSeatAMM.min} 
                            readOnly="readonly" />
                    </Col>

                </Row>


                    {/* GROOVE WIDTH B */}

                <Row>
                    <Col xs={12}>
                        <Label>{string.GROOVE_WIDTH_B}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Label className="sublabel">{string.MAX} {unit}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={(units ) ? results.grooveWidthBInches.max || '' : results.grooveWidthBMM.max} 
                            readOnly="readonly" />
                    </Col>
                    <Col xs={6}>
                        <Label className="sublabel">{string.MIN} {unit} </Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={(units) ? results.grooveWidthBInches.min || '' : results.grooveWidthBMM.min} 
                            readOnly="readonly" />
                    </Col>

                </Row>

                        {/* GROOVE DIAMETER C */}

                <Row>
                    <Col xs={12}>
                        <Label>{string.GROOVE_DIAMETER_C}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Label className="sublabel">{string.MAX} {unit}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={(units) ? results.grooveDiameterCInches.max || '' : results.grooveDiameterCMM.max} 
                            readOnly="readonly" />
                    </Col>
                    <Col xs={6}>
                        <Label className="sublabel">{string.MIN} {unit} </Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={(units) ? results.grooveDiameterCInches.min || '' : results.grooveDiameterCMM.min} 
                            readOnly="readonly" />
                    </Col>

                </Row>

                    {/* GROOVE DEPTH D */}

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GROOVE_DEPTH_D}</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label className="sublabel">{unit}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={ (units) ? results.grooveDepthDInches || '' : results.grooveDepthDMM} 
                            readOnly="readonly" />
                    </Col>

                </Row>

                    {/* MIN ALLOW WALL THICKNESS T */}
                    {MinWallThicknessT}

                    {/* MAX ALLOW FLARE DIA F */}
                <Row>
                    <Col xs={12}>
                        <Label>Max Allowable Flare Diameter "F"</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label className="sublabel">{unit}</Label>
                        <Input 
                            type="text" 
                            placeholder="&mdash;" 
                            value={ (units) ? results.maxAllowFlareDiaFInches || '' : results.maxAllowFlareDiaFMM} 
                            readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SectionDivider />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SectionCaution>
                            <h2>Notice</h2>
                            <div>{ReactHtmlParser(this.props.strings.I_600_SECTION_2_NOTICE)}</div>
                        </SectionCaution>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_600_SECTION_2_FOOTER_1)}
                        </Subtext>
                    </Col>
                </Row>

            </Grid>
        );
    }
};

const SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    SectionCaution = styled.div`
        border: 1px solid #333333;
        padding: 0;
        margin: 20px 0 0 0;

        h2 {
            font-family: Rajdhani, sans-serif;
            font-size: 20px;
            background: #333333;
            color: #ffffff;
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
            margin:0 0 20px 0;

            i {
                font-size: 14px;
                vertical-align: middle;
                position:relative;
                top:-1px;
            }
        }
        
        div {
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            text-transform: none;
            padding: 0 10px;
            position: relative;
            top: -10px;
            margin: 0 0 5px 0;
        }

        .icon-round-bg {
            background: #ff7f00;
            color: #ffffff;
        }
    `,
    SectionDivider = styled.div`
        border-top: 1px solid #efefef;
        border-collapse: collapse;
        height: 1px;
        margin: 10px 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `,
    Span = styled.span`
        text-transform: uppercase;
    `;

export default GrooveSpecification;