import React from 'react'
import axios from 'axios';
import { Switch, Route, Redirect } from 'react-router-dom'

import Splash from './Splash';
import Language from './Language';
import Terms from './Terms'
import InstallPwa from './InstallPwa';
import Home from './Home';
import Guide from './Guide';
import Section from './Section';

axios.defaults.withCredentials = true;

const Main = () => (
    <main>
        <Switch>
            <Route exact path='/app' component={Splash} />
            <Route path='/app/language' component={Language} />
            <Route path='/app/terms' component={Terms} />
            <Route path='/app/install-pwa' component={InstallPwa} />
            <Route path='/app/home' component={Home} />
            <Route path='/app/guide/:guide' component={(props) => <Guide {...props} key={window.location.pathname} />} />
            <Route path='/app/section/:guide/:id' component={Section} />
            <Route path="/app/*">
                <Redirect to='/app' />
            </Route>
        </Switch>
    </main>
)

export default Main
