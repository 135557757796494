import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class CopperTubePreparation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrlTubePrep: process.env.REACT_APP_API_URL + 'get/section?table=copper_tube_preparation',
            getSectionDataUrlTubeLength: process.env.REACT_APP_API_URL + 'get/section?table=copper_tube_lengths_for_grooving',
            getSectionDataHeaderUrlTubePrep: process.env.REACT_APP_API_URL + 'get/section?table=copper_tube_preparation',
            prepTypeOptions: {},
            selectedPrepType: {},
            tubeTypeOptions: [],
            selectedTubeType: null,
            toolModelOptions: [],
            selectedToolModel: null,
            selectedStandardType: null,
            toolLengthStandardOptions: {},
            toolModelSelectDisabled: true,
            nominalSizeOptions: {},
            selectedNominalSize: null,
            toolStandardSelectedDisabled: true,
            resultsRatings: {
                copper_tubing_size_2: "",
                copper_tubing_size_2_5: "",
                copper_tubing_size_3: "",
                copper_tubing_size_4: "",
                copper_tubing_size_5: "",
                copper_tubing_size_6: "",
                copper_tubing_size_8: "",
                copper_tubing_size_54: "",
                copper_tubing_size_64: "",
                copper_tubing_size_66_7: "",
                copper_tubing_size_76_1: "",
                copper_tubing_size_88_9: "",
                copper_tubing_size_108: "",
                copper_tubing_size_133: "",
                copper_tubing_size_159: "",
                copper_tubing_size_dn50: "",
                copper_tubing_size_dn65: "",
                copper_tubing_size_dn80: "",
                copper_tubing_size_dn100: "",
                copper_tubing_size_dn125: "",
                copper_tubing_size_dn150: "",
            },
            resultsLength: {
                tubing_length_max: '',
                tubing_length_min: ''

            }
        };
    }



    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrlTubePrep)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_tube_preparations', JSON.stringify(response.data.data));
                let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_tube_preparations'));
                let tubeTypeOptions = [];
                let map = new Map();
                let prepTypeOptions;
                let count = 0;
                for (const item of data) {
                    if (!map.has(item.tube_type) && item.tube_type !== '' && item.tube_type !== null) {
                        map.set(item.tube_type, true);    // set any value to Map
                        tubeTypeOptions.push({ value: count, label: item.tube_type });
                        count++
                    }

                }
                prepTypeOptions = [
                    { 'value': 1, 'label': "Tool Ratings" },
                    { 'value': 2, 'label': "Tubing Length Requirements For Grooving" },
                ];

                this.setState({
                    tubeTypeOptions: tubeTypeOptions,
                    prepTypeOptions: prepTypeOptions
                })
            })
            .catch(console.log);

        axios.get(this.state.getSectionDataUrlTubeLength)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_copper_tube_lengths_for_grooving', JSON.stringify(response.data.data));
                let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_tube_lengths_for_grooving'));
                let toolLengthStandardOptions = [];
                let map = new Map();
                for (const item of data) {
                    if (!map.has(item.standard) && item.standard !== '' && item.standard !== null) {
                        map.set(item.standard, true);    // set any value to Map
                        toolLengthStandardOptions.push({ value: item.standard, label: item.standard });
                    }

                }
                this.setState({
                    toolLengthStandardOptions: toolLengthStandardOptions,
                })
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.grooveSpecificationOptions, this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    setPrepType = pt => {
        this.setState({
            selectedPrepType: { 'value': pt.value, 'label': pt.label }
        })
    }

    setTubeType = n => {
        this.setState({
            selectedTubeType: { 'value': n.value, 'label': n.label },
        }, () => {
            let options = [];
            _.forEach(
                _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_tube_preparations')),
                    ['tube_type', this.state.selectedTubeType.label]
                ), (values, index) => {
                    options.push(values)

                });
            let toolModelOptions = [];
            let map = new Map();
            for (const item of options) {
                if (!map.has(item.tool_model) && item.tool_model !== '' && item.tool_model !== null) {
                    map.set(item.tool_model, true);    // set any value to Map
                    toolModelOptions.push({ value: item.tool_model, label: item.tool_model });

                }
            }

            this.setState({
                toolModelOptions: toolModelOptions,
                toolModelSelectDisabled: null,
                selectedToolModel: null,
                // sizeString : sizeString,
                // sizeGraphText : sizeGraphText,
                // odText : odText
            })

            this.clearResultsRatings();
            // console.log(this.state)
        });
    };

    setStandardType = s => {
        this.setState({
            selectedStandardType: { 'value': s.value, 'label': s.label },
        }, () => {
            let options = [];
            _.forEach(
                _.filter(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_tube_lengths_for_grooving')),
                    ['standard', this.state.selectedStandardType.label]
                ), (values, index) => {
                    options.push(values)

                });
            let nominalSizeOptions = [];
            let map = new Map();
            for (const item of options) {
                if (!map.has(item.nominal_size) && item.nominal_size !== '' && item.nominal_size !== null) {
                    map.set(item.nominal_size, true);    // set any value to Map
                    nominalSizeOptions.push({ value: item.nominal_size, label: item.nominal_size });

                }
            }

            this.setState({
                nominalSizeOptions: nominalSizeOptions,
                toolStandardSelectedDisabled: null,
                selectedNominalSize: null,
            })

            this.clearResultsLength();
        });
    }



    setToolModel = n => {
        this.setState({
            selectedToolModel: { 'value': n.value, 'label': n.label },
        }, () => {
            this.setResults();
        })

    };

    clearResultsRatings = () => {
        this.setState({
            resultsRatings: {
                copper_tubing_size_2: "",
                copper_tubing_size_2_5: "",
                copper_tubing_size_3: "",
                copper_tubing_size_4: "",
                copper_tubing_size_5: "",
                copper_tubing_size_6: "",
                copper_tubing_size_8: "",
                copper_tubing_size_54: "",
                copper_tubing_size_64: "",
                copper_tubing_size_66_7: "",
                copper_tubing_size_76_1: "",
                copper_tubing_size_88_9: "",
                copper_tubing_size_108: "",
                copper_tubing_size_133: "",
                copper_tubing_size_159: "",
                copper_tubing_size_dn50: "",
                copper_tubing_size_dn65: "",
                copper_tubing_size_dn80: "",
                copper_tubing_size_dn100: "",
                copper_tubing_size_dn125: "",
                copper_tubing_size_dn150: "",
            },
        });
    }

    clearResultsLength = () => {
        this.setState({
            resultsLength: {
                tubing_length_max: '',
                tubing_length_min: ''
            }
        });
    }

    setNominalSize = ns => {
        this.setState({
            selectedNominalSize: { 'value': ns.value, 'label': ns.label }
        }, () => {
            this.setResultsLength();
        })
    }


    setResults = () => {
        const selectedTubeType = this.state.selectedTubeType;
        const selectedToolModel = this.state.selectedToolModel;
        let dataRating = JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_tube_preparations'));

        try {
            if (Object.entries(selectedTubeType).length > 0 &&
                Object.entries(selectedToolModel).length > 0) {
                let results = _.forEach(_.find(dataRating, {
                    'tube_type': selectedTubeType.label,
                    'tool_model': selectedToolModel.value
                }))
                this.setState({
                    resultsRatings: {
                        copper_tubing_size_2: results.copper_tubing_size_2,
                        copper_tubing_size_2_5: results.copper_tubing_size_2_5,
                        copper_tubing_size_3: results.copper_tubing_size_3,
                        copper_tubing_size_4: results.copper_tubing_size_4,
                        copper_tubing_size_5: results.copper_tubing_size_5,
                        copper_tubing_size_6: results.copper_tubing_size_6,
                        copper_tubing_size_8: results.copper_tubing_size_8,
                        copper_tubing_size_54: results.copper_tubing_size_54,
                        copper_tubing_size_64: results.copper_tubing_size_64,
                        copper_tubing_size_66_7: results.copper_tubing_size_66_7,
                        copper_tubing_size_76_1: results.copper_tubing_size_76_1,
                        copper_tubing_size_88_9: results.copper_tubing_size_88_9,
                        copper_tubing_size_108: results.copper_tubing_size_108,
                        copper_tubing_size_133: results.copper_tubing_size_133,
                        copper_tubing_size_159: results.copper_tubing_size_159,
                        copper_tubing_size_dn50: results.copper_tubing_size_dn50,
                        copper_tubing_size_dn65: results.copper_tubing_size_dn65,
                        copper_tubing_size_dn80: results.copper_tubing_size_dn80,
                        copper_tubing_size_dn100: results.copper_tubing_size_dn100,
                        copper_tubing_size_dn125: results.copper_tubing_size_dn125,
                        copper_tubing_size_dn150: results.copper_tubing_size_dn150,
                    }
                });
            }
            this.setState({
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    };

    setResultsLength = () => {
        const selectedStandardType = this.state.selectedStandardType;
        const selectedNominalSize = this.state.selectedNominalSize;
        let dataLength = JSON.parse(localStorage.getItem(this.props.sectionId + '_copper_tube_lengths_for_grooving'));
        try {
            if (Object.entries(selectedStandardType).length > 0 &&
                Object.entries(selectedNominalSize).length > 0) {
                let results = _.forEach(_.find(dataLength, {
                    'standard': selectedStandardType.value,
                    'nominal_size': selectedNominalSize.value
                }))
                this.setState({
                    resultsLength: {
                        tubing_length_max: results.tubing_length_max,
                        tubing_length_min: results.tubing_length_min
                    },
                });
            }
            this.setState({
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    }

    render() {
        const string = this.props.strings;
        const state = this.state;
        const selectedToolType = (state.selectedTubeType) ? state.selectedTubeType.value : null;
        const selectedPrepType = state.selectedPrepType


        const prepLabel = () => {
            return (
                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {selectedPrepType.label}
                        </CategoryHeader>
                    </Col>
                </Row>
            )
        }

        const ratingsBoxes = () => {
            const results = this.state.resultsRatings
            if (selectedToolType === 0) {
                return (
                    <Row>
                        <Col xs={12}>
                            <Label className="sublabel">2</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.copper_tubing_size_2 || ''}
                                readOnly="readonly"
                            />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">2.5</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_2_5 || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">3</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.copper_tubing_size_3 || ''}
                                readOnly="readonly"
                            />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">4</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_4 || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">5</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.copper_tubing_size_5 || ''}
                                readOnly="readonly"
                            />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">6</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_6 || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">8</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_8 || ''}
                                readOnly="readonly" />
                        </Col>
                    </Row>
                )
            } else if (selectedToolType === 1) {
                return (
                    <Row>
                        <Col xs={12}>
                            <Label className="sublabel">54</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.copper_tubing_size_54 || ''}
                                readOnly="readonly"
                            />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">64</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_64 || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">66.7</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.copper_tubing_size_66_7 || ''}
                                readOnly="readonly"
                            />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">76.1</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_76_1 || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">88.9</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.copper_tubing_size_88_9 || ''}
                                readOnly="readonly"
                            />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">108</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_108 || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">133</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_133 || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">159</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_159 || ''}
                                readOnly="readonly" />
                        </Col>
                    </Row>
                )
            } else if (selectedToolType === 2) {
                return (
                    <Row>
                        <Col xs={12}>
                            <Label className="sublabel">DN50(50.8)</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.copper_tubing_size_dn50 || ''}
                                readOnly="readonly"
                            />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">DN65(63.5)</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_dn65 || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">DN80(76.2)</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.copper_tubing_size_dn80 || ''}
                                readOnly="readonly"
                            />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">DN100(101.6)</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_dn100 || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">DN125(127.0)</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.copper_tubing_size_dn125 || ''}
                                readOnly="readonly"
                            />
                        </Col>
                        <Col xs={12}>
                            <Label className="sublabel">DN150(152.4)</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.copper_tubing_size_dn150 || ''}
                                readOnly="readonly" />
                        </Col>
                    </Row>
                )
            }
        }

        const tableToolRatings = () => {
            return (
                <div>
                    <Row>
                        <Col xs={12}>
                            <Subtext>
                                {ReactHtmlParser(this.props.strings.I_600_SECTION_1_HEADER_TOOL_RATINGS)}
                            </Subtext>
                        </Col>
                    </Row>
                    {prepLabel()}
                    <Row>
                        <Col xs={12}>
                            <Label>{string.TUBE_TYPE}</Label>
                            <Select
                                options={this.state.tubeTypeOptions}
                                getOptionValue={option => option.value}
                                value={this.state.selectedTubeType}
                                placeholder={this.props.strings.SELECT_A_GROOVE_TYPE}
                                className="input-filter"
                                onChange={this.setTubeType}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>
                        <Col xs={12}>
                            <Label>{this.props.strings.TOOL_MODEL}</Label>
                            <Select
                                options={state.toolModelOptions}
                                getOptionValue={option => option.value}
                                placeholder={this.props.strings.SELECT_A_TOOL_MODEL}
                                value={this.state.selectedToolModel}
                                className="input-filter"
                                onChange={this.setToolModel}
                                isDisabled={state.toolModelSelectDisabled ? true : null}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <CategoryHeader>
                                {string.ROLL_GROOVING_TOOL_RATING_MAX_CAP}
                            </CategoryHeader>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Label>{string.COPPER_TUBING_SIZE_THICKNESS}</Label>
                        </Col>
                    </Row>
                    {ratingsBoxes()}
                    <Row>
                        <Col xs={12}>
                            <Subtext>
                                {ReactHtmlParser(this.props.strings.I_600_SECTION_1_FOOTER_TOOL_RATINGS)}
                            </Subtext>
                        </Col>
                    </Row>
                </div>
            )
        }

        const tableTubingLength = () => {
            const results = this.state.resultsLength
            return (
                <div>
                    <Row>
                        <Col xs={12}>
                            <Subtext>
                                {ReactHtmlParser(this.props.strings.I_600_SECTION_1_HEADER_TUBING_LENGTH)}
                            </Subtext>
                        </Col>
                    </Row>
                    {prepLabel()}
                    <Row>
                        <Col xs={12}>
                            <Label>{string.STANDARD}</Label>
                            <Select
                                options={this.state.toolLengthStandardOptions}
                                getOptionValue={option => option.value}
                                value={this.state.selectedStandardType}
                                placeholder={this.props.strings.SELECT_A_STANDARD_TYPE}
                                className="input-filter"
                                onChange={this.setStandardType}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>
                        <Col xs={12}>
                            <Label>{this.props.strings.NOMINAL_SIZE}</Label>
                            <Select
                                options={state.nominalSizeOptions}
                                getOptionValue={option => option.value}
                                placeholder={this.props.strings.SELECT_NOMINAL_SIZE}
                                value={this.state.selectedNominalSize}
                                className="input-filter"
                                onChange={this.setNominalSize}
                                isDisabled={state.toolStandardSelectedDisabled ? true : null}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={customSelectStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => (
                                        <Indicator className="icon-down-micro" />
                                    )
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <CategoryHeader>
                                {string.TUBING_LENGTH}
                            </CategoryHeader>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6}>
                            <Label className="sublabel">{string.MIN}</Label>
                            <Input
                                type="text"
                                placeholder="&mdash;"
                                value={results.tubing_length_min || ''}
                                readOnly="readonly" />
                        </Col>
                        <Col xs={6}>
                            <Label className="sublabel">{string.MAX}</Label>
                            <Input
                                type="text" placeholder="&mdash;"
                                value={results.tubing_length_max || ''}
                                readOnly="readonly"
                            />
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Subtext>
                                {ReactHtmlParser(this.props.strings.I_600_SECTION_1_FOOTER_TUBING_LENGTH)}
                            </Subtext>
                        </Col>
                    </Row>
                </div>
            )
        }

        const selectedTable = () => {
            if (selectedPrepType.value === 1) {
                return tableToolRatings();
            } else if (selectedPrepType.value === 2) {
                return tableTubingLength();
            }
        }



        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionCaution>
                            <h2><i className="icon-caution icon-round-bg"></i> {this.props.strings.WARNING}</h2>
                            <div>{ReactHtmlParser(this.props.strings.I_600_SECTION_1_WARNING)}</div>
                        </SectionCaution>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_600_SECTION_1_HEADER)}
                        </Subtext>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.state.sizeString}</Label>
                        <Select
                            options={state.prepTypeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_PREP_TYPE}
                            className="input-filter"
                            onChange={this.setPrepType}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>
                {selectedTable()}
            </Grid>
        );
    }
};

const SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    SectionCaution = styled.div`
        border: 1px solid #333333;
        padding: 0;
        margin: 20px 0 0 0;

        h2 {
            font-family: Rajdhani, sans-serif;
            font-size: 20px;
            background: #333333;
            color: #ffffff;
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
            margin:0 0 20px 0;

            i {
                font-size: 14px;
                vertical-align: middle;
                position:relative;
                top:-1px;
            }
        }

        div {
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            text-transform: none;
            padding: 0 10px;
            position: relative;
            top: -10px;
            margin: 0 0 5px 0;
        }

        .icon-round-bg {
            background: #ff7f00;
            color: #ffffff;
        }
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        margin: 0 0 5px 0;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default CopperTubePreparation;