import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

import Layout from './Layout';

import Logo from '../../assets/victaulic.png';
import BackgroundImage from '../../assets/background.jpg';

class HomeLayout extends Component {
    render() {
        const date = new Date();
        const year = date.getFullYear();

        return (
            <Layout>
                <GlobalStyle />
                <ContentContainer>
                    <LogoContainer>
                        <VictaulicLogo
                            src={Logo}
                            alt="logo"
                        />
                    </LogoContainer>
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <Content>


                                </Content>
                            </Col>
                        </Row>
                    </Grid>
                    <Footer>
                        &copy; {year}&nbsp;
                                {this.props.strings.VICTAULIC}{this.props.strings.COPYRIGHT_SEPARATOR}&nbsp;
                                {this.props.strings.ALL_RIGHTS_RESERVED}{this.props.strings.COPYRIGHT_SEPARATOR}&nbsp;
                                <a target="_blank"
                            rel="noopener noreferrer"
                            href={this.props.strings.TERMS_OF_USE_URL}>{this.props.strings.TERMS_OF_USE}</a>
                    </Footer>
                </ContentContainer>
            </Layout>
        );
    }
}

const GlobalStyle = createGlobalStyle`
    html, body {
        max-width: 100%;
    }
    body {
        background-image: url(${BackgroundImage});
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        font-family: 'Open Sans', sans-serif;
        margin: 0;
        padding: 0;
    }
    a {
        text-decoration: none;
    }
    `,
    ContentContainer = styled.div`
        padding: 0;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        margin: 0 auto;
        box-sizing:border-box;

    `,
    Content = styled.div`
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        left: 3%;
        top: 20%;
        overflow-y: scroll;

    `,
    LogoContainer = styled.div`
        width: 100%;
        margin: 0 auto;
        position: absolute;
        top: 5px;
        display: flex;
        justify-content: center;
        text-align: center;
    `,
    VictaulicLogo = styled.img`
        height: 35px;
        margin: 15px auto;
    `,
    Footer = styled.div`
        color: #ffffff;
        font-size: 13px;
        width: 100%;
        margin: 0 0 10px;
        left: 0;
        justify-content: center;
        text-align: center;
        padding:0 10px;
        box-sizing:border-box;

        position: fixed;
        bottom: 0px;

        & a {
            color: #ffffff;

            &:visited {
                color: #ffffff;
            }
        }
    `;

export default HomeLayout;
