import React, { Component } from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import Layout from './shared/Layout';

import Logo from '../assets/victaulic.png';
import BackgroundImage from '../assets/background.jpg';

import Language from './Language';
import Terms from './Terms';
import InstallPwa from './InstallPwa';

class Splash extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            langSet: localStorage.getItem('language'),
            termsSet: localStorage.getItem('terms'),
            pwaPrompt: localStorage.getItem('pwa_prompt'),
            next: process.env.REACT_APP_PUBLIC_URL + 'home',
            getLanguageUrl: process.env.REACT_APP_API_URL + 'language',
            getHandbooksUrl: process.env.REACT_APP_API_URL + 'handbook',
        };
        this._isMounted = false;
    }

    getLanguages = () => {
        // Retrieve the list of languages from the API
        axios.get(this.state.getLanguageUrl)
            .then((response) => {
                localStorage.setItem('languages', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    getStrings = () => {
        // Retrieve the strings model from the API (default to en)
        axios.get(this.state.getLanguageUrl + '/' + (this.state.langSet ? this.state.langSet : 'en'))
            .then((response) => {
                localStorage.setItem('strings', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    }

    getHandbooks = () => {
        // Retrieve the list of handbooks from the API
        axios.get(this.state.getHandbooksUrl)
            .then((response) => {
                localStorage.setItem('handbooks', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    }

    getData = async () => {
        await axios.all([this.getLanguages(), this.getStrings(), this.getHandbooks()])
            .then(axios.spread(() => {
                // All requests are now complete
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 2000);
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getData();
        // Prevent the install banner if service worker running
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
        });
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const date = new Date();
        const year = date.getFullYear();

        if (this.state.loading) return (
            <Layout>
                <GlobalStyle />
                <ContentContainer>
                    <LogoContainer>
                        <VictaulicLogo
                            src={Logo}
                            alt="logo"
                        />
                    </LogoContainer>
                </ContentContainer>
                <Footer>
                    <p>&copy; {year} Victaulic. All Rights Reserved. <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.victaulic.com/victaulic-company-website-terms-of-use-and-legal-restrictions/">Terms of Use</a>
                    </p>
                </Footer>
            </Layout>
        );

        else if (!this.state.loading && this.state.langSet === null) return (
            <Language deferredPrompt={this.deferredPrompt} />
        );

        else if (!this.state.loading && this.state.termsSet === null) return (
            <Terms deferredPrompt={this.deferredPrompt} />
        );

        else if (!this.state.loading && this.deferredPrompt && this.state.pwaPrompt === null) return (
            <InstallPwa deferredPrompt={this.deferredPrompt} />
        );

        else return (
            <Redirect to={this.state.next} />
        );

    }
}

const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100%;
    }
    body {
        background-image: url(${BackgroundImage});
        background-attachment: fixed;
        background-size: cover;
        font-family: 'Open Sans', sans-serif;
        margin: 0;
        padding: 0;
        height: 100%;
    }
    a {
        text-decoration: none;
    }
    `,
    ContentContainer = styled.div`
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `,
    LogoContainer = styled.div`
        width: 100%;
        text-align: center;
        position: fixed;
        top: 50%;
        left: 0;
        transform: translateY(calc(-50% - 15px));
    `,
    VictaulicLogo = styled.img`
        width: 60%;
        margin: 0 auto;
        max-width:400px;
    `,
    Footer = styled.div`
        position: fixed;
        clear: both;
        bottom: 0;
        margin: 20px auto 5px;
        color: #ffffff;
        font-size: 14px;
        width: 100%;
        text-align: center;
        padding:0 10px;
        box-sizing:border-box;

        &:before {
            content: "";
        }

        p {
            margin: 10px auto;
        }

        & a {
            color: #ffffff;

            &:visited {
                color: #ffffff;
            }
        }
    `;

export default Splash;
