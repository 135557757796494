import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const toolTypeOptions = [
    { value: 'roll', label: 'Roll Grooving' },
    { value: 'cut', label: 'Cut Grooving' },
];

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class ToolCapacities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=tool_capacities',
            getSectionDataHeaderUrl: process.env.REACT_APP_API_URL + 'get/section?table=tool_capacity_sizes',
            selectedToolType: {},
            selectedPipeMaterial: {},
            selectedPipeSize: {},
            results: [{
                toolType: '',
                toolModel: '',
                pipeMaterial: '',
                pipeSchedule: '',
            }],
        };
    }

    getPipeMaterial = async () => {
        // Retrieve the nominal_size for dropdown from the API
        axios.get(this.state.getSectionDataUrl + '&select=pipe_material&distinct=true')
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_pipe_material', JSON.stringify(response.data.data));
                let pipeMaterial = [];
                response.data.data.map((value, key) => {
                    pipeMaterial.push({ 'value': value.pipe_material, 'label': value.pipe_material });
                    return true;
                });
                this.setState({
                    pipeMaterialOptions: pipeMaterial
                })
            })
            .catch(console.log);
    };

    getPipeSize = async () => {
        // Retrieve the outside_diameter for dropdown from the API
        axios.get(this.state.getSectionDataHeaderUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_pipe_size', JSON.stringify(response.data.data));
                let pipeSize = [];
                response.data.data.map((value, key) => {
                    pipeSize.push({ 'value': value.id, 'label': value.size });
                    return true;
                });
                this.setState({
                    pipeSizeOptions: pipeSize
                })
            })
            .catch(console.log);
    };

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_tool_capacities', JSON.stringify(response.data.data));
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.getPipeMaterial(), this.getPipeSize(), this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    setToolType = tt => {
        this.setState({
            selectedToolType: { 'value': tt.value, 'label': tt.label },
        }, () => {
            this.setResults();
        });
    };

    setPipeMaterial = pm => {
        this.setState({
            selectedPipeMaterial: { 'value': pm.value, 'label': pm.label },
        }, () => {
            this.setResults();
        });
    };

    setPipeSize = ps => {
        this.setState({
            selectedPipeSize: { 'value': ps.value, 'label': ps.label },
        }, () => {
            this.setResults();
        });
    };

    setResults = () => {
        if (Object.entries(this.state.selectedToolType).length > 0 &&
            Object.entries(this.state.selectedPipeMaterial).length > 0 &&
            Object.entries(this.state.selectedPipeSize).length > 0) {

            try {
                let data = [],
                    results = [];

                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_tool_capacities')),
                        { 'tool_type': this.state.selectedToolType.value, 'pipe_material': this.state.selectedPipeMaterial.value }
                    ), (values, index) => {
                        _.forEach(values, (value, key) => {
                            /*eslint eqeqeq: 0*/
                            if (key == this.state.selectedPipeSize.value) {
                                if (value.length > 0) {
                                    data.push(values);
                                    return true;
                                }
                            }
                        })
                    });

                if (data.length > 0) {
                    results = data.map((item, index) => ({
                        toolType: item.tool_type || '',
                        toolModel: item.tool_model || '',
                        pipeMaterial: item.pipe_material || '',
                        pipeSchedule: item[this.state.selectedPipeSize.value] || '',
                    }));
                } else {
                    results = [{
                        toolType: 'N/A',
                        toolModel: 'N/A',
                        pipeMaterial: 'N/A',
                        pipeSchedule: 'N/A',
                    }];
                }

                this.setState({
                    results: results,
                });

            } catch (e) {
                console.log('Error', e);
                console.log('state', this.state);
            }
        }
    }

    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.TOOL_TYPE}</Label>
                        <Select
                            options={toolTypeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_TOOL_TYPE}
                            className="input-filter"
                            onChange={this.setToolType}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.PIPE_MATERIAL}</Label>
                        <Select
                            options={this.state.pipeMaterialOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_PIPE_MATERIAL}
                            className="input-filter"
                            onChange={this.setPipeMaterial}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.PIPE_SIZE} {this.props.strings.INCHES}/{this.props.strings.MM}</Label>
                        <Select
                            options={this.state.pipeSizeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_PIPE_SIZE}
                            className="input-filter"
                            onChange={this.setPipeSize}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <CategoryHeader>
                            {this.props.strings.TOOL_MODELS}
                        </CategoryHeader>
                    </Col>
                    <Col xs={6}>
                        <CategoryHeader>
                            {this.props.strings.PIPE_SCHEDULE}
                        </CategoryHeader>
                    </Col>
                </Row>
                <ResultsContainer>
                    {
                        this.state.results.map((value, index) =>
                            <Row key={index}>
                                <Col xs={6}>
                                    <Input type="text" placeholder="&mdash;" value={value.toolModel} readOnly="readonly" />
                                </Col>
                                <Col xs={6}>
                                    <Input type="text" placeholder="&mdash;" value={value.pipeSchedule} readOnly="readonly" />
                                </Col>
                            </Row>
                        )
                    }
                </ResultsContainer>

                <Row>
                    <Col xs={12}>
                        <SectionDivider />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_100_SECTION_2_FOOTER)}
                        </Subtext>
                    </Col>
                </Row>
            </Grid>
        );
    }
};

const SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 20px 0;
        margin: 0;
    `,
    SectionDivider = styled.div`
        border-top: 1px solid #efefef;
        border-collapse: collapse;
        height: 1px;
        margin: 10px 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        margin: 10px 0 0 0;
        text-transform: uppercase;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 100%;
        box-sizing:border-box;
    `,
    ResultsContainer = styled.div``,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default ToolCapacities;