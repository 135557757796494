import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import '../../../assets/css/app.css';

const gasketTypeOptions = [
    { value: 'standard', label: 'Standard NPS Gasket' },
    { value: 'special', label: 'Special NPS Gasket' },
];

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #dddddd',
        margin: 0,
        padding: 10,
    }),
    singleValue: (provided, state) => {
        const transition = 'height 250ms ease-in-out';
        return { ...provided, transition };
    }
};

class GasketSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            getSectionDataUrl: process.env.REACT_APP_API_URL + 'get/section?table=gasket_selection',
            gasketTypeOptions: gasketTypeOptions,
            tempRangeOptions: [],
            selectedGasketType: '',
            selectedTempRange: '',
            results: {
                gasketGrade: '',
                compound: '',
                colorCode: '',
                generalServiceRecommendation: '',
            },
        };
    }

    setTempRangeOptions = () => {
        let data = JSON.parse(localStorage.getItem(this.props.sectionId + '_gasket_selection'));
        let tempRanges = [];
        let map = new Map();
        for (const item of data) {
            if (!map.has(item.temp_range) && item.temp_range !== '' && item.temp_range !== null) {
                map.set(item.temp_range, true);    // set any value to Map
                tempRanges.push({ value: item.temp_range, label: item.temp_range });
            }
        }
        this.setState({
            tempRangeOptions: tempRanges,
        });
    }

    getData = async () => {
        // Retrieve the complete data set from the API
        axios.get(this.state.getSectionDataUrl)
            .then((response) => {
                localStorage.setItem(this.props.sectionId + '_gasket_selection', JSON.stringify(response.data.data));
                this.setTempRangeOptions();
            })
            .catch(console.log);
    };

    getAllData = async () => {
        await axios.all([this.getData()])
            .then(axios.spread(() => {
                // All requests are now complete
                this.setState({
                    loading: false,
                })
            }))
            .finally(() => {
                this._isMounted = true;
            });
    };

    componentDidMount() {
        this.getAllData();
    }

    setGasketType = g => {
        if (g !== null) {
            this.setState({
                selectedGasketType: { 'value': g.value, 'label': g.label },
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_gasket_selection')),
                    ['gasket_type', this.state.selectedGasketType.value]
                );
                // filter the Selection options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_gasket_selection')),
                        ['gasket_type', this.state.selectedGasketType.value]
                    ), (values, index) => {
                        options.push(values)
                    });
                let tempRangeOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.temp_range) && item.temp_range !== '' && item.temp_range !== null) {
                        map.set(item.temp_range, true);    // set any value to Map
                        tempRangeOptions.push({ value: item.temp_range, label: item.temp_range });
                    }
                }
                this.setResults(data, this.state.gasketTypeOptions, tempRangeOptions);
            });
        } else {
            this.setState({
                gasketTypeOptions: gasketTypeOptions,
                results: {
                    gasketGrade: '',
                    compound: '',
                    colorCode: '',
                    generalServiceRecommendation: '',
                },
            })
        }
    };

    setTempRange = t => {
        if (t !== null) {
            this.setState({
                selectedTempRange: { 'value': t.value, 'label': t.label },
            }, () => {
                let data = _.find(
                    JSON.parse(localStorage.getItem(this.props.sectionId + '_gasket_selection')),
                    ['temp_range', this.state.selectedTempRange.value]
                );
                // filter the Selection options
                let options = [];
                _.forEach(
                    _.filter(
                        JSON.parse(localStorage.getItem(this.props.sectionId + '_gasket_selection')),
                        ['gasket_type', this.state.selectedGasketType.value]
                    ), (values, index) => {
                        options.push(values)
                    });
                let gasketTypeOptions = [];
                let map = new Map();
                for (const item of options) {
                    if (!map.has(item.gasket_type) && item.gasket_type !== '' && item.gasket_type !== null) {
                        map.set(item.gasket_type, true);    // set any value to Map
                        gasketTypeOptions.push({ value: item.gasket_type, label: item.gasket_type === 'standard' ? 'Standard NPS Gasket' : 'Special NPS Gasket' });
                    }
                }
                this.setResults(data, gasketTypeOptions, this.state.tempRangeOptions);
            });
        } else {
            this.setTempRangeOptions();
            this.setState({
                results: {
                    gasketGrade: '',
                    compound: '',
                    colorCode: '',
                    generalServiceRecommendation: '',
                },
            });
        }
    };

    setResults = (data, gasketTypeOptions, tempRangeOptions) => {
        try {
            if (Object.entries(this.state.selectedGasketType).length > 0 &&
                Object.entries(this.state.selectedTempRange).length > 0) {
                this.setState({ // update the results, ony if all three options are set
                    selectedGasketType: { 'value': data.gasket_type, 'label': '' },
                    selectedTempRange: { 'value': data.temp_range, 'label': data.temp_range },
                    results: {
                        gasketGrade: data.grade,
                        compound: data.compound,
                        colorCode: data.color_code,
                        generalServiceRecommendation: data.general_service_recommendation,
                    },
                });
            }
            this.setState({ // update the Selection options, always
                gasketTypeOptions: gasketTypeOptions,
                tempRangeOptions: tempRangeOptions,
            });
        } catch (e) {
            console.log('Error', e);
            console.log('state', this.state);
        }
    };

    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <SectionCaution>
                            <h2><i className="icon-caution icon-round-bg"></i> Caution</h2>
                            <div>{ReactHtmlParser(this.props.strings.I_100_SECTION_4_CAUTION)}</div>
                        </SectionCaution>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SectionIntro>
                            <p>{ReactHtmlParser(this.props.strings.I_100_SECTION_4_INTRO)}</p>
                        </SectionIntro>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <SelectionHeader>
                            {this.props.strings.MAKE_A_SELECTION_BELOW}:
                        </SelectionHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GASKET_TYPE}</Label>
                        <Select
                            options={this.state.gasketTypeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_GASKET_TYPE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setGasketType}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.TEMP_RANGE}</Label>
                        <Select
                            options={this.state.tempRangeOptions}
                            getOptionValue={option => option.value}
                            placeholder={this.props.strings.SELECT_A_TEMPERATURE_RANGE}
                            className="input-filter"
                            isClearable={true}
                            onChange={this.setTempRange}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={customSelectStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <Indicator className="icon-down-micro" />
                                )
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CategoryHeader>
                            {this.props.strings.SPECIAL_NPS_GASKET_COMPOUNDS}
                        </CategoryHeader>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GASKET_GRADE}</Label>
                        <Input type="text" className="full" value={this.state.results.gasketGrade || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.COMPOUND}</Label>
                        <Input type="text" className="full" value={this.state.results.compound || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.COLOR_CODE}</Label>
                        <Input type="text" className="full" value={this.state.results.colorCode || ''} readOnly="readonly" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Label>{this.props.strings.GENERAL_SERVICE_RECOMMENDATION}</Label>
                        <Textarea type="text" className="full" rows="12" value={this.state.results.generalServiceRecommendation || ''} readOnly="readonly">

                        </Textarea>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Subtext>
                            {ReactHtmlParser(this.props.strings.I_100_SECTION_4_FOOTER)}
                        </Subtext>
                    </Col>
                </Row>
            </Grid>
        );
    }
};

const SectionCaution = styled.div`
        border: 1px solid #333333;
        padding: 0;
        margin: 20px 0 0 0;

        h2 {
            font-family: Rajdhani, sans-serif;
            font-size: 20px;
            background: #333333;
            color: #ffffff;
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
            margin:0 0 20px 0;

            i {
                font-size: 14px;
                vertical-align: middle;
                position:relative;
                top:-1px;
            }
        }

        div {
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            text-transform: none;
            padding: 0 10px;
            position: relative;
            top: -10px;
            margin: 0 0 5px 0;
        }

        .icon-round-bg {
            background: #ff7f00;
            color: #ffffff;
        }
    `,
    SectionIntro = styled.div`
        padding: 0;
        margin: 1em 0;

        span {
            color: #ff7f00;
            font-weight: bold;
        }
    `,
    SelectionHeader = styled.h2`
        font-weight: bold;
        font-size: 18px;
        padding: 0;
        margin: 0 0 15px 0;
    `,
    CategoryHeader = styled.h3`
        font-family: Rajdhani, sans-serif;
        background: #333333;
        color: #ffffff;
        padding: 10px;
        text-transform: uppercase;
        margin: 10px 0;
    `,
    Label = styled.label`
        font-family: Rajdhani, sans-serif;
        font-size: 20px
        font-weight: bold;
        text-transform: uppercase;
        clear: both;

        &::before {
            content: ' ';
        }

        &::after {
            content: ' ';
        }
    `,
    Input = styled.input`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 94%;
    `,
    Textarea = styled.textarea`
        border-radius: 0;
        background: #eaeaea;
        padding: 10px;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        width: 94%;
    `,
    Subtext = styled.div`
        font-size: 15px;
        padding-bottom: 15px;
    `,
    Indicator = styled.i`
        color: #f09b28;
        font-size: 26px;
        padding: 0 5px 0 0;

    `;

export default GasketSelection;